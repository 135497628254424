import dot from 'dot-object';
import { useFormikContext } from 'formik';
import { useEffect } from 'react';
import scrollToElement from 'scroll-to-element';

type FormikScrollToErrorsProps = {
  offset?: number;
  align?: 'top' | 'middle' | 'bottom';
  ease?: string;
  duration?: number;
};

export const FormikScrollToErrors: React.FC<FormikScrollToErrorsProps> = ({
  offset = -50,
  duration = 500,
  ease = 'linear',
  align = 'top',
}) => {
  const { errors, isSubmitting, isValidating } = useFormikContext();

  useEffect(() => {
    let timeout: any;
    const shouldScrollToError = isSubmitting && !isValidating;
    if (shouldScrollToError) {
      const flattenErrors = dot.dot(errors);
      const keys = Object.keys(flattenErrors).filter((key) => key.includes('.'));

      if (keys.length > 0) {
        const defaultElement = document.querySelector<HTMLInputElement>(`[name="${keys[0]}"]`);
        const element = defaultElement
          ? defaultElement
          : document.querySelector<HTMLInputElement>(`[name^="${keys[0]}"]`);

        if (element) {
          scrollToElement(element, {
            duration,
            offset,
            ease,
            align,
          });
          timeout = setTimeout(() => element.focus(), duration + 200);
        }
      }
    }

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [align, duration, ease, errors, isSubmitting, isValidating, offset]);
  return null;
};
