import React, { FC, useCallback, useContext, useMemo, useState } from 'react';

interface LoaderContextStore {
  loading: boolean;
  toggleLoader: (loading: boolean) => void;
}

export const LoaderContext = React.createContext({} as LoaderContextStore);
export const useLoader = () => useContext(LoaderContext);

export const LoaderProvider: FC = ({ children }) => {
  const [loading, setLoading] = useState<boolean>(false);

  const toggleLoader = useCallback((loading: boolean) => {
    setLoading(loading);
  }, []);

  const store: LoaderContextStore = useMemo(() => {
    return {
      loading,
      toggleLoader,
    };
  }, [loading, toggleLoader]);

  return <LoaderContext.Provider value={store}>{children}</LoaderContext.Provider>;
};
