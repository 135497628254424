import {
  domainTooLongMessage,
  intraRegionalCountriesMessage,
  maxNumberMessage,
  minNumberMessage,
  minRequiredMessage,
  requiredMessage,
  validWebAddressMessage,
  valueStringTooBigMessage,
  valueTooBigMessage,
} from '@utilities/validation/messages';
import * as yup from 'yup';
import { CheckoutStep4Service } from '../models/checkout-step-4-form.model';
import { ServiceType } from '../models/service-type.enum';
import { validateMoneyInput } from './validation';
import { urlRegExp } from '@utilities/validation/regex';

export const CLICKS_VALIDATION_SCHEMA = {
  clicksLeadTimeInDays: yup
    .number()
    .when(['active', 'name'], {
      is: (active, name) => name === ServiceType.Clicks && active,
      then: yup
        .number()
        .required(requiredMessage)
        .min(1, minNumberMessage(1))
        .max(365, maxNumberMessage(365))
        .nullable(),
    })
    .nullable(),
  productTypesOnline: yup
    .string()
    .when(['active', 'name'], {
      is: (active, name) => name === ServiceType.Clicks && active,
      then: yup
        .string()
        .test('exists', minRequiredMessage, function (this: yup.TestContext) {
          if (!this.parent.productTypesOnline) return false;
          return this.parent.productTypesOnline.length > 1;
        })
        .max(255, valueStringTooBigMessage(255))
        .nullable(),
      otherwise: undefined,
    })
    .nullable(),
  webshopUrl: yup
    .string()
    .when(['active', 'name'], {
      is: (active, name) => name === ServiceType.Clicks && active,
      then: yup
        .string()
        .matches(urlRegExp, validWebAddressMessage).required(requiredMessage)
        .max(64, domainTooLongMessage),
      otherwise: undefined,
    })
    .nullable(),
  averageOnlineRevenuePerMonth: validateMoneyInput('averageOnlineRevenuePerMonth', ServiceType.Clicks),
  selectedCountries: yup
    .array()
    .when(['active', 'name'], {
      is: (active, name) => name === ServiceType.Clicks && active,
      then: yup.array().min(1, requiredMessage).required(requiredMessage).nullable(),
      otherwise: undefined,
    })
    .nullable(),
  clicksDomesticTargetCountryName: yup
    .string()
    .when(['active', 'name'], {
      is: (active, name) => name === ServiceType.Clicks && active,
      then: yup.string().required(requiredMessage).nullable(),
    })
    .nullable(),
  clicksIntraRegionalTargetMarketInEuCountries: yup
    .array()
    .when(['active', 'name'], {
      is: (active, name) => name === ServiceType.Clicks && active,
      then: yup.array().test('exists', intraRegionalCountriesMessage, function (this: any) {
        const countriesInEU = this.parent.clicksIntraRegionalTargetMarketInEuCountries;
        const countriesOutsideEU = this.parent.clicksIntraRegionalTargetMarketOutsideEuCountries;
        if (
          (countriesInEU === undefined || countriesInEU.length === 0) &&
          (countriesOutsideEU === undefined || countriesOutsideEU.length === 0)
        ) {
          return false;
        }
        return true;
      }),
    })
    .nullable(),
  clicksIntraRegionalTargetMarketOutsideEuCountries: yup
    .array()
    .when(['active', 'name'], {
      is: (active, name) => name === ServiceType.Clicks && active,
      then: yup.array().test('exists', intraRegionalCountriesMessage, function (this: any) {
        const countriesInEU = this.parent.clicksIntraRegionalTargetMarketInEuCountries;
        const countriesOutsideEU = this.parent.clicksIntraRegionalTargetMarketOutsideEuCountries;
        if (
          (countriesInEU === undefined || countriesInEU.length === 0) &&
          (countriesOutsideEU === undefined || countriesOutsideEU.length === 0)
        ) {
          return false;
        }
        return true;
      }),
    })
    .nullable(),
  clicksExpectedTotalVolumeAnnually: validateMoneyInput('clicksExpectedTotalVolumeAnnually', ServiceType.Clicks),
  clicksAverageTransactionValue: validateMoneyInput('clicksAverageTransactionValue', ServiceType.Clicks),
  clicksHighestTransactionValue: validateMoneyInput('clicksHighestTransactionValue', ServiceType.Clicks),

  // PSP information
  clicksNameFormerPsp: yup
    .string()
    .when(['active', 'name', 'madePaymentsWithAnotherPsp'], {
      is: (active, name, madePaymentsWithAnotherPsp) =>
        name === ServiceType.Clicks && active && madePaymentsWithAnotherPsp === 'true',
      then: yup.string().required(requiredMessage).nullable(),
    })
    .nullable(),
  clicksReasonForSwitching: yup
    .string()
    .when(['active', 'name', 'madePaymentsWithAnotherPsp'], {
      is: (active, name, madePaymentsWithAnotherPsp) =>
        name === ServiceType.Clicks && active && madePaymentsWithAnotherPsp === 'true',
      then: yup.string().required(requiredMessage).nullable(),
    })
    .nullable(),
  clicksProcessedVolumeLastThreeMonths: yup
    .number()
    .when(['active', 'name', 'madePaymentsWithAnotherPsp'], {
      is: (active, name, madePaymentsWithAnotherPsp) =>
        name === ServiceType.Clicks && active && madePaymentsWithAnotherPsp === 'true',
      then: yup
        .number()
        .required(requiredMessage)
        .min(1, minNumberMessage(1))
        .max(999999999999999, valueTooBigMessage(15))
        .nullable(),
    })
    .nullable(),
};
