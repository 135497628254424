import CheckoutConfirmation from '@components/ReactCheckout/components/CheckoutConfirmation';
import { getOpportunityStep } from '@generated/opportunity';
import { getRegister } from '@generated/register';
import Loader from '@shared/Loader';
import Toast from '@shared/Toast';
import { useUser } from '@state/user';
import { LoaderProvider, useLoader } from '@utilities/context/LoaderContext';
import { ModalContextProvider } from '@utilities/context/ModalContext';
import { ToastProvider, useToast } from '@utilities/context/ToastContext';
import { setToken } from '@utilities/services/setToken';
import axios, { AxiosResponse } from 'axios';
import React, { useEffect, useState } from 'react';
import { Route, Switch, useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import CheckoutFive from './components/CheckoutFive/CheckoutFive';
import SignicatRedirect from './components/CheckoutFive/SignicatRedirect';
import CheckoutFour from './components/CheckoutFour/CheckoutFour';
import CheckoutOne from './components/CheckoutOne';
import CheckoutSix from './components/CheckoutSix/CheckoutSix';
import TwikeyRedirect from './components/CheckoutSix/TwikeyRedirect';
import CheckoutThree from './components/CheckoutThree';
import CheckoutTwo from './components/CheckoutTwo/CheckoutTwo';
import { useGetCurrentProduct } from '@utilities/hooks/useGetCurrentProduct';
import { getPackageSourceCode } from '@generated/package';

const Routing: React.FC = () => {
  const { path } = useRouteMatch();
  const location = useLocation();
  const history = useHistory();

  const { makeToast } = useToast();
  const { toggleLoader } = useLoader();
  const { setUser } = useUser();
  const [settingsLoaded, setSettingsLoaded] = useState(false);

  const params = new URLSearchParams(location.search);
  const {
    token,
    packageId,
    currentProduct,
    onboardingCustomerDomain,
    customerDomainSet,
    mandateId,
    state,
    account,
    signature,
  } = {
    token: params.get('token'),
    packageId: params.get('packageId') as string,
    currentProduct: useGetCurrentProduct()?.currentProduct,
    onboardingCustomerDomain: params.get('onboardingCustomerDomain'),
    customerDomainSet: params.get('customerDomainSet'),
    mandateId: params.get('mandateId'),
    state: params.get('state'),
    account: params.get('account'),
    signature: params.get('signature'),
  };

  const setPackageInformation = async () => {
    if (currentProduct && packageId) {
      const { data } = await getPackageSourceCode(currentProduct, packageId);
      setUser({ packageInformation: data });
    }
  };

  const setUserAccountData = async () => {
    toggleLoader(true);
    if (!customerDomainSet && !mandateId) {
      let redirectToPage = '';
      if (!onboardingCustomerDomain) {
        const currentDomain = window.location.host;
        redirectToPage = `https://${process.env.REACT_APP_DOMAIN}/checkout/step-1?token=${token}&packageId=${packageId}&onboardingCustomerDomain=${currentDomain}`;
        window.location.href = redirectToPage;
      } else {
        sessionStorage.setItem('ONBOARDING_CUSTOMER_DOMAIN', onboardingCustomerDomain);
        redirectToPage = `https://${onboardingCustomerDomain}/checkout/step-1?token=${token}&packageId=${packageId}&customerDomainSet=true`;
        window.location.href = redirectToPage;
      }
    }

    if (customerDomainSet || mandateId) {
      const { data } = await getRegister();
      await setPackageInformation();
      setUser({ account: data, packageId, setOnboardingDomain: window.location.host });

      toggleLoader(false);
      sessionStorage.setItem('USER_ACCOUNT_TOKEN', data.token);
      sessionStorage.setItem('ONBOARDING_CUSTOMER_DOMAIN', onboardingCustomerDomain);
      sessionStorage.setItem('CUSTOMER_DOMAIN_SET', customerDomainSet);
      setToken(data.token);
    }

    params.delete('token');
    params.delete('product');
    params.delete('packageId');
    history.replace({
      search: params.toString(),
    });

    setSettingsLoaded(true);
  };

  useEffect(() => {
    const successFn = (response) => response;
    const errorFn = ({
      response: error,
    }: {
      response: AxiosResponse & {
        body: { message: string };
        message: string;
      };
    }) => {
      if (error.status === 400) {
        toggleLoader(false);
        return Promise.reject(error);
      }

      makeToast({ message: error?.body?.message || error?.message, variant: 'error' });
      toggleLoader(false);

      return Promise.reject(error);
    };
    const interceptorId = axios.interceptors.response.use(successFn, errorFn);

    if (!!token) {
      setToken(token);
      setUserAccountData();
    } else {
      const storedDomain = sessionStorage.getItem('ONBOARDING_CUSTOMER_DOMAIN');
      if (storedDomain !== 'null' && mandateId) {
        const redirectToPage = `https://${storedDomain}/checkout/twikey-redirect?mandateId=${mandateId}&state=${state}&account=${account}&signature=${signature}&token=`;
        window.location.href = redirectToPage;
      }
      const storedToken = sessionStorage.getItem('USER_ACCOUNT_TOKEN');
      setToken(storedToken);
      setSettingsLoaded(true);
    }

    return () => {
      axios.interceptors.response.eject(interceptorId);
    };
  }, [makeToast, toggleLoader, token]); // eslint-disable-line react-hooks/exhaustive-deps

  const redirectToSavedStep = async () => {
    const steps = [...Array(6)].map((_, index) => index + 1);
    const {
      data: { step },
    } = await getOpportunityStep();
    history.replace(`/checkout/step-${steps.includes(Number(step)) ? step : 1}`);
  };

  useEffect(() => {
    if ((settingsLoaded && mandateId) || (settingsLoaded && customerDomainSet)) {
      redirectToSavedStep();
    }
  }, [settingsLoaded, customerDomainSet, mandateId]);

  const redirectDone = sessionStorage.getItem('CUSTOMER_DOMAIN_SET');

  return (
    <ModalContextProvider>
      {settingsLoaded &&
        (!redirectDone ? (
          <></>
        ) : (
          <Switch>
            <Route path={`${path}/step-1`}>
              <CheckoutOne />
            </Route>
            <Route path={`${path}/step-2`}>
              <CheckoutTwo />
            </Route>
            <Route path={`${path}/step-3`}>
              <CheckoutThree />
            </Route>
            <Route path={`${path}/step-4`}>
              <CheckoutFour />
            </Route>
            <Route path={`${path}/step-5`}>
              <CheckoutFive />
            </Route>
            <Route path={`${path}/step-6`}>
              <CheckoutSix />
            </Route>
            <Route path={`${path}/signicat-redirect`}>
              <SignicatRedirect />
            </Route>
            <Route path={`${path}/twikey-redirect`}>
              <TwikeyRedirect />
            </Route>
            <Route path={`${path}/confirmation`}>
              <CheckoutConfirmation />
            </Route>
          </Switch>
        ))}
    </ModalContextProvider>
  );
};

const Checkout: React.FC = () => {
  return (
    <LoaderProvider>
      <ToastProvider>
        <Loader />
        <Toast />
        <Routing />
      </ToastProvider>
    </LoaderProvider>
  );
};

const ReactCheckout = () => <Checkout />;

export default ReactCheckout;
