import { Draft, produce } from 'immer';
import { State, StateCreator } from 'zustand';

export interface BaseStateStore {
  loading?: boolean;
  error?: boolean | Error;
  updating?: boolean;
}

export const createImmer =
  <T extends State>(config: StateCreator<T, (fn: (draft: Draft<T>) => void) => void>): StateCreator<T> =>
  (set, get, api) =>
    config((fn) => set(produce<T>(fn)), get, api);
