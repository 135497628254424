import StyledButton from '@components/Shared/StyledButton';
import { Modal } from '@shared/modal/Modal';
import { useModalActions } from '@utilities/context/ModalContext';
import React from 'react';
import { UboFormData } from './models/ubo-form-data.model';

interface Props {
  removeUbo: (ubo: UboFormData) => void;
  ubo: UboFormData;
}

const RemoveUboModal: React.FC<Props> = ({ ubo, removeUbo }) => {
  const { hide } = useModalActions();

  return (
    <Modal id="remove-ubo-modal" title={`Ubo verwijderen`} isClosable={true}>
      <p className="u-margin-top--xs u-text--secondary">
        Weet u zeker dat u {ubo.firstname} {ubo.lastname} als Ubo wilt verwijderen?
      </p>
      <StyledButton
        tag="button"
        text="Nee, bewaar"
        type="button"
        formType="tertiary"
        classes="u-margin-right--xs u-margin-bottom--xs"
        onClick={() => hide('remove-ubo-modal')}
      />
      <StyledButton
        tag="button"
        text="Ja, verwijder"
        type="button"
        formType="tertiary"
        onClick={() => removeUbo(ubo)}
      />
    </Modal>
  );
};

export default RemoveUboModal;
