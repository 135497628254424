/**
 * Generated by orval v6.6.4 🍺
 * Do not edit manually.
 * Brinks Onboarding API v2023.9.6.948
 * Onboarding API (Development)
 * OpenAPI spec version: 1.0
 */
import axios,{
  AxiosRequestConfig,
  AxiosResponse
} from 'axios'
import type {
  BlubeemRequestsRegistrationRequest,
  BlubeemRequestsConfirmRegistrationRequest,
  BlubeemResponsesApiAccountResponse
} from './brinks.schemas'



  /**
 * @summary Creates a lead on base of Email and Phonenumber. 
Registration step 1 in onboarding. This is called after we proceed to step 2 and only includes the initial information.
 */
export const postRegister = <TData = AxiosResponse<string>>(
    blubeemRequestsRegistrationRequest: BlubeemRequestsRegistrationRequest, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.post(
      `/Register`,
      blubeemRequestsRegistrationRequest,options
    );
  }
/**
 * @summary Confirm the Registration.
This is called after finishing step 2 of the registration in onboarding. 
Updates the already created registration from step 1.
 */
export const putRegister = <TData = AxiosResponse<void>>(
    blubeemRequestsConfirmRegistrationRequest: BlubeemRequestsConfirmRegistrationRequest, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.put(
      `/Register`,
      blubeemRequestsConfirmRegistrationRequest,options
    );
  }
/**
 * @summary This converts the Lead into an Account.
 */
export const getRegister = <TData = AxiosResponse<BlubeemResponsesApiAccountResponse>>(
     options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.get(
      `/Register`,options
    );
  }
