import React from 'react';
import classNames from 'classnames';
import { ErrorMessage } from 'formik';

const FormTitle: React.FC<{
  variant: 'primary' | 'secondary' | 'tertiary';
  title: string;
  subtitle?: string;
  hasError?: boolean;
  name?: string;
}> = ({ variant, title, subtitle, hasError, name }) => {
  const classes = classNames({
    'cash-cards-clicks__title': true,
    [`cash-cards-clicks__title--${variant}`]: true,
    'cash-cards-clicks__item': variant === 'secondary' || variant === 'tertiary',
  });

  return (
    <label className={classes}>
      {title && <span className="cash-cards-clicks__main-title">{title}</span>}
      {subtitle && <span className="cash-cards-clicks__sub-title">{subtitle}</span>}
      {hasError && name && (
        <p className="cash-cards-clicks__error">
          <ErrorMessage name={name} />
        </p>
      )}
    </label>
  );
};

export default FormTitle;
