import { useFormikContext } from 'formik';
import React, { useEffect, useMemo, useState } from 'react';
import { CheckoutStep4FormData } from '../../models/checkout-step-4-form.model';
import { ServiceType } from '../../models/service-type.enum';
import { CompleteAccessory } from './complete-accessory.model';
import { CleanUpTraceNumber } from '@utilities/regex-helper';

interface AccessoryProps {
  accessory: CompleteAccessory;
  index: number;
  serviceType: ServiceType;
  resetValue: boolean;
  resetAccessoires: number;
}

const Accessory: React.FC<AccessoryProps> = ({ accessory, index, serviceType, resetValue, resetAccessoires }) => {
  const [quantity, setQuantity] = useState(accessory.quantity);
  const [prevResetValue, setPrevResetValue] = useState(false);
  const { setFieldValue, values } = useFormikContext<CheckoutStep4FormData>();

  useEffect(() => {
    if (resetValue && !prevResetValue) {
      setQuantity(0);
      setPrevResetValue(resetValue);
    } else {
      setQuantity(accessory.quantity);
    }
  }, [accessory.quantity, resetValue, prevResetValue, resetAccessoires]);

  const serviceIndex = useMemo(
    () => values.services.findIndex((service) => service.name === serviceType),
    [values, serviceType],
  );

  const changeQuantity = (add: boolean) => {
    const newQuantity = add ? quantity + 1 : quantity - 1;
    const existingAccessoryIndex = values.services[serviceIndex].accessories.findIndex((acc) => {
        const cleanName = acc !== undefined ? CleanUpTraceNumber(acc.productName) : CleanUpTraceNumber('');
        const cleanAccessoryName = CleanUpTraceNumber(accessory.name);
        return cleanName === cleanAccessoryName;
    });

    if (existingAccessoryIndex !== -1) {
      setFieldValue(`services[${serviceIndex}].accessories[${existingAccessoryIndex}].quantity`, newQuantity);
    } else {
      setFieldValue(`services[${serviceIndex}].accessories[${index}].productId`, accessory.id);
      setFieldValue(`services[${serviceIndex}].accessories[${index}].productName`, accessory.name);
      setFieldValue(`services[${serviceIndex}].accessories[${index}].quantity`, newQuantity);
    }
    setQuantity(newQuantity);
  };

  return (
    <div className="accessory">
      <img className="accessory-img" src={accessory.avatar} alt={accessory.name} />

      <div className="accessory-name">{accessory.name}</div>

      <div className="accessory-price">€{accessory.price}</div>

      <div className="accessory-quantity">
        {accessory.isMaxQuantityOne ? (
          quantity === 0 ? (
            <div role="button" tabIndex={0} onClick={() => changeQuantity(true)}>
              Selecteer
            </div>
          ) : (
            <div role="button" tabIndex={0} onClick={() => changeQuantity(false)}>
              Deselecteer
            </div>
          )
        ) : quantity !== 0 ? (
          <div className="accessory-quantity-buttons-container">
            <div
              className="accessory-quantity-minus-plus"
              role="button"
              tabIndex={0}
              onClick={() => changeQuantity(false)}
            >
              -
            </div>
            <div>{quantity}</div>
            <div
              className="accessory-quantity-minus-plus"
              role="button"
              tabIndex={0}
              onClick={() => changeQuantity(true)}
            >
              +
            </div>
          </div>
        ) : (
          <div
            className="accessory-quantity-minus-plus"
            role="button"
            tabIndex={0}
            onClick={() => changeQuantity(true)}
          >
            +
          </div>
        )}
      </div>
    </div>
  );
};

export default Accessory;
