import React from 'react';
import Range, { RangeBaseProps } from './Range';
import { FastField, useFormikContext } from 'formik';

export const FormikRange: React.FC<RangeBaseProps> = ({ name, resetValue, ...props }) => {
  const { setFieldValue } = useFormikContext();

  return (
    <FastField name={name}>
      {({ field, meta }) => {
        return (
          <Range
            {...field}
            value={field.value >= 0 ? field.value : 50}
            onChange={(e: number) => {
              setFieldValue(name, e);
            }}
            {...props}
            hasError={Boolean(meta.touched && meta.error)}
            resetValue={resetValue}
          />
        );
      }}
    </FastField>
  );
};
