// @ts-nocheck
import React from 'react';
import Checkbox, { CheckboxProps } from './Checkbox';
import { Field } from 'formik';

interface FormikCheckboxProps extends CheckboxProps {}

export const FormikCheckbox: React.FC<FormikCheckboxProps> = ({ name, ...props }) => {
  return (
    <Field name={name}>
      {({ field, meta }) => (
        <Checkbox {...field} {...props} checked={meta.value} hasError={Boolean(meta.touched && meta.error)} />
      )}
    </Field>
  );
};
