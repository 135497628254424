import classNames from 'classnames';
import * as React from 'react';

interface DefaultProps {
  classes?: string;
  fill?: string;
  direction?: string;
}

enum Directions {
  right = '0',
  bottom = '90',
  left = '180',
  top = '270',
}

export const CheckRound: React.FC<DefaultProps> = (props) => {
  const classes = classNames({
    'c-icon': true,
    [`${props.classes}`]: Boolean(props.classes),
  });

  return (
    <svg width="24" height="24" viewBox="0 0 24 24" className={classes} {...props}>
      <path
        d="M12 0c6.627 0 12 5.373 12 12s-5.373 12-12 12S0 18.627 0 12 5.373 0 12 0zm5.728 8.35a1.059 1.059 0 0 0-1.495-.078l-6.313 5.68-2.112-2.112-.08-.073a1.059 1.059 0 0 0-1.418 1.57l2.824 2.823.077.07c.4.33.987.322 1.38-.031l7.058-6.353.077-.077c.36-.397.369-1.011.002-1.418z"
        fill="#81C396"
        fillRule="evenodd"
      />
    </svg>
  );
};

export const CheckWhite: React.FC<DefaultProps> = (props) => {
  const classes = classNames({
    'c-icon': true,
    [`${props.classes}`]: Boolean(props.classes),
  });

  return (
    <svg width="10" height="8" viewBox="0 0 10 8" className={classes} {...props}>
      <path
        d="M8.49.259a.884.884 0 0 1 1.337 1.152l-.086.099-5.646 5.646a.885.885 0 0 1-1.152.086l-.1-.086L.26 4.57A.884.884 0 0 1 1.41 3.234l.099.085 1.959 1.96L8.49.259z"
        fill="#F5F1EB"
        fillRule="evenodd"
      />
    </svg>
  );
};

// todo: add size options
export const Chevron: React.FC<DefaultProps> = ({ direction = 'right', ...props }) => {
  const classes = classNames({
    'c-icon': true,
    [`${props.classes}`]: Boolean(props.classes),
  });

  return (
    <svg
      width="8px"
      height="12px"
      viewBox="0 0 8 12"
      className={classes}
      style={{ transform: `rotate(${Directions[direction]}deg)` }}
      {...props}
    >
      <path d="M7.359 6L1.344 0-.071 1.415 4.55 6 0 10.585 1.415 12z" />
    </svg>
  );
};

export const Package: React.FC<DefaultProps> = (props) => {
  const classes = classNames({
    'c-icon': true,
    [`${props.classes}`]: Boolean(props.classes),
  });

  return (
    <svg width="1em" height="1em" viewBox="0 0 20 22" className={classes} {...props}>
      <path
        d="M8.6.6c.9-.5 2-.5 2.8 0l6.9 4c.4.3.8.6 1 1 .2.5.4 1 .4 1.4v8c0 1-.5 1.9-1.4 2.4l-7 4a2.7 2.7 0 01-1.3.3c-.5 0-1 0-1.4-.3l-6.9-4A2.7 2.7 0 01.3 15V7c0-.5.2-1 .4-1.4.2-.4.6-.7 1-1zM1.8 7.1V15c0 .4.3.9.6 1l6.8 4v-8.6L1.8 7.1zm16.4 0l-7.5 4.3V20l6.9-4c.3-.1.5-.5.6-.9v-8zM4.5 11l3 1.8a.7.7 0 01-.6 1.4h-.1l-3-1.8a.7.7 0 01.7-1.4zm10.1-6.7L7.2 8.5l2.8 1.6 7.4-4.3-2.8-1.6zm-4-2.3c-.4-.2-.8-.2-1.2 0L2.6 6l3 1.7 7.5-4.3z"
        fill="#F5F1EB"
        fillRule="evenodd"
      />
    </svg>
  );
};

export const ArrowLeft: React.FC<DefaultProps> = (props) => {
  const classes = classNames({
    'c-icon': true,
    [`${props.classes}`]: Boolean(props.classes),
  });

  return (
    <svg width="17" height="14" viewBox="0 0 17 14" className={classes} {...props}>
      <path
        d="M7.542.47a.75.75 0 0 1 .073.976l-.073.084L2.83 6.244h13.17a.75.75 0 0 1 .1 1.493l-.1.007H2.792l4.75 4.749a.75.75 0 0 1 .072.976l-.073.084a.75.75 0 0 1-.976.073l-.084-.073L.47 7.543a.75.75 0 0 1-.157-.83l.009-.02a.743.743 0 0 1 .232-.295L6.482.47a.75.75 0 0 1 1.06 0z"
        fill="#3C2E72"
        fillRule="evenodd"
      />
    </svg>
  );
};

export const Switch: React.FC<DefaultProps> = (props) => {
  const classes = classNames({
    'c-icon': true,
    [`${props.classes}`]: Boolean(props.classes),
  });

  return (
    <svg width="16" height="20" viewBox="0 0 16 20" className={classes} {...props}>
      <path
        d="M11.8 10.8h.1l3.6 3.7h.1a.8.8 0 010 1l-3.7 3.6a.8.8 0 01-1.1-1l2.4-2.4H1a.7.7 0 01-.1-1.4h12.3l-2.3-2.4a.8.8 0 01-.1-1c.3-.3.8-.3 1-.1zM5.1.8c.3.3.3.8.1 1V2L2.7 4.2H15a.7.7 0 01.1 1.5H2.8l2.3 2.4c.3.2.3.7.1 1-.3.3-.8.3-1 .1H4L.5 5.4H.3a.8.8 0 01-.1-.3.7.7 0 01.2-.7L4 .9c.3-.3.7-.3 1 0z"
        fill="#FFF"
        fillRule="evenodd"
      />
    </svg>
  );
};

export const Cross: React.FC<DefaultProps> = (props) => {
  const classes = classNames({
    'c-icon': true,
    [`${props.classes}`]: Boolean(props.classes),
  });

  return (
    <svg width="11" height="11" viewBox="0 0 11 11" className={classes} {...props}>
      <path
        d="M1.28.23l.084.073 3.913 3.913L9.192.303a.75.75 0 0 1 1.133.977l-.073.084-3.914 3.913 3.914 3.915a.75.75 0 0 1-.976 1.133l-.084-.072-3.915-3.915-3.913 3.915A.75.75 0 0 1 .23 9.276l.073-.084 3.913-3.915L.303 1.364A.75.75 0 0 1 1.279.23z"
        fill="#0a4a8e"
        fillRule="evenodd"
      />
    </svg>
  );
};

export const TooltipIcon: React.FC<DefaultProps> = (props) => {
  const classes = classNames({
    'c-icon': true,
    [`${props.classes}`]: Boolean(props.classes),
  });

  return (
    <svg width="20" height="20" viewBox="0 0 20 20" className={classes} {...props}>
      <path
        d="M10 .25A9.75 9.75 0 0 1 19.75 10 9.75 9.75 0 0 1 10 19.75 9.75 9.75 0 0 1 9.724.254zm0 1.5l-.244.004A8.25 8.25 0 0 0 1.75 10 8.25 8.25 0 0 0 10 18.25 8.25 8.25 0 0 0 18.25 10 8.25 8.25 0 0 0 10 1.75zm-.001 11.5l.117.007a1 1 0 1 1-.226-.001l.109-.006zM10 4.75a.75.75 0 0 1 .743.648l.007.102v5a.75.75 0 0 1-1.493.102L9.25 10.5v-5a.75.75 0 0 1 .75-.75z"
        fill="#0a4a8e"
        fillRule="evenodd"
      />
    </svg>
  );
};

export const LoadingIcon: React.FC<DefaultProps> = (props) => {
  const classes = classNames({
    'c-icon': true,
    [`${props.classes}`]: Boolean(props.classes),
  });

  return (
    <svg width="21" height="21" viewBox="0 0 21 21" className={classes} {...props} xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.3 15.4c.3 0 .7.3.7.7v3.6a.7.7 0 01-1.5.1v-3.6c0-.4.3-.8.8-.8zM6.6 14c.3.3.3.7 0 1l-2.5 2.6a.8.8 0 01-1.1-1l2.5-2.6c.3-.3.8-.3 1.1 0zm10.2 1.9h.1l.6.6a.7.7 0 01-1 1.2v-.1l-.6-.6a.7.7 0 011-1.1zM4.3 9.5a.8.8 0 01.1 1.5H.7a.7.7 0 01-.1-1.5h3.6zm15.4 0a.7.7 0 01.1 1.5h-1.6a.7.7 0 01-.1-1.5h1.6zM4 3l2.6 2.5a.8.8 0 01-1 1.2L3 4A.7.7 0 014 3zm13.5 0c.3.3.3.7 0 1l-1.7 1.8a.7.7 0 01-1.1-1L16.5 3c.3-.3.8-.3 1 0zm-7.2-3c.3 0 .7.3.7.7V4a.7.7 0 01-1.5.1V.7c0-.4.3-.8.8-.8z"
        fill="#3C2E72"
        fillRule="evenodd"
      />
    </svg>
  );
};
export const ToggleSecondary: React.FC<DefaultProps> = (props) => {
  const classes = classNames({
    'c-icon': true,
    [`${props.classes}`]: Boolean(props.classes),
  });
  return (
    <svg width="72" height="36" viewBox="0 0 72 36" className={classes} {...props}>
      <g fill="none" fillRule="evenodd">
        <path
          d="M18 0h36c9.941 0 18 8.059 18 18s-8.059 18-18 18H18C8.059 36 0 27.941 0 18S8.059 0 18 0z"
          fill="#0a4a8e"
        />
        <path
          d="M54 4.5c7.456 0 13.5 6.044 13.5 13.5S61.456 31.5 54 31.5 40.5 25.456 40.5 18 46.544 4.5 54 4.5z"
          fill="#FFF"
        />
      </g>
    </svg>
  );
};

export const PencilIcon: React.FC<DefaultProps> = (props) => {
  const classes = classNames({
    'c-icon': true,
    [`${props.classes}`]: Boolean(props.classes),
  });

  return (
    <svg width="16" height="16" viewBox="0 0 16 16" className={classes} xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11 .8c.8-.7 1.9-.7 2.6 0l1.6 1.6c.7.7.7 1.8 0 2.5L5 15.2c-.3.4-.8.6-1.2.6H1a.7.7 0 01-.7-.8v-2.7c0-.4.1-.9.5-1.2zM9.9 4.2l-8 8v2.1h2v-.1l8-8-2-2zm2.7-2.4a.2.2 0 00-.4 0l-1.3 1.4 2 2 1.4-1.3v-.4z"
        fill="#3C2E72"
        fillRule="evenodd"
      />
    </svg>
  );
};

export const BinIcon: React.FC<DefaultProps> = (props) => {
  const classes = classNames({
    'c-icon': true,
    [`${props.classes}`]: Boolean(props.classes),
  });

  return (
    <svg width="12" height="15" className={classes} viewBox="0 0 12 15">
      <g fill="none" fillRule="evenodd">
        <path
          d="M11.25 2.864a.562.562 0 01.076 1.12l-.076.005h-.188v8.173c0 1.136-.85 2.078-1.936 2.147L9 14.313H3c-1.104 0-1.994-.9-2.059-2.021l-.003-.13V3.988L.75 3.99a.563.563 0 01-.076-1.12l.076-.005h10.5zM9.937 3.988H2.064v8.174c0 .537.375.968.842 1.02l.095.005h6c.478 0 .884-.397.933-.92l.005-.105V3.988zM4.5 6.04c.285 0 .52.212.557.487l.005.076v3.97a.562.562 0 01-1.12.077l-.005-.076V6.603c0-.31.252-.563.563-.563zm3 0c.285 0 .52.212.557.487l.005.076v3.97a.562.562 0 01-1.12.077l-.005-.076V6.603c0-.31.252-.563.563-.563zM8.25.283a.563.563 0 01.076 1.12l-.076.005h-4.5a.563.563 0 01-.076-1.12L3.75.283h4.5z"
          fill={props.fill ?? '#81C396'}
        />
      </g>
    </svg>
  );
};

export const Checkout: React.FC<DefaultProps> = (props) => {
  const classes = classNames({
    'c-icon': true,
    [`${props.classes}`]: Boolean(props.classes),
  });

  return (
    <svg viewBox="0 0 96 96" className={classes}>
      <g id="Checkout">
        <path d="M35,64a6,6,0,1,0,6,6A6,6,0,0,0,35,64Zm0,11a5,5,0,1,1,5-5A5,5,0,0,1,35,75Z" />
        <path d="M63,64a6,6,0,1,0,6,6A6,6,0,0,0,63,64Zm0,11a5,5,0,1,1,5-5A5,5,0,0,1,63,75Z" />
        <path d="M67.79,55a.49.49,0,0,0,.46-.31l11.6-29a.5.5,0,0,0-.46-.69H23.92L23,21.38s0,0,0,0a.26.26,0,0,0-.07-.15l0,0a.39.39,0,0,0-.1-.08l-.05,0a.34.34,0,0,0-.12,0H16.5a.5.5,0,0,0-.5.5v4a.5.5,0,0,0,.5.5h6.64l8,31.14A3,3,0,0,0,32,63H68.5a.5.5,0,0,0,.5-.5v-5a.5.5,0,0,0-.5-.5H32.15l-.52-2ZM78.65,26,67.45,54H31.62L24.39,26ZM17,25V22h5.11l.75,3ZM68,62H32a2,2,0,0,1,0-4H68Z" />
        <path d="M50,50A10,10,0,1,0,40,40,10,10,0,0,0,50,50Zm0-19a9,9,0,1,1-9,9A9,9,0,0,1,50,31Z" />
        <path d="M45,40.5h8.79l-2.64,2.65a.48.48,0,0,0,0,.7.48.48,0,0,0,.7,0l3.5-3.5a.36.36,0,0,0,.11-.16.5.5,0,0,0,0-.38.36.36,0,0,0-.11-.16l-3.5-3.5a.49.49,0,0,0-.7.7l2.64,2.65H45a.5.5,0,0,0,0,1Z" />
      </g>
    </svg>
  );
};

export const Register: React.FC<DefaultProps> = (props) => {
  const classes = classNames({
    'c-icon': true,
    [`${props.classes}`]: Boolean(props.classes),
  });

  return (
    <svg viewBox="0 0 512 512" className={classes}>
      <g id="E-learning_log-in_privacy">
        <path d="M465.3362,100.5972H343.0619a86.503,86.503,0,1,0-172.1062,0H46.6662a31.56,31.56,0,0,0-31.53,31.53v216.7c0,.0083-.0025.0161-.0025.0244s.0024.0166.0025.0249v41.2212a31.56,31.56,0,0,0,31.53,31.53H218.1875l-10.28,75.1313H183.5215a5,5,0,0,0,0,10h28.7451l.0163-.0015H301.635l.0163.0015h28.7452a5,5,0,0,0,0-10H306.0153l-10.28-75.1313H465.3362a31.5667,31.5667,0,0,0,31.53-31.53V132.127A31.5663,31.5663,0,0,0,465.3362,100.5972Zm0,10a21.55,21.55,0,0,1,21.53,21.53v11.91H325.8678a86.1794,86.1794,0,0,0,15.5689-33.44ZM257.0088,15.2417a76.503,76.503,0,1,1-76.503,76.5029A76.59,76.59,0,0,1,257.0088,15.2417ZM46.6662,110.5972H172.5808a86.1808,86.1808,0,0,0,15.5689,33.44H25.1363v-11.91A21.55,21.55,0,0,1,46.6662,110.5972Zm249.25,386.16h-77.92l10.28-75.13h57.36Zm169.42-85.13H46.6662a21.5565,21.5565,0,0,1-21.53-21.53V353.8511H414.99a5,5,0,1,0,0-10H25.1363V154.0371H197.0524a86.345,86.345,0,0,0,119.9127,0H486.8662v189.814h-49.49a5,5,0,0,0,0,10h49.49v36.2461A21.5566,21.5566,0,0,1,465.3362,411.627Z" />
        <path d="M255.998,366.063a16.7237,16.7237,0,1,0,16.7236,16.7236A16.7422,16.7422,0,0,0,255.998,366.063Zm0,23.4473a6.7237,6.7237,0,1,1,6.7236-6.7237A6.7318,6.7318,0,0,1,255.998,389.51Z" />
        <path d="M165.5644,224.9165a5.3156,5.3156,0,0,1-1.0879-.6133,2.3584,2.3584,0,0,1-.9716-1.72,2.98,2.98,0,0,1,.8017-2.3027,15.7756,15.7756,0,0,0,4.1143-12.1485,15.5591,15.5591,0,0,0-13.8926-14.1689,15.7446,15.7446,0,0,0-17.5244,15.6435,15.661,15.661,0,0,0,4.2158,10.71,2.9189,2.9189,0,0,1,.7461,2.4658,2.5761,2.5761,0,0,1-1.2959,1.8867,5.0466,5.0466,0,0,1-.5283.2539,17.7186,17.7186,0,0,0-10.8291,16.5528v.6758a8.8035,8.8035,0,0,0,8.7929,8.7939h29.5118a8.8035,8.8035,0,0,0,8.7929-8.7939v-.5752A17.8783,17.8783,0,0,0,165.5644,224.9165Zm1.8457,17.0293H138.3125v-.47a8.8675,8.8675,0,0,1,5.2558-8.2305,14.2578,14.2578,0,0,0,1.4639-.7031,11.5313,11.5313,0,0,0,5.8213-8.3447,11.8608,11.8608,0,0,0-3.0488-10.0157,6.7422,6.7422,0,1,1,9.8974-.0156,11.986,11.986,0,0,0-3.1543,9.2939,11.4148,11.4148,0,0,0,4.6348,8.1211,14.4226,14.4226,0,0,0,2.915,1.6416l.003.001a9.02,9.02,0,0,1,5.31,8.3526Z" />
        <path d="M226.5078,141.8823H285.48A14.689,14.689,0,0,0,300.1523,127.21l.0049-44.0117a14.68,14.68,0,0,0-13.7041-14.624V62.0562a29.3833,29.3833,0,1,0-58.7666,0v6.47h-1.1738A14.689,14.689,0,0,0,211.84,83.1978l-.0049,44.0117A14.6893,14.6893,0,0,0,226.5078,141.8823Zm11.1787-79.8261a19.3833,19.3833,0,1,1,38.7666,0v6.47H237.6865ZM221.84,83.1987a4.6787,4.6787,0,0,1,4.6729-4.6728h58.9716a4.6786,4.6786,0,0,1,4.6729,4.6719l-.0049,44.0117a4.6787,4.6787,0,0,1-4.6728,4.6728H226.5078a4.6786,4.6786,0,0,1-4.6729-4.6719Z" />
        <path d="M251.9941,112.6821v7.34a4,4,0,0,0,8,0v-7.34a13.4512,13.4512,0,1,0-8,0Zm4-18.2959a5.4517,5.4517,0,1,1-5.4512,5.4512A5.4572,5.4572,0,0,1,255.9941,94.3862Z" />
        <path d="M348.7168,193.3647H198.3945a13.8828,13.8828,0,0,0-13.8672,13.8672v30.3457a13.8836,13.8836,0,0,0,13.8672,13.8682H348.7168a13.8836,13.8836,0,0,0,13.8672-13.8682V207.2319A13.8828,13.8828,0,0,0,348.7168,193.3647Zm3.8672,44.2129a3.8719,3.8719,0,0,1-3.8672,3.8682H198.3945a3.8719,3.8719,0,0,1-3.8672-3.8682V207.2319a3.871,3.871,0,0,1,3.8672-3.8672H348.7168a3.871,3.871,0,0,1,3.8672,3.8672Z" />
        <path d="M242.1748,216.9624H226.9492a5,5,0,0,0,0,10h15.2256a5,5,0,0,0,0-10Z" />
        <path d="M281.2959,216.9624H266.07a5,5,0,0,0,0,10h15.2256a5,5,0,0,0,0-10Z" />
        <path d="M320.416,216.9624H305.19a5,5,0,0,0,0,10H320.416a5,5,0,1,0,0-10Z" />
        <path d="M348.7168,262.9771H198.3945a13.8828,13.8828,0,0,0-13.8672,13.8671V307.19a13.8828,13.8828,0,0,0,13.8672,13.8672H348.7168A13.8828,13.8828,0,0,0,362.584,307.19V276.8442A13.8828,13.8828,0,0,0,348.7168,262.9771ZM352.584,307.19a3.871,3.871,0,0,1-3.8672,3.8672H198.3945a3.871,3.871,0,0,1-3.8672-3.8672V276.8442a3.871,3.871,0,0,1,3.8672-3.8671H348.7168a3.871,3.871,0,0,1,3.8672,3.8671Z" />
        <path d="M242.1748,287.0171H226.9492a5,5,0,0,0,0,10h15.2256a5,5,0,0,0,0-10Z" />
        <path d="M281.2959,287.0171H266.07a5,5,0,0,0,0,10h15.2256a5,5,0,1,0,0-10Z" />
        <path d="M320.416,287.0171H305.19a5,5,0,1,0,0,10H320.416a5,5,0,0,0,0-10Z" />
        <path d="M168.2724,283.918v-8.9507a10.36,10.36,0,0,0-10.3476-10.3486h-10.127a10.3606,10.3606,0,0,0-10.3486,10.3486v8.9507a9.482,9.482,0,0,0-4.9766,8.3364v17.2578a9.485,9.485,0,0,0,9.4746,9.4746h21.8272a9.4858,9.4858,0,0,0,9.4756-9.4746V292.2544A9.4822,9.4822,0,0,0,168.2724,283.918Zm-20.4746-10.2993h10.127a1.35,1.35,0,0,1,1.3476,1.3486V282.78H146.4492v-7.8125A1.3507,1.3507,0,0,1,147.7978,273.6187ZM164.25,309.5122a.4753.4753,0,0,1-.4756.4746H141.9472a.4751.4751,0,0,1-.4746-.4746V292.2544a.4751.4751,0,0,1,.4746-.4746h21.8272a.4753.4753,0,0,1,.4756.4746Z" />
      </g>
    </svg>
  );
};

export const Checkbox: React.FC<DefaultProps> = (props) => {
  const classes = classNames({
    'c-icon': true,
    [`${props.classes}`]: Boolean(props.classes),
  });

  return (
    <svg
      width="32px"
      height="32px"
      viewBox="0 0 32 32"
      id="icon"
      className={classes}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M26,4H6A2,2,0,0,0,4,6V26a2,2,0,0,0,2,2H26a2,2,0,0,0,2-2V6A2,2,0,0,0,26,4ZM6,26V6H26V26Z" />
      <rect id="_Transparent_Rectangle_" data-name="&lt;Transparent Rectangle&gt;" width="32" height="32" />
    </svg>
  );
};

// export const CheckboxFilled: React.FC<DefaultProps> = (props) => {
//   const classes = classNames({
//     'c-icon': true,
//     'c-icon': true,
//     [`${props.classes}`]: Boolean(props.classes),
//   });

//   return (
//     <svg width="32px" height="32px" viewBox="0 0 32 32" id="icon"  xmlns="http://www.w3.org/2000/svg"><defs><style>.cls-1{fill:none;}</style></defs><title>checkbox--checked</title><path d="M26,4H6A2,2,0,0,0,4,6V26a2,2,0,0,0,2,2H26a2,2,0,0,0,2-2V6A2,2,0,0,0,26,4ZM6,26V6H26V26Z"/><polygon points="14 21.5 9 16.54 10.59 15 14 18.35 21.41 11 23 12.58 14 21.5"/><rect id="_Transparent_Rectangle_" data-name="&lt;Transparent Rectangle&gt;" className="cls-1" width="32" height="32"/></svg>
//   );
// };
