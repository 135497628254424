import { useHistory } from 'react-router-dom';
import Confirmation from '../../Shared/Confirmation';

const CheckoutConfirmation = () => {
  const history = useHistory();

  return (
    <Confirmation
      title="Gelukt!"
      text="We sturen alles zo snel mogelijk op zo dat jij snel kan beginnen met geld verdienen. Zodra er iets verandert in je bestelling krijg je een mail hierover."
      button={{ label: 'Terug naar home', onClick: () => history.push('/') }}
    />
  );
};

export default CheckoutConfirmation;
