import React from 'react';
import classNames from 'classnames';
import { useLoader } from '@utilities/context/LoaderContext';

const Loader: React.FC = () => {
  const { loading } = useLoader();

  const classes = classNames({
    'c-loader': true,
    'c-loader--hidden': !loading,
    'c-loader--visible': loading,
  });
  return (
    <div className={classes}>
      <div className="loader__ring">
        {Array(4)
          .fill(1)
          .map((x, index) => (
            <div key={x + index} className="loader__part"></div>
          ))}
      </div>
    </div>
  );
};

export default Loader;
