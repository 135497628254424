export const countries = [
  {
    value: 'AF',
    displayValue: 'Afghanistan',
  },
  {
    value: 'AX',
    displayValue: 'Åland',
  },
  {
    value: 'AL',
    displayValue: 'Albanië',
  },
  {
    value: 'DZ',
    displayValue: 'Algerije',
  },
  {
    value: 'VI',
    displayValue: 'Amerikaanse Maagdeneilanden',
  },
  {
    value: 'AS',
    displayValue: 'Amerikaans-Samoa',
  },
  {
    value: 'AD',
    displayValue: 'Andorra',
  },
  {
    value: 'AO',
    displayValue: 'Angola',
  },
  {
    value: 'AI',
    displayValue: 'Anguilla',
  },
  {
    value: 'AQ',
    displayValue: 'Antarctica',
  },
  {
    value: 'AG',
    displayValue: 'Antigua en Barbuda',
  },
  {
    value: 'AR',
    displayValue: 'Argentinië',
  },
  {
    value: 'AM',
    displayValue: 'Armenië',
  },
  {
    value: 'AW',
    displayValue: 'Aruba',
  },
  {
    value: 'AU',
    displayValue: 'Australië',
  },
  {
    value: 'AZ',
    displayValue: 'Azerbeidzjan',
  },
  {
    value: 'BS',
    displayValue: "Bahama's",
  },
  {
    value: 'BH',
    displayValue: 'Bahrein',
  },
  {
    value: 'BD',
    displayValue: 'Bangladesh',
  },
  {
    value: 'BB',
    displayValue: 'Barbados',
  },
  {
    value: 'BE',
    displayValue: 'België',
  },
  {
    value: 'BZ',
    displayValue: 'Belize',
  },
  {
    value: 'BJ',
    displayValue: 'Benin',
  },
  {
    value: 'BM',
    displayValue: 'Bermuda',
  },
  {
    value: 'BT',
    displayValue: 'Bhutan',
  },
  {
    value: 'BO',
    displayValue: 'Bolivia',
  },
  {
    value: 'BA',
    displayValue: 'Bosnië en Herzegovina',
  },
  {
    value: 'BW',
    displayValue: 'Botswana',
  },
  {
    value: 'BV',
    displayValue: 'Bouvet Bo',
  },
  {
    value: 'BR',
    displayValue: 'Brazilië',
  },
  {
    value: 'IO',
    displayValue: 'Brits Indische Oceaanterritorium',
  },
  {
    value: 'VG',
    displayValue: 'Britse Maagdeneilanden',
  },
  {
    value: 'BN',
    displayValue: 'Brunei',
  },
  {
    value: 'BG',
    displayValue: 'Bulgarije',
  },
  {
    value: 'BF',
    displayValue: 'Burkina Faso',
  },
  {
    value: 'BI',
    displayValue: 'Burundi',
  },
  {
    value: 'KH',
    displayValue: 'Cambodja',
  },
  {
    value: 'CA',
    displayValue: 'Canada',
  },
  {
    value: 'BQ',
    displayValue: 'Caribisch Nederland',
  },
  {
    value: 'CF',
    displayValue: 'Centraal-Afrikaanse Republiek',
  },
  {
    value: 'CL',
    displayValue: 'Chili',
  },
  {
    value: 'CN',
    displayValue: 'China',
  },
  {
    value: 'CX',
    displayValue: 'Christmaseiland',
  },
  {
    value: 'CC',
    displayValue: 'Cocoseilanden',
  },
  {
    value: 'CO',
    displayValue: 'Colombia',
  },
  {
    value: 'KM',
    displayValue: 'Comoren',
  },
  {
    value: 'CG',
    displayValue: 'Congo-Brazzaville',
  },
  {
    value: 'CD',
    displayValue: 'Congo-Kinshasa',
  },
  {
    value: 'CK',
    displayValue: 'Cookeilanden',
  },
  {
    value: 'CR',
    displayValue: 'Costa Rica',
  },
  {
    value: 'CU',
    displayValue: 'Cuba',
  },
  {
    value: 'CW',
    displayValue: 'Curaçao',
  },
  {
    value: 'CY',
    displayValue: 'Cyprus',
  },
  {
    value: 'PH',
    displayValue: 'de Filipijne',
  },
  {
    value: 'DK',
    displayValue: 'Denemarken',
  },
  {
    value: 'DJ',
    displayValue: 'Djibouti',
  },
  {
    value: 'DM',
    displayValue: 'Dominica',
  },
  {
    value: 'DO',
    displayValue: 'Dominicaanse Republiek',
  },
  {
    value: 'DE',
    displayValue: 'Duitsland',
  },
  {
    value: 'EC',
    displayValue: 'Ecuador',
  },
  {
    value: 'EG',
    displayValue: 'Egypte',
  },
  {
    value: 'SV',
    displayValue: 'El Salvador',
  },
  {
    value: 'GQ',
    displayValue: 'Equatoriaal-Guinea',
  },
  {
    value: 'ER',
    displayValue: 'Eritrea',
  },
  {
    value: 'EE',
    displayValue: 'Estland',
  },
  {
    value: 'ET',
    displayValue: 'Ethiopië',
  },
  {
    value: 'FO',
    displayValue: 'Faeröer',
  },
  {
    value: 'FK',
    displayValue: 'Falklandeilanden',
  },
  {
    value: 'FJ',
    displayValue: 'Fiji',
  },
  {
    value: 'FI',
    displayValue: 'Finland',
  },
  {
    value: 'FR',
    displayValue: 'Frankrijk',
  },
  {
    value: 'TF',
    displayValue: 'Franse Zuidelijke en Antarctische Gebieden',
  },
  {
    value: 'GF',
    displayValue: 'Frans-Guyana',
  },
  {
    value: 'PF',
    displayValue: 'Frans-Polynesië',
  },
  {
    value: 'GA',
    displayValue: 'Gabon',
  },
  {
    value: 'GM',
    displayValue: 'Gambia',
  },
  {
    value: 'GE',
    displayValue: 'Georgië',
  },
  {
    value: 'GH',
    displayValue: 'Ghana',
  },
  {
    value: 'GI',
    displayValue: 'Gibraltar',
  },
  {
    value: 'GD',
    displayValue: 'Grenada',
  },
  {
    value: 'GR',
    displayValue: 'Griekenland',
  },
  {
    value: 'GL',
    displayValue: 'Groenland',
  },
  {
    value: 'GP',
    displayValue: 'Guadeloupe',
  },
  {
    value: 'GU',
    displayValue: 'Guam',
  },
  {
    value: 'GT',
    displayValue: 'Guatemala',
  },
  {
    value: 'GG',
    displayValue: 'Guernsey',
  },
  {
    value: 'GN',
    displayValue: 'Guinee',
  },
  {
    value: 'GW',
    displayValue: 'Guinee-Bissau',
  },
  {
    value: 'GY',
    displayValue: 'Guyana',
  },
  {
    value: 'HT',
    displayValue: 'Haïti',
  },
  {
    value: 'HM',
    displayValue: 'Heard en McDonaldeilanden',
  },
  {
    value: 'HN',
    displayValue: 'Honduras',
  },
  {
    value: 'HU',
    displayValue: 'Hongarije',
  },
  {
    value: 'HK',
    displayValue: 'Hongkong',
  },
  {
    value: 'IE',
    displayValue: 'Ierland',
  },
  {
    value: 'IS',
    displayValue: 'IJsland',
  },
  {
    value: 'IN',
    displayValue: 'India',
  },
  {
    value: 'ID',
    displayValue: 'Indonesië',
  },
  {
    value: 'IQ',
    displayValue: 'Irak',
  },
  {
    value: 'IR',
    displayValue: 'Iran',
  },
  {
    value: 'IL',
    displayValue: 'Israël',
  },
  {
    value: 'IT',
    displayValue: 'Italië',
  },
  {
    value: 'CI',
    displayValue: 'Ivoorkust',
  },
  {
    value: 'JM',
    displayValue: 'Jamaica',
  },
  {
    value: 'JP',
    displayValue: 'Japan',
  },
  {
    value: 'YE',
    displayValue: 'Jemen',
  },
  {
    value: 'JE',
    displayValue: 'Jersey',
  },
  {
    value: 'JO',
    displayValue: 'Jordanië',
  },
  {
    value: 'KY',
    displayValue: 'Kaaimaneilanden',
  },
  {
    value: 'CV',
    displayValue: 'Kaapverdië',
  },
  {
    value: 'CM',
    displayValue: 'Kameroen',
  },
  {
    value: 'KZ',
    displayValue: 'Kazachstan',
  },
  {
    value: 'KE',
    displayValue: 'Kenia',
  },
  {
    value: 'KG',
    displayValue: 'Kirgizië',
  },
  {
    value: 'KI',
    displayValue: 'Kiribati',
  },
  {
    value: 'UM',
    displayValue: 'Kleine afgelegen eilanden van de Verenigde Staten',
  },
  {
    value: 'KW',
    displayValue: 'Koeweit',
  },
  {
    value: 'HR',
    displayValue: 'Kroatië',
  },
  {
    value: 'LA',
    displayValue: 'Laos',
  },
  {
    value: 'LS',
    displayValue: 'Lesotho',
  },
  {
    value: 'LV',
    displayValue: 'Letland',
  },
  {
    value: 'LB',
    displayValue: 'Libanon',
  },
  {
    value: 'LR',
    displayValue: 'Liberia',
  },
  {
    value: 'LY',
    displayValue: 'Libië',
  },
  {
    value: 'LI',
    displayValue: 'Liechtenstein',
  },
  {
    value: 'LT',
    displayValue: 'Litouwen',
  },
  {
    value: 'LU',
    displayValue: 'Luxemburg',
  },
  {
    value: 'MO',
    displayValue: 'Macau',
  },
  {
    value: 'MG',
    displayValue: 'Madagaskar',
  },
  {
    value: 'MW',
    displayValue: 'Malawi',
  },
  {
    value: 'MV',
    displayValue: 'Maldiven',
  },
  {
    value: 'MY',
    displayValue: 'Maleisië',
  },
  {
    value: 'ML',
    displayValue: 'Mali',
  },
  {
    value: 'MT',
    displayValue: 'Malta',
  },
  {
    value: 'IM',
    displayValue: 'Man',
  },
  {
    value: 'MA',
    displayValue: 'Marokko',
  },
  {
    value: 'MH',
    displayValue: 'Marshalleilanden',
  },
  {
    value: 'MQ',
    displayValue: 'Martinique',
  },
  {
    value: 'MR',
    displayValue: 'Mauritanië',
  },
  {
    value: 'MU',
    displayValue: 'Mauritius',
  },
  {
    value: 'YT',
    displayValue: 'Mayotte',
  },
  {
    value: 'MX',
    displayValue: 'Mexico',
  },
  {
    value: 'FM',
    displayValue: 'Micronesia',
  },
  {
    value: 'MD',
    displayValue: 'Moldavië',
  },
  {
    value: 'MC',
    displayValue: 'Monaco',
  },
  {
    value: 'MN',
    displayValue: 'Mongolië',
  },
  {
    value: 'ME',
    displayValue: 'Montenegro',
  },
  {
    value: 'MS',
    displayValue: 'Montserrat',
  },
  {
    value: 'MZ',
    displayValue: 'Mozambique',
  },
  {
    value: 'MM',
    displayValue: 'Myanmar',
  },
  {
    value: 'NA',
    displayValue: 'Namibië',
  },
  {
    value: 'NR',
    displayValue: 'Nauru',
  },
  {
    value: 'NL',
    displayValue: 'Nederland',
  },
  {
    value: 'NP',
    displayValue: 'Nepal',
  },
  {
    value: 'NI',
    displayValue: 'Nicaragua',
  },
  {
    value: 'NC',
    displayValue: 'Nieuw-Caledonië',
  },
  {
    value: 'NZ',
    displayValue: 'Nieuw-Zeeland',
  },
  {
    value: 'NE',
    displayValue: 'Niger',
  },
  {
    value: 'NG',
    displayValue: 'Nigeria',
  },
  {
    value: 'NU',
    displayValue: 'Niue',
  },
  {
    value: 'MP',
    displayValue: 'Noordelijke Marianen',
  },
  {
    value: 'KP',
    displayValue: 'Noord-Korea',
  },
  {
    value: 'MK',
    displayValue: 'Noord-Macedonië',
  },
  {
    value: 'NO',
    displayValue: 'Noorwegen',
  },
  {
    value: 'NF',
    displayValue: 'Norfolk',
  },
  {
    value: 'UG',
    displayValue: 'Oeganda',
  },
  {
    value: 'UA',
    displayValue: 'Oekraïne',
  },
  {
    value: 'UZ',
    displayValue: 'Oezbekistan',
  },
  {
    value: 'OM',
    displayValue: 'Oman',
  },
  {
    value: 'AT',
    displayValue: 'Oostenrijk',
  },
  {
    value: 'TL',
    displayValue: 'Oost-Timor',
  },
  {
    value: 'PK',
    displayValue: 'Pakistan',
  },
  {
    value: 'PW',
    displayValue: 'Palau',
  },
  {
    value: 'PS',
    displayValue: 'Palestina',
  },
  {
    value: 'PA',
    displayValue: 'Panama',
  },
  {
    value: 'PG',
    displayValue: 'Papua-Nieuw-Guinea ',
  },
  {
    value: 'PY',
    displayValue: 'Paraguay',
  },
  {
    value: 'PE',
    displayValue: 'Peru',
  },
  {
    value: 'PN',
    displayValue: 'Pitcairneilanden',
  },
  {
    value: 'PL',
    displayValue: 'Polen',
  },
  {
    value: 'PT',
    displayValue: 'Portugal',
  },
  {
    value: 'PR',
    displayValue: 'Puerto Rico',
  },
  {
    value: 'QA',
    displayValue: 'Qatar',
  },
  {
    value: 'RE',
    displayValue: 'Réunion',
  },
  {
    value: 'RO',
    displayValue: 'Roemenië',
  },
  {
    value: 'RU',
    displayValue: 'Rusland',
  },
  {
    value: 'RW',
    displayValue: 'Rwanda',
  },
  {
    value: 'KN',
    displayValue: 'Saint Kitts en Nevis',
  },
  {
    value: 'LC',
    displayValue: 'Saint Lucia',
  },
  {
    value: 'VC',
    displayValue: 'Saint Vincent en de Grenadines',
  },
  {
    value: 'BL',
    displayValue: 'Saint-Barthélemy',
  },
  {
    value: 'PM',
    displayValue: 'Saint-Pierre en Miquelon',
  },
  {
    value: 'SB',
    displayValue: 'Salomonseilanden',
  },
  {
    value: 'WS',
    displayValue: 'Samoa',
  },
  {
    value: 'SM',
    displayValue: 'San Marino',
  },
  {
    value: 'ST',
    displayValue: 'Sao Tomé en Principe',
  },
  {
    value: 'SA',
    displayValue: 'Saoedi-Arabië',
  },
  {
    value: 'SN',
    displayValue: 'Senegal',
  },
  {
    value: 'RS',
    displayValue: 'Servië',
  },
  {
    value: 'SC',
    displayValue: 'Seychellen',
  },
  {
    value: 'SL',
    displayValue: 'Sierra Leone',
  },
  {
    value: 'SG',
    displayValue: 'Singapore',
  },
  {
    value: 'SX',
    displayValue: 'Sint Maarten',
  },
  {
    value: 'SH',
    displayValue: 'Sint-Helena, Ascension en Tristan da Cunha',
  },
  {
    value: 'MF',
    displayValue: 'Sint-Maarten',
  },
  {
    value: 'SI',
    displayValue: 'Slovenië',
  },
  {
    value: 'SK',
    displayValue: 'Slowakije',
  },
  {
    value: 'SD',
    displayValue: 'Soedan',
  },
  {
    value: 'SO',
    displayValue: 'Somalië',
  },
  {
    value: 'ES',
    displayValue: 'Spanje',
  },
  {
    value: 'SJ',
    displayValue: 'Spitsbergen en Jan Mayen',
  },
  {
    value: 'LK',
    displayValue: 'Sri Lanka',
  },
  {
    value: 'SR',
    displayValue: 'Suriname',
  },
  {
    value: 'SZ',
    displayValue: 'Swaziland',
  },
  {
    value: 'SY',
    displayValue: 'Syrië',
  },
  {
    value: 'TJ',
    displayValue: 'Tadzjikistan',
  },
  {
    value: 'TW',
    displayValue: 'Taiwan',
  },
  {
    value: 'TZ',
    displayValue: 'Tanzania',
  },
  {
    value: 'TH',
    displayValue: 'Thailand',
  },
  {
    value: 'TG',
    displayValue: 'Togo',
  },
  {
    value: 'TK',
    displayValue: 'Tokelau',
  },
  {
    value: 'TO',
    displayValue: 'Tonga',
  },
  {
    value: 'TT',
    displayValue: 'Trinidad en Tobago',
  },
  {
    value: 'TD',
    displayValue: 'Tsjaad',
  },
  {
    value: 'CZ',
    displayValue: 'Tsjechië',
  },
  {
    value: 'TN',
    displayValue: 'Tunesië',
  },
  {
    value: 'TR',
    displayValue: 'Turkije',
  },
  {
    value: 'TM',
    displayValue: 'Turkmenistan',
  },
  {
    value: 'TC',
    displayValue: 'Turks- en Caicoseilanden',
  },
  {
    value: 'TV',
    displayValue: 'Tuvalu',
  },
  {
    value: 'UY',
    displayValue: 'Uruguay',
  },
  {
    value: 'VU',
    displayValue: 'Vanuatu',
  },
  {
    value: 'VA',
    displayValue: 'Vaticaanstad',
  },
  {
    value: 'VE',
    displayValue: 'Venezuela',
  },
  {
    value: 'GB',
    displayValue: 'Verenigd Koninkrijk',
  },
  {
    value: 'AE',
    displayValue: 'Verenigde Arabische Emiraten',
  },
  {
    value: 'US',
    displayValue: 'Verenigde Staten',
  },
  {
    value: 'VN',
    displayValue: 'Vietnam',
  },
  {
    value: 'WF',
    displayValue: 'Wallis en Futuna',
  },
  {
    value: 'EH',
    displayValue: 'Westelijke Sahara',
  },
  {
    value: 'BY',
    displayValue: 'Wit-Rusland',
  },
  {
    value: 'ZM',
    displayValue: 'Zambia',
  },
  {
    value: 'ZW',
    displayValue: 'Zimbabwe',
  },
  {
    value: 'ZA',
    displayValue: 'Zuid-Afrika',
  },
  {
    value: 'GS',
    displayValue: 'Zuid-Georgia en de Zuidelijke Sandwicheilanden',
  },
  {
    value: 'KR',
    displayValue: 'Zuid-Korea',
  },
  {
    value: 'SS',
    displayValue: 'Zuid-Soedan',
  },
  {
    value: 'SE',
    displayValue: 'Zweden',
  },
  {
    value: 'CH',
    displayValue: 'Zwitserland',
  },
];
