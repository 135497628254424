/**
 * Generated by orval v6.6.4 🍺
 * Do not edit manually.
 * Brinks Onboarding API v2023.9.6.948
 * Onboarding API (Development)
 * OpenAPI spec version: 1.0
 */
import axios,{
  AxiosRequestConfig,
  AxiosResponse
} from 'axios'
import type {
  BlubeemRequestsApiTransactionRequest,
  BlubeemRequestsApiPennyTestTransactionRequest,
  BlubeemResponsesApiTwikeyFeedUpdateResponse
} from './brinks.schemas'



  /**
 * @summary Creates a transaction request towards a Mandate
 */
export const postTransaction = <TData = AxiosResponse<string>>(
    blubeemRequestsApiTransactionRequest: BlubeemRequestsApiTransactionRequest, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.post(
      `/Transaction`,
      blubeemRequestsApiTransactionRequest,options
    );
  }
/**
 * @summary Performs a Penny Test towards a Mandate
 */
export const postTransactionPennyTest = <TData = AxiosResponse<string>>(
    blubeemRequestsApiPennyTestTransactionRequest: BlubeemRequestsApiPennyTestTransactionRequest, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.post(
      `/Transaction/PennyTest`,
      blubeemRequestsApiPennyTestTransactionRequest,options
    );
  }
export const getTransactionFeedUpdate = <TData = AxiosResponse<BlubeemResponsesApiTwikeyFeedUpdateResponse>>(
     options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.get(
      `/Transaction/FeedUpdate`,options
    );
  }
