import React from 'react';
import StyledButton from './StyledButton';
import { ToggleSecondary } from './svg/icons';

export interface ConfirmationProps {
  title: string;
  text: string;
  button?: ConfirmationButtonProps;
  noMailButton?: ConfirmationButtonProps;
}

interface ConfirmationButtonProps {
  label: string;
  url?: string;
  onClick?: () => void;
}

const Confirmation: React.FC<ConfirmationProps> = ({ title, text, button, noMailButton }) => {
  return (
    <section className="c-confirmation u-background--primary">
      <div className="o-container confirmation__container">
        <div className="confirmation__content">
          <div className="confirmation__icon">
            <ToggleSecondary />
          </div>
          <h1 className="confirmation__title">{title}</h1>
          <p className="confirmation__text" dangerouslySetInnerHTML={{ __html: text }} />

          <div className="confirmation__buttons">
            {noMailButton && (
              <StyledButton
                tag="a"
                text={noMailButton.label}
                href={noMailButton.url}
                onClick={noMailButton.onClick}
                classes="confirmation__button"
              />
            )}
            {button && <StyledButton tag="button" href={button.url} text={button.label} onClick={button.onClick} />}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Confirmation;
