/**
 * Generated by orval v6.6.4 🍺
 * Do not edit manually.
 * Brinks Onboarding API v2023.9.6.948
 * Onboarding API (Development)
 * OpenAPI spec version: 1.0
 */
import axios,{
  AxiosRequestConfig,
  AxiosResponse
} from 'axios'
import type {
  BlubeemModelsApiLocation
} from './brinks.schemas'



  /**
 * @summary Creates a location
 */
export const postLocation = <TData = AxiosResponse<void>>(
    blubeemModelsApiLocation: BlubeemModelsApiLocation, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.post(
      `/Location`,
      blubeemModelsApiLocation,options
    );
  }
/**
 * @summary Gets all Locations for this opportunity !!!!!  so get the opportunity for this account where Soure=Blubeem or Brink's
 */
export const getLocation = <TData = AxiosResponse<BlubeemModelsApiLocation[]>>(
     options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.get(
      `/Location`,options
    );
  }
/**
 * @summary Updates the location
 */
export const putLocation = <TData = AxiosResponse<void>>(
    blubeemModelsApiLocation: BlubeemModelsApiLocation, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.put(
      `/Location`,
      blubeemModelsApiLocation,options
    );
  }
export const deleteLocation = <TData = AxiosResponse<void>>(
    deleteLocationBody: string[], options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.delete(
      `/Location`,{data:
      deleteLocationBody, ...options}
    );
  }
/**
 * @summary Gets a Location
 */
export const getLocationId = <TData = AxiosResponse<BlubeemModelsApiLocation>>(
    id: string, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.get(
      `/Location/${id}`,options
    );
  }
/**
 * @summary Deletes the Location
 */
export const deleteLocationId = <TData = AxiosResponse<void>>(
    id: string, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.delete(
      `/Location/${id}`,options
    );
  }
