import AutoAddress from '@components/Shared/AutoAddress';
import { FormikSelect } from '@components/Shared/form-elements/FormikSelect';
import StyledButton from '@components/Shared/StyledButton';
import { FormikInput } from '@shared/form-elements/FormikInput';
import { Modal } from '@shared/modal/Modal';
import { LoadingIcon } from '@shared/svg/icons';
import { useModalActions } from '@utilities/context/ModalContext';
import { CheckoutStepThreeAddress } from '@utilities/validation';
import { Form, Formik } from 'formik';
import React, { useCallback, useState } from 'react';
import { LocationDetailsFormValues } from '../edit-location/location-details.model';

interface AddressModalProps {
  id: string;
  title: string;
  initialValues: LocationDetailsFormValues;
  onSubmit: (values: LocationDetailsFormValues) => void;
}

const AddressModal: React.FC<AddressModalProps> = ({ initialValues, id, title, onSubmit }) => {
  const [loading, setLoading] = useState(false);
  const { hide } = useModalActions();

  const submit = useCallback(
    (values: LocationDetailsFormValues) => {
      setLoading(true);
      setTimeout(() => {
        onSubmit(values);
        hide('contact-modal');
        setLoading(false);
      }, 600);
    },
    [hide, setLoading, onSubmit],
  );

  return (
    <Modal id={id} title={title} isClosable={true}>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={CheckoutStepThreeAddress}
        onSubmit={submit}
      >
        <Form>
          <div className="address__modal">
            <FormikInput formType="tertiary" name="name" label="Bedrijfsnaam" type="text" />

            <AutoAddress
              zipCode={<FormikInput formType="tertiary" name="address.zipCode" label="Postcode" type="text" />}
              houseNumber={
                <FormikInput formType="tertiary" name="address.houseNumber" label="Huisnummer" type="text" />
              }
              street={<FormikInput formType="tertiary" name="address.street" label="Straat" type="text" />}
              city={<FormikInput formType="tertiary" name="address.city" label="Plaats" type="text" />}
              // This will probably be added back to the project once we added BE handling for foreign addresses
              // country={
              //   <FormikSelect
              //     label="Land"
              //     hideLabel
              //     name="address.country"
              //     placeholder="Kies land"
              //     options={countries.map((item) => ({
              //       value: item.value,
              //       displayValue: item.displayValue,
              //     }))}
              //   />
              // }
            />

            <h4 className="location__title">Contactgegevens</h4>

            <div className="address__modal-row">
              <FormikInput
                formType="tertiary"
                classes="form__item--half"
                name="contact.firstname"
                label="Voornaam"
                type="text"
              />
              <FormikInput
                formType="tertiary"
                classes="form__item--half"
                name="contact.lastname"
                label="Achternaam"
                type="text"
              />
            </div>
            <div className="address__modal-row">
              <FormikInput
                formType="tertiary"
                classes="form__item--half"
                name="contact.email"
                label="E-mailadres"
                type="text"
              />
              <FormikInput
                formType="tertiary"
                classes="form__item--half"
                name="contact.mobilePhone"
                label="Telefoonnummer"
                type="text"
              />
            </div>
            <FormikSelect
              label="Aanhef"
              hideLabel
              name="contact.gender"
              placeholder="Selecteer aanhef"
              options={[
                { value: 'M', displayValue: 'De Heer' },
                { value: 'F', displayValue: 'Mevrouw' },
              ]}
            />

            <StyledButton tag="button" type="submit" text="Opslaan" classes={loading ? 'button--icon' : ''}>
              {loading && <LoadingIcon classes={'button__icon button__icon--loading'} />}
            </StyledButton>
          </div>
        </Form>
      </Formik>
    </Modal>
  );
};

export default AddressModal;
