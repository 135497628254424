import { FormikMultiSelect } from '@components/Shared/form-elements/FormikMultiSelect';
import { FormikNumberInput } from '@components/Shared/form-elements/FormikNumberInput';
import { FormikRadio } from '@components/Shared/form-elements/FormikRadio';
import { FormikRange } from '@components/Shared/form-elements/FormikRange';
import { FormikSelect } from '@components/Shared/form-elements/FormikSelect';
import { BlubeemModelsApiPicklistValue, BlubeemModelsApiProduct } from '@generated/brinks.schemas';
import Collapse from '@shared/collapse/Collapse';
import { FormikProductInput } from '@shared/form-elements/FormikProductInput';
import { FormikToggle } from '@shared/form-elements/FormikToggle';
import { countries } from '@utilities/countries';
import { useFormikContext } from 'formik';
import React, { useCallback, useMemo, useState } from 'react';
import { CheckoutStep4FormData } from '../../models/checkout-step-4-form.model';
import { ServiceType } from '../../models/service-type.enum';
import Accessories from '../shared/Accessories';
import FormTitle from '../shared/FormTitle';
import GroupButton from '../shared/GroupButton';

const PAGE_SERVICE_TYPE = ServiceType.Clicks;

interface Props {
  products: BlubeemModelsApiProduct[];
  reasonForSwitchingPicklistValues: BlubeemModelsApiPicklistValue[];
  formerPSPPicklistValues: BlubeemModelsApiPicklistValue[];
  resetValue: boolean;
  resetAccessoires: number;
}

export const Clicks: React.FC<Props> = ({
  products,
  reasonForSwitchingPicklistValues,
  formerPSPPicklistValues,
  resetValue,
  resetAccessoires,
}) => {
  const { setFieldValue, values, setFieldTouched } = useFormikContext<CheckoutStep4FormData>();
  const [clicksActive, setClicksActive] = useState<boolean>(true);

  const clicksProducts = useMemo(
    () => products.filter((product) => product.serviceType?.toLowerCase() === PAGE_SERVICE_TYPE),
    [products],
  );

  const serviceIndex = useMemo(
    () => values.services.findIndex((service) => service.name === PAGE_SERVICE_TYPE),
    [values],
  );
  const active = useMemo(() => values.services.find((service) => service.name === PAGE_SERVICE_TYPE).active, [values]);

  const setAllTogglesTouchedForValidation = useCallback(() => {
    values.services.forEach((_, index) => setFieldTouched(`services[${index}].active`, true, true));
  }, [values.services, setFieldTouched]);

  return (
    <>
      <div className='cash-cards-clicks__button-toggle'>
      <GroupButton title={'Clicks'} defaultActive={true} onToggle={setClicksActive}>
        <div className="cash-cards-clicks__item">
        <FormikToggle
            formType="secondary"
            label={active ? 'Zet clicks uit' : 'Zet clicks aan'}
            checkedLabel={'Zet clicks uit'}
            uncheckedLabel={'Zet clicks aan'}
            name={`services[${serviceIndex}].active`}
            hideLabel={true}
            optimized={false}
            onChange={() => {
              setFieldValue(`services[${serviceIndex}].active`, !active);
              setAllTogglesTouchedForValidation();
            }}
          />
        </div>
        </GroupButton>
      </div>
      <Collapse active={active}>
        <div className="cash-cards-clicks__item">
          <FormikNumberInput
            title="Levertijd goederen"
            label="Levertijd goederen"
            subtitle="Gemiddelde doorlooptijd in dagen"
            name={`services[${serviceIndex}].clicksLeadTimeInDays`}
            formType="tertiary"
          />
        </div>
        <div className="cash-cards-clicks__item">
          <FormikProductInput
            label="Wat voor producten heeft u?"
            labelSubtitle="Type producten dat online wordt verkocht"
            name={`services[${serviceIndex}].productTypesOnline`}
            valueToArray
            optimized={false}
            placeholder="Bijvoorbeeld: schoenen, paraplu’s"
          />
        </div>
        <div className="cash-cards-clicks__item">
          <FormikProductInput
            label="Url webshop"
            labelSubtitle="De link van de website"
            optimized={false}
            name={`services[${serviceIndex}].webshopUrl`}
          />
        </div>
        <div className="cash-cards-clicks__item">
          <FormikNumberInput
            title="Gemiddelde omzet clicks per maand"
            label="Gemiddelde omzet clicks per maand"
            subtitle="Verwachte omzet aan clicks per maand"
            name={`services[${serviceIndex}].averageOnlineRevenuePerMonth`}
            formType="tertiary"
            prefix="€"
          />
        </div>
        <div className="cash-cards-clicks__item">
          <FormikRange
            label="Periodieke / seizoensgebonden omzet"
            name={`services[${serviceIndex}].clicksSeasonalRevenuePercentage`}
            labelSubtitle="Hoeveel procent van je omzet is periodiek of seizoensgebonden?"
            suffix="%"
            resetValue={resetValue}
          />
        </div>
        <div className="cash-cards-clicks__item">
          <FormikMultiSelect
            label="Gericht op welke landen"
            labelSubtitle="Gericht op welke landen"
            name={`services[${serviceIndex}].selectedCountries`}
            placeholder="Kies land"
            options={countries.map((current) => ({
              value: current.value,
              label: current.displayValue,
            }))}
          />
        </div>
        <div className="cash-cards-clicks__item">
          <FormikRange
            name={`services[${serviceIndex}].clicksAverageRevenuePerPaymentMethodDifference`}
            label="Percentage omzet per betaalmethode"
            labelSubtitle="Percentage creditcard versus overboeking & online bankieren"
            suffix="%"
            minLabel="Creditcard"
            maxLabel="Overboeking & online bankieren"
            split
            initialValue={0}
            resetValue={resetValue}
          />
        </div>
        <div className="cash-cards-clicks__item">
          <FormikRadio
            name={`services[${serviceIndex}].recurringCreditCardPayments`}
            label="Terugkerende Credit Card Betalingen"
            labelSubtitle=""
            options={[
              { value: 'true', displayValue: 'Ja' },
              { value: 'false', displayValue: 'Nee' },
            ]}
          />
        </div>
        {clicksProducts.length > 0 && (
          <Accessories
            products={clicksProducts}
            service={values?.services[serviceIndex]}
            resetValue={resetValue}
            resetAccessoires={resetAccessoires}
          />
        )}
        <div className="cash-cards-clicks__item">
          <FormikRange
            name={`services[${serviceIndex}].clicksConsumptionDistributionDifference`}
            label="Verdeling consumenten / zakelijk"
            labelSubtitle="Percentage consumenten versus zakelijke klanten"
            suffix="%"
            minLabel="Consumenten"
            maxLabel="Zakelijk"
            split
            initialValue={0}
            resetValue={resetValue}
          />
        </div>
        <div className="cash-cards-clicks__item">
          <FormikRange
            name={`services[${serviceIndex}].clicksDomesticTargetMarketPercentage`}
            label="Percentage doelmarkt binnenland"
            suffix="%"
            resetValue={resetValue}
          />
        </div>
        <div className="cash-cards-clicks__item">
          <FormikSelect
            optimized={false}
            label="Land binnenland"
            labelSubtitle="Welk land is je doelmarkt?"
            name={`services[${serviceIndex}].clicksDomesticTargetCountryName`}
            placeholder="Kies land"
            options={countries.map((current) => ({
              value: current.value,
              displayValue: current.displayValue,
            }))}
          />
        </div>
        <div className="cash-cards-clicks__item">
          <FormikRange
            name={`services[${serviceIndex}].clicksIntraRegionalTargetMarketDifference`}
            label="Verdeling intraregionaal EU/EER"
            labelSubtitle="Percentage binnen en buiten EU"
            suffix="%"
            minLabel="Binnen EU"
            maxLabel="Buiten EU"
            split
            resetValue={resetValue}
          />
        </div>
        <div className="cash-cards-clicks__item">
          <FormikMultiSelect
            label="Intraregionale doelmarkt binnen EU"
            labelSubtitle="Selecteer landen"
            name={`services[${serviceIndex}].clicksIntraRegionalTargetMarketInEuCountries`}
            placeholder="Kies land"
            options={countries.map((current) => ({
              value: current.value,
              label: current.displayValue,
            }))}
          />
        </div>
        <div className="cash-cards-clicks__item">
          <FormikMultiSelect
            label="Intraregionale doelmarkt buiten EU"
            labelSubtitle="Selecteer landen"
            placeholder="Kies land"
            name={`services[${serviceIndex}].clicksIntraRegionalTargetMarketOutsideEuCountries`}
            options={countries.map((current) => ({
              value: current.value,
              label: current.displayValue,
            }))}
          />
        </div>
        <div className="cash-cards-clicks__item">
          <FormikNumberInput
            label="Verwacht totaal volume per jaar (EUR)"
            title="Verwacht totaal volume per jaar (EUR)"
            name={`services[${serviceIndex}].clicksExpectedTotalVolumeAnnually`}
            formType="tertiary"
            prefix="€"
          />
        </div>
        <div className="cash-cards-clicks__item">
          <FormikNumberInput
            label="Gemiddelde transactiewaarde"
            title="Gemiddelde transactiewaarde"
            subtitle="Gemiddelde transactiewaarde per betaling"
            name={`services[${serviceIndex}].clicksAverageTransactionValue`}
            formType="tertiary"
            prefix="€"
          />
        </div>
        <div className="cash-cards-clicks__item">
          <FormikNumberInput
            label="Hoogste transactiewaarde"
            title="Hoogste transactiewaarde"
            subtitle="Hoogste transactiewaarde per betaling"
            name={`services[${serviceIndex}].clicksHighestTransactionValue`}
            formType="tertiary"
            prefix="€"
          />
        </div>
        <FormTitle variant="primary" title="Verwerkingsgeschiedenis" />
        <div className="cash-cards-clicks__item">
          <FormikRadio
            name={`services[${serviceIndex}].madePaymentsWithAnotherPsp`}
            label="Verwerkingsgeschiedenis"
            labelSubtitle="Heeft u in het verleden betalingen via een andere betaaldienstverlener uitgevoerd?"
            options={[
              { value: 'true', displayValue: 'Ja' },
              { value: 'false', displayValue: 'Nee' },
            ]}
          />
        </div>
        <Collapse active={(values.services[serviceIndex].madePaymentsWithAnotherPsp as any) === 'true'}>
          <div className="cash-cards-clicks__item">
            <FormikSelect
              optimized={false}
              label="Naam voormalige betaaldienstverlener"
              name={`services[${serviceIndex}].clicksNameFormerPsp`}
              placeholder="Kies betaaldienstverlener"
              options={formerPSPPicklistValues.map((value) => ({
                value: value.value,
                displayValue: value.displayValue,
              }))}
            />
          </div>
          <div className="cash-cards-clicks__item">
            <FormikSelect
              optimized={false}
              label="Reden van overstap"
              name={`services[${serviceIndex}].clicksReasonForSwitching`}
              placeholder="Kies reden"
              options={reasonForSwitchingPicklistValues.map((value) => ({
                value: value.value,
                displayValue: value.displayValue,
              }))}
            />
          </div>
          <div className="cash-cards-clicks__item">
            <FormikNumberInput
              title="Aantal transacties huidige dienstverlener in de afgelopen 3 maanden"
              label="Betaalhistorie huidige dienstverlener"
              subtitle=""
              name={`services[${serviceIndex}].clicksProcessedVolumeLastThreeMonths`}
              formType="tertiary"
            />
          </div>
          <div className="cash-cards-clicks__item">
            <FormikRange
              name={`services[${serviceIndex}].clicksReturnPaymentPercentage`}
              label="Terugbetalingen (% van volume)"
              formType="tertiary"
              suffix="%"
              resetValue={resetValue}
            />
          </div>
          <div className="cash-cards-clicks__item">
            <FormikRange
              name={`services[${serviceIndex}].clicksChargebackPercentage`}
              label="Terugvorderingen (% van volume)"
              formType="tertiary"
              suffix="%"
              resetValue={resetValue}
            />
          </div>
          <div className="cash-cards-clicks__item">
            <FormikRange
              name={`services[${serviceIndex}].clicksFraudVolumePercentage`}
              label="Fraude (% van volume)"
              formType="tertiary"
              suffix="%"
              resetValue={resetValue}
            />
          </div>
        </Collapse>
      </Collapse>
    </>
  );
};
