export const reasonForSwitching = [
  {
    value: 'Pricing too expensive',
    displayValue: 'Pricing too expensive',
  },
  {
    value: 'Quality of the service not as expected',
    displayValue: 'Quality of the service not as expected',
  },
  {
    value: 'Poor customer services',
    displayValue: 'Poor customer services',
  },
  {
    value: 'Could not offer online payments',
    displayValue: 'Could not offer online payments',
  },
  {
    value: 'Could not offer in-store payments',
    displayValue: 'Could not offer in-store payments',
  },
  {
    value: 'Could not offer cash',
    displayValue: 'Could not offer cash',
  },
  {
    value: 'Other',
    displayValue: 'Other',
  },
];
