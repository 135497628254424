import React, { ReactNode } from 'react';
import classNames from 'classnames';
import { Company } from '@utilities/interfaces/user';

interface TileProps {
  variant: string | undefined;
  children?: ReactNode;
  classes?: string;
}

type CompanyTileProps = TileProps & Company;

const CompanyTile: React.FC<CompanyTileProps | any> = ({ variant = 'primary', ...props }) => {
  const classes = classNames({
    'c-company-tile': true,
    [`c-company-tile--${variant}`]: Boolean(variant),
    [`${props.classes}`]: Boolean(props.classes),
  });

  return (
    <div className={classes}>
      {props.name && <span>{props.name}</span>}
      {props.address && (
        <>
          <span>
            {props.address.street ? `${props.address.street} ` : ''}
            {props.address.houseNumber ? props.address.houseNumber : ''}
          </span>
          <span>
            {props.address.zipCode ? `${props.address.zipCode}, ` : ''}
            {props.address.city
              ? `${props.address.city.charAt(0)}${props.address.city.slice(1).toLowerCase()}`
              : ''}{' '}
            {props.address.country && `(${props.address.country})`}
          </span>
        </>
      )}
      {props.children && props.children}
      {props.registrationNumberTitle} {props.registrationNumber}
    </div>
  );
};

export default CompanyTile;
