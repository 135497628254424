/**
 * Generated by orval v6.6.4 🍺
 * Do not edit manually.
 * Brinks Onboarding API v2023.9.6.948
 * Onboarding API (Development)
 * OpenAPI spec version: 1.0
 */
import axios,{
  AxiosRequestConfig,
  AxiosResponse
} from 'axios'
import type {
  BlubeemResponsesIdentificationResponse,
  BlubeemRequestsIdentificationRequest,
  BlubeemRequestsIdentificationProcessRequest
} from './brinks.schemas'



  export const postSignicat = <TData = AxiosResponse<BlubeemResponsesIdentificationResponse>>(
    blubeemRequestsIdentificationRequest: BlubeemRequestsIdentificationRequest, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.post(
      `/Signicat`,
      blubeemRequestsIdentificationRequest,options
    );
  }
export const putSignicat = <TData = AxiosResponse<string>>(
    blubeemRequestsIdentificationProcessRequest: BlubeemRequestsIdentificationProcessRequest, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.put(
      `/Signicat`,
      blubeemRequestsIdentificationProcessRequest,options
    );
  }
