import { BlubeemModelsApiLocationAccessories, BlubeemModelsApiProduct } from '@generated/brinks.schemas';
import { CompleteAccessory } from './complete-accessory.model';
import { CleanUpTraceNumber } from '@utilities/regex-helper';

export function createCompleteAccessory(
  product: BlubeemModelsApiProduct,
  accessories: BlubeemModelsApiLocationAccessories[],
): CompleteAccessory {
  const matchAccessory = accessories
    .filter(Boolean)
    .find((accessory) => CleanUpTraceNumber(accessory.productName) === CleanUpTraceNumber(product.name));

  return {
    id: product.id,
    name: CleanUpTraceNumber(product.name),
    price: product.price,
    description: product.description,
    avatar: product.avatar,
    quantity: matchAccessory?.quantity || 0,
    isMaxQuantityOne: product.maxQuantity === 1,
  };
}
