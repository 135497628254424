/* eslint-disable prefer-const */
import {
  domainTooLongMessage,
  invalidEmail,
  invalidPhone,
  invalidPostalCode,
  maxCharacters,
  minCharacters,
  requiredMessage,
  validWebAddressMessage,
} from '@utilities/validation/messages';
import {
  belgianVat,
  belgiumPostcode,
  dutchPostcode,
  dutchVat,
  emailRegExp,
  phoneRegExp,
  urlRegExp,
} from '@utilities/validation/regex';
import * as yup from 'yup';

export interface LocationError {
  locations?: string;
  hasContact?: boolean[];
}

export const RegistrationOne = yup.object().shape({
  applicant: yup.object().shape({
    email: yup
      .string()
      .matches(emailRegExp, invalidEmail)
      .max(40, ({ max }) => maxCharacters(max))
      .required(requiredMessage),
    mobilePhone: yup.string().matches(phoneRegExp, invalidPhone).required(requiredMessage),
    acceptTerms: yup.bool().oneOf([true], 'Om door te gaan dient u onze voorwaarden te accepteren'),
  }),
});

export const RegistrationTwo = (hasCardsOrClicks) =>
  yup.object().shape({
    applicant: yup.object().shape({
      firstname: yup
        .string()
        .min(2, ({ min }) => minCharacters(min))
        .max(40, ({ max }) => maxCharacters(max))
        .required(requiredMessage),
      middlename: yup.string().max(40, ({ max }) => maxCharacters(max)),
      lastname: yup
        .string()
        .min(2, ({ min }) => minCharacters(min))
        .max(80, ({ max }) => maxCharacters(max))
        .required(requiredMessage),
    }),
    company: yup
      .object()
      .shape({
        vatNumber: yup
          .string()
          .test('checkVatNumber', 'Dit is geen geldig BTW nummer', function (this: yup.TestContext) {
            if (!this.parent.vatNumber) return false;
            if (this.parent.vatNumber.match(dutchVat) || this.parent.vatNumber.match(belgianVat)) return true;
            return false;
          })
          .required(requiredMessage),
        industry: yup
          .string()
          .when({
            is: () => !!hasCardsOrClicks,
            then: yup.string().required(requiredMessage),
          })
          .nullable(),
        website: yup
          .string()
          .when({
            is: () => !!hasCardsOrClicks,
            then: yup.string().matches(urlRegExp, validWebAddressMessage).optional().max(64, domainTooLongMessage),
          })
          .nullable(),
      })
      .nullable(),
  });

export const CheckoutStepOne = yup.object().shape({
  account: yup.object().shape({
    name: yup
      .string()
      .max(80, ({ max }) => maxCharacters(max))
      .required(requiredMessage),
    phone: yup.string().matches(phoneRegExp, invalidPhone).required(requiredMessage),
  }),
});

export const Locations = (values) => {
  const errors: LocationError = {};

  if (!values.locations.length) {
    errors.locations = 'Het is verplicht om minimaal 1 vestiging toe te voegen';
    return errors;
  }

  const missingContact = values.locations.some((location) => !location.contactId);
  const hasContact = values.locations.map((location) => !!location.contactId);

  if (missingContact) {
    document.body.scrollIntoView({ behavior: 'smooth', block: 'start' });
    errors.locations =
      "Voor één of meerdere vestigingen ontbreken de contactpersonen. Geef deze op via de 'Bewerken' knop";
    errors.hasContact = hasContact;
  }

  return errors;
};

export const CheckoutStepThreeAddress = yup.object().shape({
  name: yup.string().max(80, ({ max }) => maxCharacters(max)),
  address: yup.object().shape({
    street: yup
      .string()
      .nullable()
      .max(255, ({ max }) => maxCharacters(max))
      .required(requiredMessage),
    houseNumber: yup
      .string()
      .nullable()
      .max(255, ({ max }) => maxCharacters(max))
      .required(requiredMessage),
    zipCode: yup
      .string()
      .nullable()
      .test('postcode', invalidPostalCode, (value) => dutchPostcode.test(value) || belgiumPostcode.test(value))
      .required(requiredMessage),
    city: yup
      .string()
      .nullable()
      .max(80, ({ max }) => maxCharacters(max))
      .required(requiredMessage),
    // This will probably be added back to the project once we added BE handling for foreign addresses
    // country: yup.string().test('exists', selectAValueMessage, function (this: yup.TestContext) {
    //   const { country } = this.parent;
    //   return countries.includes(country);
    // }),
  }),
  contact: yup.object().shape({
    firstname: yup
      .string()
      .min(2, ({ min }) => minCharacters(min))
      .max(40, ({ max }) => maxCharacters(max))
      .required(requiredMessage),
    lastname: yup
      .string()
      .min(2, ({ min }) => minCharacters(min))
      .max(80, ({ max }) => maxCharacters(max))
      .required(requiredMessage),
    email: yup
      .string()
      .matches(emailRegExp, invalidEmail)
      .max(255, ({ max }) => maxCharacters(max))
      .required(requiredMessage),
    mobilePhone: yup.string().matches(phoneRegExp, invalidPhone).required(requiredMessage),
  }),
});

export const CheckoutStepFive = yup.object().shape({
  authorizedSignatory: yup.object().shape({
    phone: yup.string().matches(phoneRegExp, invalidPhone).required(requiredMessage),
    email: yup.string().matches(emailRegExp, invalidEmail).required(requiredMessage),
  }),
});
