import StyledButton from '@components/Shared/StyledButton';
import { postMandate } from '@generated/mandate';
import { postOpportunityComplete, putOpportunityStep } from '@generated/opportunity';
import { useUser } from '@state/user';
import { useLoader } from '@utilities/context/LoaderContext';
import { MandateProcessStatus } from '@utilities/interfaces/user';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import FormHeader from '../../../Shared/FormHeader';
import FormLayout from '../../../Shared/FormLayout';
import FormLayoutColOne from '../../../Shared/FormLayoutColOne';
import FormLayoutColTwo from '../../../Shared/FormLayoutColTwo';
import InfoBox from '../../../Shared/InfoBox';
import { Cross } from '../../../Shared/svg/icons';

const CheckoutSix: React.FC = () => {
  const { user, setUser } = useUser();
  const { toggleLoader } = useLoader();
  const history = useHistory();

  const createMandate = async () => {
    toggleLoader(true);
    const { data } = await postMandate();
    setUser({ mandateProcess: { mandateId: data.mandateId } });

    const twikeyUrl = data.protectedUrl;
    window.location.href = twikeyUrl;
  };

  const mandateProcessStatus = useMemo(() => user.mandateProcess && user.mandateProcess.status, [user.mandateProcess]);

  const onSubmit = useCallback(() => {
    postOpportunityComplete();
    history.push('confirmation');
  }, [history]);

  useEffect(() => {
    putOpportunityStep({ step: '6' });
  }, []);

  return (
    <FormLayout>
      <FormLayoutColOne>
        <FormHeader title="Machtiging" indicator="Stap 6/6" hasPrev prevUrl="step-5" />
        <InfoBox>
          <p style={{ hyphens: 'none' }}>
            Tot slot vragen we om een doorlopende zakelijke incassomachtiging. Met deze machtiging kunnen we de kosten
            afschrijven en je contante omzet bijschrijven. De machtiging wordt gecontroleerd door een eenmalige
            afschrijving van €0,01, de zogeheten pennytest.
          </p>
        </InfoBox>
      </FormLayoutColOne>
      <FormLayoutColTwo>
        {!mandateProcessStatus && (
          <button type="button" className="c-verify" onClick={() => createMandate()}>
            <div className="verify__icon-wrapper">
              <Cross classes="verify__icon" />
            </div>
            <p className="verify__content">Geef je machtiging</p>
          </button>
        )}
        {!!mandateProcessStatus && mandateProcessStatus !== MandateProcessStatus.SIGNED && (
          <button type="button" className="c-verify error" onClick={() => createMandate()}>
            <div className="verify__icon-wrapper">
              <Cross classes="verify__icon" />
            </div>
            <p className="verify__content">Verifieer opnieuw je machtiging</p>
          </button>
        )}
        {mandateProcessStatus === MandateProcessStatus.PENDING && (
          <div className="pending-info">Sommige validaties zijn nog in behandeling...</div>
        )}

        {mandateProcessStatus === MandateProcessStatus.SIGNED && (
          <div className="success-info">Machtiging succesvol afgerond</div>
        )}
        <StyledButton
          tag="button"
          text="Voltooien"
          formType="tertiary"
          disabled={!mandateProcessStatus}
          onClick={onSubmit}
        />
      </FormLayoutColTwo>
    </FormLayout>
  );
};

export default CheckoutSix;
