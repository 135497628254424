import { Checkout, Register } from '@shared/svg/icons/All';
import { renderMetaTags } from '@utilities/meta-tags';
import axios, { AxiosResponse } from 'axios';
import { useEffect } from 'react';
// eslint-ignore-next-line
import MetaTags from 'react-meta-tags';
import { BrowserRouter, Link, Route, Switch } from 'react-router-dom';
import './App.scss';
import ReactCheckout from './components/ReactCheckout/ReactCheckout';
import ReactRegister from './components/ReactRegister/ReactRegister';
import './sass/main.scss';
import { useUser } from './state';
import { useGetCurrentProduct } from '@utilities/hooks/useGetCurrentProduct';
import { addGoogleTagManager } from '@utilities/settings/GoogleTagManager';

axios.defaults.baseURL = process.env.REACT_APP_API_URL;
axios.defaults.headers.common['Application'] = 'Onboarding';
axios.interceptors.response.use(
  (res) => {
    return { data: res.data, status: res.status } as AxiosResponse<{
      data: any;
      status: string;
    }>;
  },
  (error) => Promise.reject(error),
);

function App() {
  const { user, setUser } = useUser();
  const { currentProduct } = useGetCurrentProduct();

  useEffect(() => {
    if (!currentProduct) {
      document.documentElement.className = user.productType;
      return;
    }
    setUser({ productType: currentProduct });

    document.documentElement.className = currentProduct || user.productType;
  }, [currentProduct]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    // Add the Google Tag Manager script to the head when the component mounts
    addGoogleTagManager(currentProduct);
  }, [currentProduct]);

  return (
    <>
      <MetaTags>{renderMetaTags(currentProduct || user.productType)}</MetaTags>
      <BrowserRouter>
        <Switch>
          <Route path="/" exact>
            <div className="main-test-header">
              <h3 style={{ color: 'var(--normal-text)' }}>Welcome to extracted apps or something</h3>
              <Link to={`/register/step-1/KCAS`} className={'icon-block'}>
                <Register />
                Register app
              </Link>
              <Link to={`/checkout/step-1?token=123&packageId=KCAS`} className={'icon-block'}>
                <Checkout />
                checkout app
              </Link>
            </div>
          </Route>
          <Route path="/register">
            <ReactRegister />
          </Route>
          <Route path="/checkout">
            <ReactCheckout />
          </Route>
        </Switch>
      </BrowserRouter>
    </>
  );
}

export default App;
