import { FastField, Field, useFormikContext } from 'formik';
import React from 'react';
import ProductInput, { ProductInputProps } from './ProductInput';

interface FormikInputProps extends ProductInputProps {
  optimized?: boolean;
}

export const FormikProductInput: React.FC<FormikInputProps> = ({ name, optimized = true, ...props }) => {
  const { setFieldTouched } = useFormikContext();
  const Component = optimized ? FastField : Field;

  return (
    <Component name={name}>
      {({ field, meta }) => (
        <ProductInput
          {...field}
          {...props}
          hasError={Boolean(meta.touched && meta.error)}
          onChange={(e: any) => {
            setFieldTouched(field.name);
            field.onChange(e);

            if (props.onChange) props.onChange(e.target.value);
          }}
        />
      )}
    </Component>
  );
};
