import * as yup from 'yup';
import { CheckoutStep4Service } from '../models/checkout-step-4-form.model';
import { CARDS_VALIDATION_SCHEMA } from './cards-validation-schema';
import { CASH_VALIDATION_SCHEMA } from './cash-validation-schema';
import { CLICKS_VALIDATION_SCHEMA } from './clicks-validation-schema';

export const selectPackageMessage = 'Selecteer minimaal 1 pakket';

export const CashCardsClickValidationSchema = yup.object().shape({
  services: yup.array().of(
    yup.object().shape({
      active: yup.boolean().test('is-one-true', selectPackageMessage, function (this: any) {
        const formServices = this.from[1].value.services as CheckoutStep4Service[];

        return formServices.filter((service) => service.active).length > 0;
      }),
      ...CASH_VALIDATION_SCHEMA,
      ...CARDS_VALIDATION_SCHEMA,
      ...CLICKS_VALIDATION_SCHEMA,
    }),
  ),
});
