/**
 * Generated by orval v6.6.4 🍺
 * Do not edit manually.
 * Brinks Onboarding API v2023.9.6.948
 * Onboarding API (Development)
 * OpenAPI spec version: 1.0
 */
import axios,{
  AxiosRequestConfig,
  AxiosResponse
} from 'axios'
import type {
  BlubeemRequestsApiUboRequest
} from './brinks.schemas'



  /**
 * @summary Creates an UBO
 */
export const postUbo = <TData = AxiosResponse<void>>(
    blubeemRequestsApiUboRequest: BlubeemRequestsApiUboRequest, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.post(
      `/Ubo`,
      blubeemRequestsApiUboRequest,options
    );
  }
/**
 * @summary Update the UBO, When UBO was not manually added, only generic data can be changed, like Email, MobilePhone, Phone, etc
 */
export const putUbo = <TData = AxiosResponse<void>>(
    blubeemRequestsApiUboRequest: BlubeemRequestsApiUboRequest, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.put(
      `/Ubo`,
      blubeemRequestsApiUboRequest,options
    );
  }
/**
 * @summary Gets all UBO's for this account
 */
export const getUbo = <TData = AxiosResponse<BlubeemRequestsApiUboRequest[]>>(
     options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.get(
      `/Ubo`,options
    );
  }
/**
 * @summary Gets the UBO
 */
export const getUboId = <TData = AxiosResponse<BlubeemRequestsApiUboRequest>>(
    id: string, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.get(
      `/Ubo/${id}`,options
    );
  }
/**
 * @summary Deletes the UBO
 */
export const deleteUboId = <TData = AxiosResponse<void>>(
    id: string, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.delete(
      `/Ubo/${id}`,options
    );
  }
