import React, { ReactNode, useState } from 'react';
import Collapse from '@shared/collapse/Collapse';
import classNames from 'classnames';

interface GroupButtonProps {
  children: ReactNode;
  title: string;
  defaultActive: boolean | undefined;
  onToggle: (active: boolean) => void;
}

const GroupButton: React.FC<GroupButtonProps> = ({ defaultActive = true, title, children, onToggle }) => {
  const [active, setActive] = useState<boolean>(defaultActive);

  const warpperClasses = classNames({
    'cash-cards-clicks__group-button-wrapper': true,
  });

  const classes = classNames({
    'cash-cards-clicks__group-button': true,
    'cash-cards-clicks__group-button--active': active,
  });

  const toggleActive = () => {
    setActive(!active);
    onToggle(!active);
  };

  return (
    <>
      <div className={warpperClasses}>
        <button type="button" className={classes} onClick={toggleActive}>
          {title}
        </button>
      </div>
      <Collapse active={active}>{children}</Collapse>
    </>
  );
};

export default GroupButton;
