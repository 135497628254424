import { BlubeemModelsApiLocation } from '@generated/brinks.schemas';
import { CheckoutStep4FormData } from '../models/checkout-step-4-form.model';
import { ServiceType } from '../models/service-type.enum';
import { deserializeAccessories } from './accessories.converter';
import { deserializeDayInformation, serializeDayInformation } from './day-information.converter';

export function convertLocationDataToForm(location: BlubeemModelsApiLocation): CheckoutStep4FormData {
  return {
    services: [
      {
        name: ServiceType.Cash,
        ...location.cash,
        active: location.cash && location.cash.cashEnabled,
        dayInformation: deserializeDayInformation(location.cash?.dayInformation),
        locationId: location.id,
        accessories: location.cash?.accessories || [],
      },
      {
        name: ServiceType.Cards,
        ...location.cards,
        active: location.cards && location.cards.cardsEnabled,
        locationId: location.id,
        cardsLeadTimeInDays: location.cards?.leadTimeInDays,
        cardsDeliveryAfterTransactionDifference: Number(location.cards?.directDelivery),
        cardsSeasonalRevenuePercentage: location.cards?.expectedSeasonalPattern,
        accessories: location.cards?.accessories || [],
        madePaymentsWithAnotherPsp: location.cards?.processedVolumeLastThreeMonths > 0 ? 'true' : 'false',
        cardsConsumptionDistributionDifference: Number(location.cards?.b2C),
        cardsIntraRegionalTargetMarketDifference: Number(location.cards?.intraRegionalTargetMarketOutsideEU),
        cardsIntraRegionalTargetMarketInEuCountries:
          location.cards?.intraRegionalTargetMarketInsideEUCountries?.split(',') || [],
        cardsIntraRegionalTargetMarketOutsideEuCountries:
          location.cards?.intraRegionalTargetMarketOutsideEUCountries?.split(',') || [],
        cardsDomesticTargetMarketPercentage: location.cards?.domesticTargetMarket,
        cardsDomesticTargetCountryName: location.cards?.domesticTargetCountryName,
        cardsAverageTransactionValue: location.cards?.averageTransactionValue,
        cardsHighestTransactionValue: location.cards?.highestTransactionValue,
        cardsNameFormerPsp: location.cards?.nameFormerPsp,
        cardsReasonForSwitching: location.cards?.reasonForSwitching,
        cardsProcessedVolumeLastThreeMonths: location.cards?.processedVolumeLastThreeMonths,
        cardsReturnPaymentPercentage: location.cards?.numberOfRefundsFromTotalSales,
        cardsChargebackPercentage: location.cards?.numberOfChargebacksFromTotalSales,
        cardsFraudVolumePercentage: location.cards?.fraudVolumePercentage,
        cardsExpectedTotalVolumeAnnually: location.cards?.expectedTotalVolumeAnnually,
        cardsGoodsDeliveredAfterTransactionMoment: location.cards?.goodsDeliveredAfterTransactionMoment,
        cardsAverageRevenuePerPaymentMethodDifference: Number(location.cards?.averageRevenuePerMonthCredit),
      },
      {
        name: ServiceType.Clicks,
        ...location.clicks,
        active: location.clicks && location.clicks.clicksEnabled,
        locationId: location.id,
        clicksLeadTimeInDays: location.clicks?.leadTimeInDays,
        recurringCreditCardPayment: location.clicks?.recurringCreditCardPayments || false,
        madePaymentsWithAnotherPsp: location.clicks?.processedVolumeLastThreeMonths > 0 ? 'true' : 'false',
        clicksSeasonalRevenuePercentage: location.clicks?.periodicSeasonalRevenue,
        selectedCountries: location.clicks?.countryFocus?.split(',') || [],
        clicksConsumptionDistributionDifference: Number(location.clicks?.b2C),
        accessories: location.clicks?.accessories || [],
        clicksIntraRegionalTargetMarketDifference: Number(location.clicks?.intraRegionalTargetMarketInsideEU),
        clicksIntraRegionalTargetMarketInEuCountries:
          location.clicks?.intraRegionalTargetMarketInsideEUCountries?.split(';') || [],
        clicksIntraRegionalTargetMarketOutsideEuCountries:
          location.clicks?.intraRegionalTargetMarketOutsideEUCountries?.split(';') || [],
        clicksDomesticTargetMarketPercentage: location.clicks?.domesticTargetMarket,
        clicksDomesticTargetCountryName: location.clicks?.domesticTargetCountryName,
        clicksAverageTransactionValue: location.clicks?.averageTransactionValue,
        clicksHighestTransactionValue: location.clicks?.highestTransactionValue,
        clicksExpectedTotalVolumeAnnually: location.clicks?.expectedTotalVolumeAnnually,
        clicksNameFormerPsp: location.clicks?.nameFormerPsp,
        clicksReasonForSwitching: location.clicks?.reasonForSwitching,
        clicksReturnPaymentPercentage: location.clicks?.numberOfRefundsFromTotalSales,
        clicksChargebackPercentage: location.clicks?.numberOfChargebacksFromTotalSales,
        clicksFraudVolumePercentage: location.clicks?.fraudVolumePercentage,
        clicksProcessedVolumeLastThreeMonths: location.clicks?.processedVolumeLastThreeMonths,
        clicksAverageRevenuePerPaymentMethodDifference: Number(location.clicks?.averageRevenuePerMonthCredit),
      },
    ],
  };
}

export function deserializeFormData(
  location: BlubeemModelsApiLocation,
  formData: CheckoutStep4FormData,
): BlubeemModelsApiLocation {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { locationId: cashLocationId, ...cashService } = formData.services.find(
    (service) => service.name === ServiceType.Cash,
  );
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { locationId: cardsLocationId, ...cardsService } = formData.services.find(
    (service) => service.name === ServiceType.Cards,
  );
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { locationId: clicksLocationId, ...clicksService } = formData.services.find(
    (service) => service.name === ServiceType.Clicks,
  );
  return {
    ...location,
    cash: {
      ...cashService,
      cashEnabled: cashService.active,
      alarm: cashService.alarm,
      dayInformation: serializeDayInformation(cashService.dayInformation, location),
      accessories: deserializeAccessories(cashService.accessories),
    },
    cards: {
      ...cardsService,
      cardsEnabled: cardsService.active,
      leadTimeInDays: cardsService.cardsLeadTimeInDays,
      directDelivery: convertRangeToValue('max', cardsService.cardsDeliveryAfterTransactionDifference),
      deliveryAfterwards: convertRangeToValue('min', cardsService.cardsDeliveryAfterTransactionDifference),
      expectedSeasonalPattern: cardsService.cardsSeasonalRevenuePercentage,
      accessories: deserializeAccessories(cardsService.accessories),
      b2B: Number(convertRangeToValue('min', cardsService.cardsConsumptionDistributionDifference)),
      b2C: Number(convertRangeToValue('max', cardsService.cardsConsumptionDistributionDifference)),
      intraRegionalTargetMarketInsideEU: cardsService.active
        ? Number(convertRangeToValue('max', cardsService.cardsIntraRegionalTargetMarketDifference))
        : undefined,
      intraRegionalTargetMarketOutsideEU: cardsService.active
        ? Number(convertRangeToValue('min', cardsService.cardsIntraRegionalTargetMarketDifference))
        : undefined,
      intraRegionalTargetMarketInsideEUCountries: cardsService.active
        ? cardsService.cardsIntraRegionalTargetMarketInEuCountries.join(';')
        : undefined,
      intraRegionalTargetMarketOutsideEUCountries: cardsService.active
        ? cardsService.cardsIntraRegionalTargetMarketOutsideEuCountries.join(';')
        : undefined,
      domesticTargetCountryName: cardsService.cardsDomesticTargetCountryName,
      averageRevenuePerMonthDebit: cardsService.active
        ? Number(convertRangeToValue('min', cardsService.cardsAverageRevenuePerPaymentMethodDifference))
        : undefined,
      averageRevenuePerMonthCredit: cardsService.active
        ? Number(convertRangeToValue('max', cardsService.cardsAverageRevenuePerPaymentMethodDifference))
        : undefined,
      averageTransactionValue: cardsService.cardsAverageTransactionValue,
      highestTransactionValue: cardsService.cardsHighestTransactionValue,
      domesticTargetMarket: cardsService.cardsDomesticTargetMarketPercentage,
      expectedTotalVolumeAnnually: cardsService.cardsExpectedTotalVolumeAnnually,
      goodsDeliveredAfterTransactionMoment: cardsService.cardsGoodsDeliveredAfterTransactionMoment,

      nameFormerPsp: cardsService.madePaymentsWithAnotherPsp === 'true' ? cardsService.cardsNameFormerPsp : undefined,
      reasonForSwitching:
        cardsService.madePaymentsWithAnotherPsp === 'true' ? cardsService.cardsReasonForSwitching : undefined,
      numberOfRefundsFromTotalSales:
        cardsService.madePaymentsWithAnotherPsp === 'true' ? cardsService.cardsReturnPaymentPercentage : 0,
      numberOfChargebacksFromTotalSales:
        cardsService.madePaymentsWithAnotherPsp === 'true' ? cardsService.cardsChargebackPercentage : 0,
      fraudVolumePercentage:
        cardsService.madePaymentsWithAnotherPsp === 'true' ? cardsService.cardsFraudVolumePercentage : 0,
      processedVolumeLastThreeMonths:
        cardsService.madePaymentsWithAnotherPsp === 'true' ? cardsService.cardsProcessedVolumeLastThreeMonths : 0,
    },
    clicks: {
      ...clicksService,
      clicksEnabled: clicksService.active,
      leadTimeInDays: clicksService.clicksLeadTimeInDays,
      periodicSeasonalRevenue: clicksService.clicksSeasonalRevenuePercentage,
      countryFocus: clicksService.active ? clicksService.selectedCountries.join(',') : undefined,
      b2B: Number(convertRangeToValue('min', clicksService.clicksConsumptionDistributionDifference)),
      b2C: Number(convertRangeToValue('max', clicksService.clicksConsumptionDistributionDifference)),
      accessories: deserializeAccessories(clicksService.accessories),
      intraRegionalTargetMarketInsideEU: clicksService.active
        ? Number(convertRangeToValue('max', clicksService.clicksIntraRegionalTargetMarketDifference))
        : undefined,
      intraRegionalTargetMarketOutsideEU: clicksService.active
        ? Number(convertRangeToValue('min', clicksService.clicksIntraRegionalTargetMarketDifference))
        : undefined,
      intraRegionalTargetMarketInsideEUCountries: clicksService.active
        ? clicksService.clicksIntraRegionalTargetMarketInEuCountries.join(';')
        : undefined,
      intraRegionalTargetMarketOutsideEUCountries: clicksService.active
        ? clicksService.clicksIntraRegionalTargetMarketOutsideEuCountries.join(';')
        : undefined,
      domesticTargetCountryName: clicksService.clicksDomesticTargetCountryName,
      averageRevenuePerMonthDebit:
        clicksService.active && clicksService.clicksAverageRevenuePerPaymentMethodDifference
          ? Number(convertRangeToValue('min', clicksService.clicksAverageRevenuePerPaymentMethodDifference))
          : undefined,
      averageRevenuePerMonthCredit: clicksService.active
        ? Number(convertRangeToValue('max', clicksService.clicksAverageRevenuePerPaymentMethodDifference))
        : undefined,
      averageTransactionValue: clicksService.clicksAverageTransactionValue,
      highestTransactionValue: clicksService.clicksHighestTransactionValue,
      domesticTargetMarket: clicksService.clicksDomesticTargetMarketPercentage,
      expectedTotalVolumeAnnually: clicksService.clicksExpectedTotalVolumeAnnually,

      // Former payment service provider information
      nameFormerPsp:
        clicksService.madePaymentsWithAnotherPsp === 'true' ? clicksService.clicksNameFormerPsp : undefined,
      reasonForSwitching:
        clicksService.madePaymentsWithAnotherPsp === 'true' ? clicksService.clicksReasonForSwitching : undefined,
      numberOfRefundsFromTotalSales:
        clicksService.madePaymentsWithAnotherPsp === 'true' ? clicksService.clicksReturnPaymentPercentage : 0,
      numberOfChargebacksFromTotalSales:
        clicksService.madePaymentsWithAnotherPsp === 'true' ? clicksService.clicksChargebackPercentage : 0,
      fraudVolumePercentage:
        clicksService.madePaymentsWithAnotherPsp === 'true' ? clicksService.clicksFraudVolumePercentage : 0,
      processedVolumeLastThreeMonths:
        clicksService.madePaymentsWithAnotherPsp === 'true' ? clicksService.clicksProcessedVolumeLastThreeMonths : 0,
    },
  };
}

function convertRangeToValue(cardType: 'min' | 'max', rangeValue?: number): string {
  if (cardType === 'max') {
    return String(rangeValue);
  }

  return String(100 - rangeValue);
}
