import {
  invalidEmail,
  invalidPostalCode,
  invalidPurchaseOrderNumber,
  maxCharacters,
  minCharacters,
  requiredMessage,
} from '@utilities/validation/messages';
import { belgiumPostcode, dutchPostcode, emailRegExp, purchaseOrderNumberRegExp } from '@utilities/validation/regex';
import * as yup from 'yup';

export const CheckoutStepTwo = yup.object().shape({
  contact: yup.object().shape({
    email: yup.string().matches(emailRegExp, invalidEmail).required(requiredMessage),
    firstname: yup
      .string()
      .min(2, ({ min }) => minCharacters(min))
      .max(40, ({ max }) => maxCharacters(max))
      .required(requiredMessage),
    inFix: yup.string().max(40, ({ max }) => maxCharacters(max)),
    lastname: yup
      .string()
      .min(2, ({ min }) => minCharacters(min))
      .max(80, ({ max }) => maxCharacters(max))
      .required(requiredMessage),
  }),
  purchaseOrderNumber: yup
    .string()
    .matches(purchaseOrderNumberRegExp, invalidPurchaseOrderNumber)
    .max(50, ({ max }) => maxCharacters(max)),
  billingAddress: yup.object().when('useSameInvoiceAddress', {
    is: false,
    then: yup.object({
      street: yup
        .string()
        .max(255, ({ max }) => maxCharacters(max))
        .required(requiredMessage),
      houseNumber: yup.string().nullable().required(requiredMessage),
      zipCode: yup
        .string()
        .test('postcode', invalidPostalCode, (value) => dutchPostcode.test(value) || belgiumPostcode.test(value))
        .required(requiredMessage),
      city: yup
        .string()
        .max(20, ({ max }) => maxCharacters(max))
        .required(requiredMessage),
    }),
  }),
});
