import classNames from 'classnames';
import React, { ReactNode, MouseEvent } from 'react';

export interface ButtonProps {
  tag: keyof JSX.IntrinsicElements;
  type?: string;
  href?: string;
  target?: string;
  title?: string;
  size?: string;
  shadow?: boolean;
  fullWidth?: boolean;
  disabled?: boolean;
  modifier?: string;
  onClick?: (e: MouseEvent<HTMLElement>) => void;
  tabIndex?: number;
  id?: string;
  text: string;
  ariaLabel?: string;
  children?: ReactNode;
  formType?: string;
  classes?: string;
}

const Button: React.FC<ButtonProps> = ({
  tag,
  type,
  href,
  target,
  title,
  size,
  shadow,
  fullWidth,
  disabled,
  modifier,
  onClick,
  tabIndex,
  id,
  text,
  ariaLabel,
  children,
  formType,
  classes,
}) => {
  const Element = tag as 'a';

  return (
    <Element
      className={classNames('c-button', {
        [`c-button--size-${size}`]: size,
        'c-button--shadow': shadow,
        'c-button--disabled': disabled,
        'c-button--border': modifier === 'white' && !shadow,
        'c-button--full-width': fullWidth,
        [`c-button--${modifier}`]: modifier,
        [`c-button--disabled-${formType}`]: formType,
        [`${classes}`]: Boolean(classes),
      })}
      type={type}
      onClick={onClick}
      href={href}
      target={target}
      rel={target === '__blank' ? 'noopener noreferrer' : undefined}
      title={title}
      {...(tag === 'button' && { disabled })}
      tabIndex={tabIndex}
      aria-label={ariaLabel}
      id={id}
    >
      <span className="c-button__label" dangerouslySetInnerHTML={{ __html: text }} />

      {children}
    </Element>
  );
};

export default Button;
