export const emailRegExp =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const phoneRegExp = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/;
export const dutchPostcode = /^\d{4}\s?\w{2}$/;
export const belgiumPostcode = /^[1-9]{1}[0-9]{3}$/;
export const dutchVat = /^(NL)[0-9]{9}B[0-9]{2}$/;
export const belgianVat = /^(BE)?0[0-9]{9}$/;
export const maxTwoDecimalRegExp = /^[0-9]+\.?[0-9]{0,2}$/;
export const purchaseOrderNumberRegExp = /^[a-zA-Z0-9_-]*$/;
export const urlRegExp =
  /^((https?|ftp|smtp):\/\/)?(www.)?[a-zA-Z0-9\-]+\.[a-zA-Z]+(\.[a-zA-Z]+)?(\/[a-zA-Z0-9#]+\/?)*$/;
export const cleanUpTraceAndNumber = /(\s*-\s*\d+\s*)$/;
