/* eslint-disable jsx-a11y/label-has-associated-control */

import classNames from 'classnames';
import { ErrorMessage } from 'formik';
import React from 'react';
import Tooltip from '../Tooltip';

export interface RadioProps {
  classes?: string;
  formType?: string;
  disabled?: boolean;
  readonly?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onFocus?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  sronly?: boolean;
  label: string;
  labelSubtitle?: string;
  value?: string;
  name: string;
  options: { value: string | boolean; displayValue: string }[];
  hasError?: boolean;
  tooltip?: string;
  smallerFont?: boolean;
}

const Radio: React.FC<RadioProps> = ({ formType = 'primary', smallerFont = false, ...props }) => {
  if (typeof props.value === 'boolean') props.value = (props.value as boolean).toString();

  const classes = classNames({
    'c-radio form__item': true,
    [`${props.classes}`]: Boolean(props.classes),
    'form__item--error': props.hasError,
    [`form__item--${formType}`]: formType,
  });

  const labelClasses = classNames({
    radio__label: true,
    radio__smaller_label: smallerFont,
  });

  const inputClasses = classNames({
    radio__input: true,
    'u-sr-only': Boolean(props.sronly),
  });

  return (
    <div className={classes}>
      <div className="radio__wrapper">
        <div className="radio__label-wrapper">
          <label className="radio__label-title">
            <span>{props.label}</span>
            {props.labelSubtitle && <span className="radio__label-subtitle">{props.labelSubtitle}</span>}
          </label>
          {props.tooltip && <Tooltip text={props.tooltip} />}
        </div>
      </div>
      {props.options.map(({ value, displayValue }) => {
        return (
          <React.Fragment key={`${props.name}-${value}`}>
            <input
              className={inputClasses}
              type="radio"
              name={props.name}
              id={`${props.name}-${displayValue}`}
              value={String(value)}
              checked={props.value === value}
              onChange={props.onChange}
              disabled={props.disabled}
              readOnly={props.readonly}
            />
            <label
              htmlFor={`${props.name}-${displayValue}`}
              className={labelClasses}
              dangerouslySetInnerHTML={{ __html: displayValue }}
            />
          </React.Fragment>
        );
      })}
      {props.hasError && (
        <p className="checkbox__error">
          <ErrorMessage name={props.name} />
        </p>
      )}
    </div>
  );
};

export default Radio;
