import { BlubeemModelsApiLocation, BlubeemRequestsApiContactRequest } from '@generated/brinks.schemas';
import { LocationError } from '@utilities/validation';
import { FormikErrors, useFormikContext } from 'formik';
import React, { useMemo } from 'react';
import { BinIcon, PencilIcon } from '../svg/icons';

interface LocationExtraProps {
  index: number;
  contacts: BlubeemRequestsApiContactRequest[];
  openEditModal: (locationIndex: number) => void;
  openRemoveLocationModal: (locationIndex: number) => void;
  hasError?: boolean;
}

type LocationProps = LocationExtraProps & BlubeemModelsApiLocation;

const Location: React.FC<LocationProps> = ({
  openRemoveLocationModal,
  openEditModal,
  index,
  name,
  street,
  city,
  country,
  houseNumber,
  zipCode,
  contactId,
  contacts,
}) => {
  const contact = contacts.find((contact) => contact.id === contactId);
  const { errors }: { errors: FormikErrors<LocationError> } = useFormikContext();
  const missingContact = useMemo(() => !!Object.keys(errors).length && !errors.hasContact[index], [errors, index]);

  return (
    <div className={`c-location ${missingContact && 'c-location--error'}`}>
      <h2 className="location__title">Vestiging {index + 1}</h2>
      <div className="location__data">
        {name} <br />
        {street} {houseNumber} <br />
        {zipCode}, {city} <br />
        {country}
      </div>

      {contact && (
        <div className="location__data">
          {contact.firstname} {contact.lastname} <br />
          {contact.email} <br />
          {contact.mobilePhone}
        </div>
      )}

      <div className="location__buttons">
        <button className="location__button" type="button" onClick={() => openEditModal(index)}>
          <span className="location__button-text">Bewerken</span>
          <PencilIcon />
        </button>
        <button className="location__button" type="button" onClick={() => openRemoveLocationModal(index)}>
          <span className="location__button-text">Verwijderen</span>
          <BinIcon />
        </button>
      </div>
    </div>
  );
};

export default Location;
