import React, { ReactNode } from 'react';

interface FormLayoutColOneProps {
  children: ReactNode;
}
const FormLayoutColOne: React.FC<FormLayoutColOneProps> = ({ children }) => {
  return (
    <div className="o-col-12 o-col-6--md">
      <div className="form-layout__column">{children}</div>
    </div>
  );
};

export default FormLayoutColOne;
