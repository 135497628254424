import { FormikSelect } from '@components/Shared/form-elements/FormikSelect';
import StyledButton from '@components/Shared/StyledButton';
import { FormikAutocomplete } from '@shared//form-elements/FormikAutocomplete';
import { FormikInput } from '@shared//form-elements/FormikInput';
import FormHeader from '@shared//FormHeader';
import FormLayout from '@shared//FormLayout';
import FormLayoutColOne from '@shared//FormLayoutColOne';
import FormLayoutColTwo from '@shared//FormLayoutColTwo';
import { UserProps } from '@utilities/interfaces/user';
import { RegistrationTwo } from '@utilities/validation';
import { Form, Formik } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import SelectedPackage from './SelectedPackage';
import { socialMediaValues } from '@utilities/input-values';
import Collapse from '@components/Shared/collapse/Collapse';
import { BinIcon } from '@components/Shared/svg/icons';
import { getPicklistIndustry } from '@generated/picklist';
import { setToken as setAxiosToken } from '@utilities/services/setToken';
import { BlubeemModelsApiPicklistValue } from '@generated/brinks.schemas';
import { industries } from '@utilities/industries';
import { useUser } from '@state/user';

interface RegisterTwoProps {
  callback?: (id: string) => void;
  initialValues: UserProps;
  onSubmit: (values) => void;
}

export interface SocialMedia {
  mediaType?: string | undefined;
  mediaUrl?: string | undefined;
}

const RegisterTwo: React.FC<RegisterTwoProps> = ({ onSubmit, initialValues, callback }) => {
  const { packageid } = useParams<{ packageid: string }>();
  const [vatNumberVisible, setVatNumberVisible] = useState<boolean>(false);
  const [socialMediaFields, setSocialMediaFields] = useState<SocialMedia[]>([{ mediaType: undefined, mediaUrl: '' }]);
  const [socialMediaWarning, setSocialMediaWarning] = useState({ show: false, text: '' });
  const [industryPicklist, setIndustryPicklist] = useState<BlubeemModelsApiPicklistValue[]>();
  const { user } = useUser();

  const firstRender = useRef<boolean>(true);

  const unableToDeleteSocialMediaWarning = 'Selecteer de optie `Geen` wanneer dit veld niet van toepassing is';
  const finishPreviousSocialMediaWarning = 'Maak de vorige social media selectie af voordat u een nieuwe toevoegt';
  const hasCardsOrClicks = user.packageInformation.hasCards || user.packageInformation.hasClicks;

  const socialMediaIsNotComplete = () =>
    socialMediaFields.length > 0 && !socialMediaFields[socialMediaFields.length - 1].mediaType;

  const addSocialMedia = (event) => {
    event.preventDefault();

    if (socialMediaIsNotComplete()) {
      setSocialMediaWarning({ text: finishPreviousSocialMediaWarning, show: true });
    } else {
      setSocialMediaWarning({ ...socialMediaWarning, show: false });
      setSocialMediaFields((socialMediaFields) => [...socialMediaFields, { mediaType: undefined, mediaUrl: '' }]);
    }
  };

  const removeSocialMedia = (index: number) => {
    if (socialMediaFields.length > 1) {
      const reducedArr = socialMediaFields.filter((field, i) => i !== index);
      setSocialMediaFields(reducedArr);
    } else {
      setSocialMediaWarning({ text: unableToDeleteSocialMediaWarning, show: true });
    }
  };

  const handleFormChange = (selection, index: number) => {
    const newFields = socialMediaFields.map((data) => Object.assign({}, data));
    const updatedField = { ...newFields[index], mediaType: selection };

    if (selection == 'Geen') {
      updatedField.mediaUrl = '';
    }

    newFields[index] = updatedField;
    setSocialMediaFields(newFields);
  };

  const token =
    (sessionStorage.getItem('USER_ACCOUNT_TOKEN') as string) ?? (sessionStorage.getItem('USER_TOKEN') as string);

  useEffect(() => {
    const getIndustryPicklist = async () => {
      try {
        setAxiosToken(token);
        const { data } = await getPicklistIndustry();
        setIndustryPicklist(data.values);
      } catch {
        setIndustryPicklist(industries);
      }
    };

    getIndustryPicklist();
  }, [token]);

  useEffect(() => {
    // We use this to avoid triggering the Collapse on first render.
    if (firstRender.current) {
      firstRender.current = false;
      return;
    }
    if (!socialMediaIsNotComplete()) {
      setSocialMediaWarning({ ...socialMediaWarning, show: false });
    }
  }, [socialMediaFields]);

  useEffect(() => {
    if (callback && packageid) {
      callback(packageid);
    }
  }, [packageid, callback]);

  return (
    <FormLayout mobileReverse>
      <FormLayoutColOne>
        <FormHeader
          title="Voltooi je aanmelding"
          text="Vul je gegevens in"
          status="Nog één stap te gaan"
          indicator="Stap 2/2"
          hasPrev
        />
        <Formik initialValues={initialValues} validationSchema={RegistrationTwo(hasCardsOrClicks)} onSubmit={onSubmit}>
          {({ handleChange, values, touched }) => (
            <Form>
              {!values.company.registrationNumber && touched.companySearchQuery && (
                <p className="input__error u-margin-bottom--xs">Zoek en selecteer een bedrijf voordat u verder gaat.</p>
              )}
              <FormikAutocomplete
                label="Bedrijfsnaam of KVK-nummer"
                name="companySearchQuery"
                formValue="company"
                type="text"
                formType="secondary"
                setVatNumberVisible={(value) => setVatNumberVisible(value)}
              />
              {vatNumberVisible && (
                <FormikInput label="BTW nummer" name="company.vatNumber" type="text" formType="secondary" />
              )}
              {industryPicklist && hasCardsOrClicks && (
                <>
                  <FormikInput label="Bedrijfswebsite" name="company.website" type="text" formType="secondary" />
                  <FormikSelect
                    label="Industrie"
                    hideLabel
                    name="company.industry"
                    placeholder="Selecteer industrie"
                    white
                    options={industryPicklist.map((value) => ({
                      value: value.value,
                      displayValue: value.displayValue,
                    }))}
                  />
                </>
              )}
              <FormikInput label="Voornaam" name="applicant.firstname" type="text" formType="secondary" />
              <FormikInput label="Tussenvoegsel" name="applicant.middlename" type="text" formType="secondary" />
              <FormikInput label="Achternaam" name="applicant.lastname" type="text" formType="secondary" />
              <FormikSelect
                label="Aanhef"
                hideLabel
                name="applicant.gender"
                placeholder="Selecteer aanhef"
                white
                options={[
                  { value: 'M', displayValue: 'De Heer' },
                  { value: 'F', displayValue: 'Mevrouw' },
                ]}
              />
              {hasCardsOrClicks &&
                socialMediaFields.map((field, index) => {
                  return (
                    <div key={index} className="input__small">
                      <div className="form__item_socmed">
                        <FormikSelect
                          label="Social media"
                          hideLabel
                          name={`socialMediaFields[${index}].mediaType`}
                          placeholder="Bedrijf social media"
                          white
                          onChange={(selection) => handleFormChange(selection, index)}
                          options={socialMediaValues.map((current) => ({
                            value: current,
                            displayValue: current,
                          }))}
                        />
                        <FormikInput
                          label={`Zakelijk social media account`}
                          name={`socialMediaFields[${index}].mediaUrl`}
                          formType="secondary"
                          type="text"
                          onChange={handleChange}
                        />
                        <button className="form__item_socmed_button_add" type="button" onClick={addSocialMedia}>
                          +
                        </button>
                        <button type="button" onClick={() => removeSocialMedia(index)}>
                          <BinIcon fill="#FFFFFF" />
                        </button>
                      </div>
                    </div>
                  );
                })}
              {hasCardsOrClicks && (
                <div className="form__item_socmed_warning">
                  <Collapse active={socialMediaWarning.show}>
                    <p className="input__error u-margin-bottom--xs">{socialMediaWarning.text}</p>
                  </Collapse>
                </div>
              )}

              <StyledButton tag="button" type="submit" text="Bevestig" />
            </Form>
          )}
        </Formik>
      </FormLayoutColOne>
      <FormLayoutColTwo selectedPackage>
        {initialValues.productType && <SelectedPackage productid={initialValues.productType} />}
      </FormLayoutColTwo>
    </FormLayout>
  );
};

export default RegisterTwo;
