import StyledButton from '@components/Shared/StyledButton';
import { putAccountDetails } from '@generated/account';
import { BlubeemRequestsApiAccountDetails } from '@generated/brinks.schemas';
import { putOpportunityStep } from '@generated/opportunity';
import { useUser } from '@state/user';
import { useLoader } from '@utilities/context/LoaderContext';
import { UserProps } from '@utilities/interfaces/user';
import { CheckoutStepOne } from '@utilities/validation';
import { Form, Formik } from 'formik';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { FormikInput } from '../../Shared/form-elements/FormikInput';
import FormHeader from '../../Shared/FormHeader';
import FormLayout from '../../Shared/FormLayout';
import FormLayoutColOne from '../../Shared/FormLayoutColOne';
import FormLayoutColTwo from '../../Shared/FormLayoutColTwo';
import InfoBox from '../../Shared/InfoBox';

const CheckoutOne = () => {
  const history = useHistory();
  const { toggleLoader } = useLoader();
  const { user, setUser } = useUser();

  const hasCardsOrClicks = user.packageInformation.hasCards || user.packageInformation.hasClicks;

  const onSubmit = async (values: UserProps) => {
    toggleLoader(true);

    const payload: BlubeemRequestsApiAccountDetails = {
      companyName: values.account.name,
      phone: values.account.phone,
    };

    await putAccountDetails(payload);

    setUser({ ...values, step: 2 });
    toggleLoader(false);
    history.push('/checkout/step-2');
  };

  useEffect(() => {
    putOpportunityStep({ step: '1' });
  }, []);

  return (
    <FormLayout>
      <FormLayoutColOne>
        <FormHeader title="Voltooi je bestelling" indicator="Stap 1/6" />
        <InfoBox theme="light">
          <p className="u-margin-bottom--sm">Zorg dat je de volgende informatie bij de hand hebt:</p>
          <ul className="c-list u-margin-bottom--sm">
            <li className="list__item">Bankrekeningnummer</li>
            <li className="list__item">Paspoort of ID van alle tekenbevoegden</li>
            <li className="list__item">Informatie over je vestigingen zoals adresgegevens en openingstijden</li>
            {hasCardsOrClicks && (
              <>
                <li className="list__item">Bedrijfsgegevens en wettelijke vertegenwoordigers</li>
                <li className="list__item">Informatie over de transacties die je doet</li>
              </>
            )}
          </ul>
          <p>
            Het invullen van de vragen is in <b>(ongeveer) 10 minuten</b> geregeld.
          </p>
        </InfoBox>
      </FormLayoutColOne>
      <FormLayoutColTwo>
        <Formik enableReinitialize initialValues={user} validationSchema={CheckoutStepOne} onSubmit={onSubmit}>
          <Form>
            <FormikInput label="Bedrijfsnaam" name="account.name" type="text" formType="tertiary" readonly disabled />
            <FormikInput label="+31 Telefoonnummer" name="account.phone" type="tel" formType="tertiary" />
            <StyledButton tag="button" type="submit" text="Volgende" formType="tertiary" />
          </Form>
        </Formik>
      </FormLayoutColTwo>
    </FormLayout>
  );
};

export default CheckoutOne;
