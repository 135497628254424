import { BlubeemRequestsIdentificationProcessRequest } from '@generated/brinks.schemas';
import { putSignicat } from '@generated/signicat';
import { useUser } from '@state/user';
import { useLoader } from '@utilities/context/LoaderContext';
import { SignicatUboProcess } from '@utilities/interfaces/user';
import React, { useEffect, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

interface QueryParams {
  dossierId: string;
  processId: string;
  status: string;
  uboId: string;
}

const SignicatRedirect: React.FC = () => {
  const { toggleLoader } = useLoader();
  const location = useLocation();
  const history = useHistory();
  const { user, setUser } = useUser();
  const params = useMemo(() => new URLSearchParams(location.search), [location]);
  const { dossierId, processId, status, uboId }: QueryParams = {
    dossierId: params.get('dossierId'),
    processId: params.get('processId'),
    status: params.get('status'),
    uboId: params.get('uboId'),
  };

  const updateData = async () => {
    toggleLoader(true);

    await updateSalesforceData();

    const existingProcess = user.signicatProcess?.find((process) => process.uboId === uboId);
    if (!!existingProcess) {
      const updatedProcess = { ...existingProcess, status, uboId };
      const otherProcesses = user.signicatProcess.filter((process) => process.uboId !== uboId);
      setUser({ signicatProcess: [...otherProcesses, updatedProcess] });
    } else {
      const newProcess: SignicatUboProcess = {
        dossierId,
        processId,
        status,
        uboId,
      };
      setUser({ signicatProcess: [...(user.signicatProcess || []), newProcess] });
    }

    history.replace('step-5');
    toggleLoader(false);
  };

  const updateSalesforceData = async () => {
    const payload: BlubeemRequestsIdentificationProcessRequest = {
      processId: processId,
      dossierId: dossierId,
      status: status,
    };
    await putSignicat(payload);
  };

  useEffect(() => {
    updateData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return <></>;
};

export default SignicatRedirect;
