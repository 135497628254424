// @ts-nocheck
import { useModalActions, useModalState } from '@utilities/context/ModalContext';
import React, { FC } from 'react';
import ReactModal from 'react-modal';
import Button from '../Button';
import { Cross } from '../svg/icons';

const duration = 300;

interface ModalProps {
  id: string;
  isClosable: boolean;
  title?: string;
  onClose?: () => void;
  shouldCloseOnOverlay?: boolean;
}

export const Modal: FC<ModalProps> = ({ children, id, isClosable, title, onClose, shouldCloseOnOverlay }) => {
  const modalActions = useModalActions();
  const modalState = useModalState();

  const modal = modalState.getModal(id);

  if (modal && modal.isShown) {
    document.body.classList.add('noScroll');
  }

  const onDismiss = () => {
    modalActions.hide(id);
    document.body.classList.remove('noScroll');

    if (onClose) {
      setTimeout(onClose, duration);
    }
  };

  const onConfirm = () => {
    if (modal.callback) {
      modal.callback();
    }

    onDismiss();
  };

  return (
    <>
      <ReactModal
        className="c-modal__content"
        overlayClassName="modal__overlay"
        closeTimeoutMS={duration}
        isOpen={Boolean(modal && modal.isShown)}
        contentLabel="Modal"
        onRequestClose={onDismiss}
        ariaHideApp={false}
        shouldCloseOnOverlayClick={!!shouldCloseOnOverlay}
      >
        {modal && (
          <div className="modal__content-wrapper">
            {title && <h3 className="modal__title">{title}</h3>}
            {isClosable && (
              <button onClick={onDismiss} className="modal__close-button">
                <Cross />
              </button>
            )}
            <div>
              {modal.content}

              {children}

              {modal.callback && modal.callbackLabel && (
                <Button tag="button" onClick={onConfirm} text={modal.callbackLabel} />
              )}
            </div>
          </div>
        )}
      </ReactModal>
    </>
  );
};
