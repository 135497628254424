import React, { ReactNode } from 'react';
import classNames from 'classnames';

interface FormLayoutColTwoProps {
  selectedPackage?: boolean;
  children: ReactNode;
}
const FormLayoutColTwo: React.FC<FormLayoutColTwoProps> = ({ selectedPackage, children }) => {
  const classes = classNames({
    'form-layout__column form-layout__column--two': true,
    'form-layout__column--mobile': selectedPackage,
  });

  return (
    <div className="o-col-12 o-col-6--md">
      <div className={classes}>{children}</div>
    </div>
  );
};

export default FormLayoutColTwo;
