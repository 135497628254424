/**
 * Generated by orval v6.6.4 🍺
 * Do not edit manually.
 * Brinks Onboarding API v2023.9.6.948
 * Onboarding API (Development)
 * OpenAPI spec version: 1.0
 */
import axios,{
  AxiosRequestConfig,
  AxiosResponse
} from 'axios'
import type {
  BlubeemRequestsApiContactRequest
} from './brinks.schemas'



  /**
 * @summary Creates a contact
 */
export const postContact = <TData = AxiosResponse<string>>(
    blubeemRequestsApiContactRequest: BlubeemRequestsApiContactRequest, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.post(
      `/Contact`,
      blubeemRequestsApiContactRequest,options
    );
  }
/**
 * @summary Update the contact
 */
export const putContact = <TData = AxiosResponse<void>>(
    blubeemRequestsApiContactRequest: BlubeemRequestsApiContactRequest, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.put(
      `/Contact`,
      blubeemRequestsApiContactRequest,options
    );
  }
/**
 * @summary Gets all contacts for this opportunity !!!!!  so get the opportunity for this account where Soure=Blubeem of Brinks
 */
export const getContact = <TData = AxiosResponse<BlubeemRequestsApiContactRequest[]>>(
     options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.get(
      `/Contact`,options
    );
  }
/**
 * @summary Gets the Contact
 */
export const getContactId = <TData = AxiosResponse<BlubeemRequestsApiContactRequest>>(
    id: string, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.get(
      `/Contact/${id}`,options
    );
  }
/**
 * @summary Deletes the Contact
 */
export const deleteContactId = <TData = AxiosResponse<void>>(
    id: string, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.delete(
      `/Contact/${id}`,options
    );
  }
