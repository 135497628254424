import { ProductType } from '@utilities/interfaces/user';

const GTM_BLUBEEM_ID = process.env.REACT_APP_GTM_BLUBEEM_ID || '';
const GTM_BRINKS_ID = process.env.REACT_APP_GTM_BRINKS_ID || '';

export const addGoogleTagManager = (currentProduct: ProductType) => {
  // Your Google Tag Manager script
  const gtmId = currentProduct === ProductType.BLUEBEEM ? GTM_BLUBEEM_ID : GTM_BRINKS_ID;

  if (gtmId !== '' && gtmId !== undefined && gtmId !== null) {
    addGoogleTagManagerScripts(gtmId);
  }
};

const addGoogleTagManagerScripts = (gtmId: string) => {
  if (gtmId !== null && gtmId != undefined && gtmId.trim() !== '') {
    // The script is added to the head component
    const script = document.createElement('script');
    script.innerHTML = `
        (function (w, d, s, l, i) {
          w[l] = w[l] || [];
          w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
          var f = d.getElementsByTagName(s)[0],
            j = d.createElement(s),
            dl = l != 'dataLayer' ? '&l=' + l : '';
          j.async = true;
          j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
          f.parentNode.insertBefore(j, f);
        })(window, document, 'script', 'dataLayer', '${gtmId}');
      `;
    document.head.appendChild(script);

    // The noscript is added the body component
    const noscript = document.createElement('noscript');
    noscript.innerHTML = `<iframe
        src="https://www.googletagmanager.com/ns.html?id=${gtmId} "
        height="0"
        width="0"
        style="display: none; visibility: hidden"></iframe>`;
    document.body.appendChild(noscript);
  }
};
