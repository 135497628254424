import { FormikNumberInput } from '@components/Shared/form-elements/FormikNumberInput';
import { FormikRange } from '@components/Shared/form-elements/FormikRange';
import { BlubeemModelsApiPicklistValue, BlubeemModelsApiProduct } from '@generated/brinks.schemas';
import Collapse from '@shared/collapse/Collapse';
import { FormikRadio } from '@shared/form-elements/FormikRadio';
import { FormikSelect } from '@shared/form-elements/FormikSelect';
import { FormikToggle } from '@shared/form-elements/FormikToggle';
import { countries } from '@utilities/countries';
import { useFormikContext } from 'formik';
import React, { useCallback, useMemo, useState } from 'react';
import { CheckoutStep4FormData } from '../../models/checkout-step-4-form.model';
import { ServiceType } from '../../models/service-type.enum';
import Accessories from '../shared/Accessories';
import FormTitle from '../shared/FormTitle';
import { FormikMultiSelect } from '@components/Shared/form-elements/FormikMultiSelect';
import GroupButton from '../shared/GroupButton';

const PAGE_SERVICE_TYPE = ServiceType.Cards;

interface Props {
  products: BlubeemModelsApiProduct[];
  reasonForSwitchingPicklistValues: BlubeemModelsApiPicklistValue[];
  formerPSPPicklistValues: BlubeemModelsApiPicklistValue[];
  resetValue: boolean;
  resetAccessoires: number;
}

export const Cards: React.FC<Props> = ({
  products,
  reasonForSwitchingPicklistValues,
  formerPSPPicklistValues,
  resetValue,
  resetAccessoires,
}) => {
  const { setFieldValue, values, setFieldTouched } = useFormikContext<CheckoutStep4FormData>();
  const [cardsActive, setCardsActive] = useState<boolean>(true);

  const serviceIndex = useMemo(
    () => values.services.findIndex((service) => service.name === PAGE_SERVICE_TYPE),
    [values],
  );
  const active = useMemo(() => values.services.find((service) => service.name === PAGE_SERVICE_TYPE).active, [values]);

  const cardsProducts = useMemo(
    () => products.filter((product) => product.serviceType?.toLowerCase() === PAGE_SERVICE_TYPE),
    [products],
  );

  const setAllTogglesTouchedForValidation = useCallback(() => {
    values.services.forEach((_, index) => setFieldTouched(`services[${index}].active`, true, true));
  }, [values.services, setFieldTouched]);

  return (
    <div className={`${active ? 'collapse__wrapper-active' : null}`}>
      <div className='cash-cards-clicks__button-toggle'>
      <GroupButton title={'Cards'} defaultActive={true} onToggle={setCardsActive}>
        <div className="cash-cards-clicks__item">
        <FormikToggle
            formType="secondary"
            label={active ? 'Zet cards uit' : 'Zet cards aan'}
            uncheckedLabel={'Zet cards aan'}
            checkedLabel={'Zet cards uit'}
            name={`services[${serviceIndex}].active`}
            hideLabel={true}
            optimized={false}
            onChange={() => {
              setFieldValue(`services[${serviceIndex}].active`, !active);
              setAllTogglesTouchedForValidation();
            }}
          />
        </div>
        </GroupButton>
      </div>
      <Collapse active={active}>
        <>
          <div className="cash-cards-clicks__item">
            <FormikRadio
              name={`services[${serviceIndex}].cardsGoodsDeliveredAfterTransactionMoment`}
              label="Levering goederen en/of diensten"
              labelSubtitle="Worden goederen en/of diensten later geleverd dan het transactiemoment?"
              options={[
                { value: 'true', displayValue: 'Ja' },
                { value: 'false', displayValue: 'Nee' },
              ]}
            />
          </div>
          <Collapse
            active={(values.services[serviceIndex].cardsGoodsDeliveredAfterTransactionMoment as any) === 'true'}
          >
            <div className="cash-cards-clicks__item">
              <FormikNumberInput
                title="Doorlooptijd"
                label="Doorlooptijd"
                subtitle="Gemiddelde doorlooptijd in dagen"
                name={`services[${serviceIndex}].cardsLeadTimeInDays`}
                formType="tertiary"
              />
            </div>
          </Collapse>
          <div className="cash-cards-clicks__item">
            <FormikRange
              name={`services[${serviceIndex}].cardsDeliveryAfterTransactionDifference`}
              label="Verdeling tussen directe levering en achteraf levering"
              labelSubtitle="Percentage levering na transactie"
              suffix="%"
              minLabel="Direct"
              maxLabel="Achteraf"
              tooltip="Wat is het percentage van je producten die direct worden geleverd na transactie en die op een later moment pas worden geleverd na transactie?"
              split
              initialValue={50}
              resetValue={resetValue}
            />
          </div>
          <div className="cash-cards-clicks__item">
            <FormikNumberInput
              title="Gemiddelde omzet"
              label="Gemiddelde omzet"
              subtitle="Gemiddelde cards omzet per maand"
              name={`services[${serviceIndex}].averageRevenue`}
              formType="tertiary"
              prefix="€"
            />
          </div>
          <div className="cash-cards-clicks__item">
            <FormikRange
              label="Periodieke / seizoensgebonden omzet"
              name={`services[${serviceIndex}].cardsSeasonalRevenuePercentage`}
              labelSubtitle="Hoeveel procent van je omzet is periodiek / seizoensgebonden?"
              suffix="%"
              tooltip="Heb je gedurende het jaar een periode waarin je omzet significant toeneemt of afneemt? Geef aan welk percentage van je totale omzet seizoensgebonden / periode gebonden is."
              resetValue={resetValue}
            />
          </div>
          <div className="cash-cards-clicks__item">
            <FormikRadio
              name={`services[${serviceIndex}].possibilityToReturnPins`}
              label="Extra diensten"
              labelSubtitle="Mogelijkheid tot retourpinnen"
              options={[
                { value: 'true', displayValue: 'Ja' },
                { value: 'false', displayValue: 'Nee' },
              ]}
            />
          </div>
          <div className="cash-cards-clicks__item">
            <FormikRadio
              name={`services[${serviceIndex}].tipTransactions`}
              label="Extra diensten"
              labelSubtitle="Mogelijkheid tot fooi"
              options={[
                { value: 'true', displayValue: 'Ja' },
                { value: 'false', displayValue: 'Nee' },
              ]}
            />
          </div>
          <div className="cash-cards-clicks__item">
            <FormikRange
              name={`services[${serviceIndex}].cardsConsumptionDistributionDifference`}
              label="Verdeling consumenten / zakelijk"
              labelSubtitle="Percentage consumenten versus zakelijke klanten"
              suffix="%"
              minLabel="Consument"
              maxLabel="Zakelijk"
              split
              initialValue={100}
              resetValue={resetValue}
            />
          </div>
          <div className="cash-cards-clicks__item">
            <FormikRange
              name={`services[${serviceIndex}].cardsAverageRevenuePerPaymentMethodDifference`}
              label="Gemiddelde omzet per betaalmethode"
              labelSubtitle="Percentage Creditcard versus Debitcard"
              suffix="%"
              minLabel="Creditcard"
              maxLabel="Debit"
              split
              initialValue={100}
              resetValue={resetValue}
            />
          </div>
          <div className="cash-cards-clicks__item">
            <FormikRange
              name={`services[${serviceIndex}].cardsDomesticTargetMarketPercentage`}
              label="Percentage doelmarkt binnenland"
              labelSubtitle="Hoeveel procent van je omzet behaal je in je doelmarkt?"
              suffix="%"
              resetValue={resetValue}
            />
          </div>
          <div className="cash-cards-clicks__item">
            <FormikSelect
              optimized={false}
              label="Land binnenland"
              labelSubtitle="Welk land is je doelmarkt?"
              name={`services[${serviceIndex}].cardsDomesticTargetCountryName`}
              placeholder="Kies land"
              options={countries.map((current) => ({
                value: current.value,
                displayValue: current.displayValue,
              }))}
            />
          </div>
          <div className="cash-cards-clicks__item">
            <FormikRange
              name={`services[${serviceIndex}].cardsIntraRegionalTargetMarketDifference`}
              label="Verdeling transactiies"
              labelSubtitle="Percentage binnen en buiten EU"
              suffix="%"
              minLabel="Binnen EU"
              maxLabel="Buiten EU"
              split
              resetValue={resetValue}
            />
          </div>
          <div className="cash-cards-clicks__item">
            <FormikMultiSelect
              label="Doelmarkt binnen de EU"
              labelSubtitle="Selecteer landen"
              name={`services[${serviceIndex}].cardsIntraRegionalTargetMarketInEuCountries`}
              placeholder="Kies land"
              options={countries.map((current) => ({
                value: current.value,
                label: current.displayValue,
              }))}
            />
          </div>
          <div className="cash-cards-clicks__item">
            <FormikMultiSelect
              label="Doelmarkt buiten de EU"
              labelSubtitle="Selecteer landen"
              placeholder="Kies land"
              name={`services[${serviceIndex}].cardsIntraRegionalTargetMarketOutsideEuCountries`}
              options={countries.map((current) => ({
                value: current.value,
                label: current.displayValue,
              }))}
            />
          </div>
          <div className="cash-cards-clicks__item">
            <FormikNumberInput
              label="Verwacht totaal volume per jaar (EUR)"
              title="Verwacht totaal volume per jaar (EUR)"
              name={`services[${serviceIndex}].cardsExpectedTotalVolumeAnnually`}
              formType="tertiary"
              prefix="€"
            />
          </div>
          <div className="cash-cards-clicks__item">
            <FormikNumberInput
              label="Gemiddelde transactiewaarde"
              title="Gemiddelde transactiewaarde"
              subtitle="Gemiddelde transactiewaarde per pin"
              name={`services[${serviceIndex}].cardsAverageTransactionValue`}
              formType="tertiary"
              prefix="€"
            />
          </div>
          <div className="cash-cards-clicks__item">
            <FormikNumberInput
              label="Hoogste transactiewaarde"
              title="Hoogste transactiewaarde"
              subtitle="Hoogste transactiewaarde per pin"
              name={`services[${serviceIndex}].cardsHighestTransactionValue`}
              formType="tertiary"
              prefix="€"
            />
          </div>
          <FormTitle variant="primary" title="Verwerkingsgeschiedenis" />
          <div className="cash-cards-clicks__item">
            <FormikRadio
              name={`services[${serviceIndex}].madePaymentsWithAnotherPsp`}
              label="Verwerkingsgeschiedenis"
              labelSubtitle="Heeft u in het verleden betalingen via een andere betaaldienstverlener uitgevoerd?"
              options={[
                { value: 'true', displayValue: 'Ja' },
                { value: 'false', displayValue: 'Nee' },
              ]}
            />
          </div>

          <Collapse active={(values.services[serviceIndex].madePaymentsWithAnotherPsp as any) === 'true'}>
            <div className="cash-cards-clicks__item">
              <FormikSelect
                optimized={false}
                label="Naam voormalige betaaldienstverlener"
                name={`services[${serviceIndex}].cardsNameFormerPsp`}
                placeholder="Kies betaaldienstverlener"
                options={formerPSPPicklistValues.map((value) => ({
                  value: value.value,
                  displayValue: value.displayValue,
                }))}
              />
            </div>
            <div className="cash-cards-clicks__item">
              <FormikSelect
                optimized={false}
                label="Reden van overstap"
                name={`services[${serviceIndex}].cardsReasonForSwitching`}
                placeholder="Kies reden"
                options={reasonForSwitchingPicklistValues.map((value) => ({
                  value: value.value,
                  displayValue: value.displayValue,
                }))}
              />
            </div>

            <div className="cash-cards-clicks__item">
              <FormikNumberInput
                title="Aantal transacties huidige dienstverlener in de afgelopen 3 maanden"
                label="Betaalhistorie huidige dienstverlener"
                subtitle=""
                name={`services[${serviceIndex}].cardsProcessedVolumeLastThreeMonths`}
                formType="tertiary"
              />
            </div>
            <div className="cash-cards-clicks__item">
              <FormikRange
                name={`services[${serviceIndex}].cardsReturnPaymentPercentage`}
                label="Terugbetalingen (% van volume)"
                formType="tertiary"
                suffix="%"
                resetValue={resetValue}
              />
            </div>
            <div className="cash-cards-clicks__item">
              <FormikRange
                name={`services[${serviceIndex}].cardsChargebackPercentage`}
                label="Terugvorderingen (% van volume)"
                formType="tertiary"
                suffix="%"
                resetValue={resetValue}
              />
            </div>
            <div className="cash-cards-clicks__item">
              <FormikRange
                name={`services[${serviceIndex}].cardsFraudVolumePercentage`}
                label="Fraude (% van volume)"
                formType="tertiary"
                suffix="%"
                resetValue={resetValue}
              />
            </div>
          </Collapse>
        </>

        {cardsProducts.length > 0 && (
          <Accessories
            products={cardsProducts}
            service={values?.services[serviceIndex]}
            resetValue={resetValue}
            resetAccessoires={resetAccessoires}
          />
        )}
      </Collapse>
    </div>
  );
};
