import { FormikDatePicker } from '@components/Shared/form-elements/FormikDatePicker';
import { FormikSelect } from '@components/Shared/form-elements/FormikSelect';
import StyledButton from '@components/Shared/StyledButton';
import { FormikInput } from '@shared/form-elements/FormikInput';
import { Modal } from '@shared/modal/Modal';
import { LoadingIcon } from '@shared/svg/icons';
import { useModalActions } from '@utilities/context/ModalContext';
import { Form, Formik } from 'formik';
import React, { useCallback, useState } from 'react';
import { UboFormData } from './models/ubo-form-data.model';
import { UboFormSchema } from './validation/ubo-form-schema';
import { countries } from '@utilities/countries';
import Radio from '@components/Shared/form-elements/Radio';
import { FormikCheckbox } from '@components/Shared/form-elements/FormikCheckbox';
import { useUser } from '@state/user';

interface AddUboModalProps {
  id: string;
  title: string;
  initialValues: UboFormData;
  onSubmit: (values: UboFormData) => void;
}

const AVAILABLE_ROLES = ['Partner', 'Tekenbevoegde'];
const DEFAULT_ROLE = 'Tekenbevoegde';

const UboModal: React.FC<AddUboModalProps> = ({ id, title, initialValues, onSubmit }) => {
  const [loading, setLoading] = useState(false);
  const [powerOfRepresentation, setPowerOfRepresentation] = useState('');
  const { user } = useUser();
  const hasCardsOrClicks = user.packageInformation.hasCards || user.packageInformation.hasClicks;

  const { hide } = useModalActions();

  const submit = useCallback(
    (values: UboFormData) => {
      setLoading(true);

      const role = values.roles || DEFAULT_ROLE;
      const lowerCaseAvailableRoles = AVAILABLE_ROLES.map((availableRole) => availableRole.toLowerCase());
      const payload: UboFormData = {
        ...values,
        roles: lowerCaseAvailableRoles.includes(role.toLowerCase()) ? role : DEFAULT_ROLE,
        powerOfRepresentation,
      };

      onSubmit(payload);
      hide('add-ubo-modal');
      setLoading(false);
    },
    [hide, setLoading, onSubmit, powerOfRepresentation],
  );

  const handlePowerOfRepresentationChange = (value: string) => {
    setPowerOfRepresentation(value);
  };

  return (
    <Modal id={id} title={title} isClosable={true}>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={UboFormSchema(hasCardsOrClicks)}
        onSubmit={submit}
      >
        <Form>
          <div className="address__modal">
            <div className="address__modal-row">
              <FormikInput
                formType="tertiary"
                classes="form__item--half"
                name="firstname"
                label="Voornaam"
                type="text"
              />
              <FormikInput
                formType="tertiary"
                classes="form__item--half"
                name="middlename"
                label="Tussenvoegsel"
                type="text"
              />
              <FormikInput
                formType="tertiary"
                classes="form__item--half"
                name="lastname"
                label="Achternaam"
                type="text"
              />
            </div>
            <div className="address__modal-row-full">
              <FormikDatePicker name="dateOfBirth" label="Geboortedatum" style={{ marginTop: 3 }} />
            </div>
            <div className="address__modal-row">
              <FormikSelect
                name="gender"
                placeholder="Selecteer aanhef"
                options={[
                  { value: 'M', displayValue: 'De Heer' },
                  { value: 'F', displayValue: 'Mevrouw' },
                ]}
              />
              {hasCardsOrClicks && (
                <FormikSelect
                  name="roles"
                  placeholder="Selecteer rol"
                  options={[
                    { value: 'Tekenbevoegde', displayValue: 'Tekenbevoegde' },
                    { value: 'Partner', displayValue: 'Partner' }
                  ]}
                />
              )}
            </div>
            {hasCardsOrClicks && (
              <>
                <div className="address__modal-row-full">
                  <FormikInput
                    formType="tertiary"
                    classes="form__item--half"
                    name="homeAddress"
                    label="Woonadres"
                    type="text"
                  />
                </div>
                <div className="address__modal-row">
                  <FormikInput
                    formType="tertiary"
                    classes="form__item--half"
                    name="postalCode"
                    label="Postcode"
                    type="text"
                  />
                  <FormikInput
                    formType="tertiary"
                    classes="form__item--full"
                    name="city"
                    label="Woonplaats"
                    type="text"
                  />
                </div>
                <div className="address__modal-row-full">
                  <FormikSelect name="country" placeholder="Kies land" options={countries} />
                </div>
                <div className="address__modal-row-full">
                  <FormikInput
                    formType="tertiary"
                    classes="form__item--half"
                    name="email"
                    label="E-mailadres"
                    type="text"
                  />
                </div>
              </>
            )}

            <div className="address__modal-row">
              <FormikInput
                formType="tertiary"
                classes="form__item--half"
                name="mobilePhone"
                label="Mobiel"
                type="text"
              />
              <FormikInput formType="tertiary" classes="form__item--half" name="phone" label="Telefoon" type="text" />
            </div>
            {hasCardsOrClicks && (
              <>
                <div className="address__modal-row-full">
                  <Radio
                    label="Tekenbevoegdheid"
                    formType="tertiary"
                    name="powerOfRepresentation"
                    options={[
                      { value: 'Sole', displayValue: 'Alleen' },
                      { value: 'Joint', displayValue: 'Gezamenlijk' },
                      { value: 'Limited', displayValue: 'Beperkt, bijv.:' },
                    ]}
                    value={powerOfRepresentation}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      handlePowerOfRepresentationChange(e.target.value)
                    }
                    smallerFont
                  />
                </div>

                {powerOfRepresentation === 'Limited' && (
                  <div className="address__modal-row-full">
                    <FormikInput
                      formType="tertiary"
                      classes="form__item--half"
                      name="limited"
                      label="Beschrijving"
                      type="text"
                    />
                  </div>
                )}
              </>
            )}

            <StyledButton tag="button" type="submit" text="Opslaan" classes={loading ? 'button--icon' : ''}>
              {loading && <LoadingIcon classes={'button__icon button__icon--loading'} />}
            </StyledButton>
          </div>
        </Form>
      </Formik>
    </Modal>
  );
};

export default UboModal;
