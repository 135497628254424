import { BlubeemModelsApiLocationAccessories } from '@generated/brinks.schemas';

export function deserializeAccessories(
  accessories: BlubeemModelsApiLocationAccessories[],
): BlubeemModelsApiLocationAccessories[] {
  if (!accessories) {
    return [];
  }

  //return accessories.filter((accessory) => accessory?.quantity > 0);
  return accessories;
}
