import Tooltip from '@shared//Tooltip';
import classNames from 'classnames';
import { ErrorMessage } from 'formik';
import React from 'react';

export interface ProductInputProps {
  classes?: string;
  disabled?: boolean;
  readonly?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  sronly?: boolean;
  label: string;
  labelSubtitle?: string;
  value?: string | number;
  type?: string;
  name: string;
  placeholder?: string;
  formType?: string;
  hasError?: boolean;
  tooltip?: string;
  valueToArray?: boolean;
}

const Input: React.FC<ProductInputProps> = ({ formType = 'primary', ...props }) => {
  const classes = classNames({
    'c-product-input form__item': true,
    [`${props.classes}`]: Boolean(props.classes),
    'form__item--error': props.hasError,
    [`form__item--${formType}`]: formType,
  });

  const labelClasses = classNames({
    'product-input__label-title': true,
    'u-sr-only': Boolean(props.sronly),
  });

  return (
    <div className={classes}>
      <div className="product-input__wrapper">
        <div className="product-input__label-wrapper">
          <label htmlFor={props.name} className={labelClasses}>
            <span>{props.label}</span>
            {props.labelSubtitle && <span className="product-input__label-subtitle"> {props.labelSubtitle}</span>}
          </label>
          {props.tooltip && <Tooltip text={props.tooltip} />}
        </div>
        <input
          onChange={props.onChange}
          type={props.type || 'text'}
          className="product-input__input"
          name={props.name}
          id={props.name}
          value={props.value || ''}
          disabled={props.disabled}
          readOnly={props.readonly}
          placeholder={props.placeholder}
        />
      </div>
      {props.hasError && (
        <p className="product-input__error">
          <ErrorMessage name={props.name} />
        </p>
      )}
    </div>
  );
};

export default Input;
