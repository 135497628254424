export const industries = [
  {
    value: 'Agriculture',
    displayValue: 'Landbouw',
  },
  {
    value: 'Apparel',
    displayValue: 'Kleding',
  },
  {
    value: 'Banking',
    displayValue: 'Bankieren',
  },
  {
    value: 'Biotechnology',
    displayValue: 'Biotechnologie',
  },
  {
    value: 'Chemicals',
    displayValue: 'Chemicaliën',
  },
  {
    value: 'Communications',
    displayValue: 'Communications',
  },
  {
    value: 'Construction',
    displayValue: 'Construction',
  },
  {
    value: 'Consulting',
    displayValue: 'Consulting',
  },
  {
    value: 'Education',
    displayValue: 'Education',
  },
  {
    value: 'Electronics',
    displayValue: 'Electronics',
  },
  {
    value: 'Energy',
    displayValue: 'Energy',
  },
  {
    value: 'Engineering',
    displayValue: 'Engineering',
  },
  {
    value: 'Entertainment',
    displayValue: 'Entertainment',
  },
  {
    value: 'Environmental',
    displayValue: 'Environmental',
  },
  {
    value: 'Finance',
    displayValue: 'Finance',
  },
  {
    value: 'Food & Beverage',
    displayValue: 'Food & Beverage',
  },
  {
    value: 'Government',
    displayValue: 'Government',
  },
  {
    value: 'Healthcare',
    displayValue: 'Healthcare',
  },
  {
    value: 'Hospitality',
    displayValue: 'Hospitality',
  },
  {
    value: 'Insurance',
    displayValue: 'Insurance',
  },
  {
    value: 'Machinery',
    displayValue: 'Machinery',
  },
  {
    value: 'Manufacturing',
    displayValue: 'Manufacturing',
  },
  {
    value: 'Media',
    displayValue: 'Media',
  },
  {
    value: 'Not For Profit',
    displayValue: 'Not For Profit',
  },
  {
    value: 'Other',
    displayValue: 'Other',
  },
  {
    value: 'Recreation',
    displayValue: 'Recreation',
  },
  {
    value: 'Retail',
    displayValue: 'Retail',
  },
  {
    value: 'Shipping',
    displayValue: 'Shipping',
  },
  {
    value: 'Technology',
    displayValue: 'Technology',
  },
  {
    value: 'Telecommunications',
    displayValue: 'Telecommunications',
  },
  {
    value: 'Transportation',
    displayValue: 'Transportation',
  },
  {
    value: 'Utilities',
    displayValue: 'Utilities',
  },
];
