export const selectAValueMessage = 'Kies een optie';
export const requiredMessage = 'Dit veld is verplicht';
export const invalidEmail = 'Dit is geen geldig email-adres';
export const invalidPhone = 'Dit is geen geldig telefoonnummer';
export const invalidPostalCode = 'Dit is geen geldige postcode';
export const minCharacters = (amount) => `Minimaal ${amount} karakters`;
export const maxCharacters = (amount) => `Maximaal ${amount} karakters`;
export const maxTwoDecimals = 'Gebruik maximaal twee decimalen';
export const invalidPurchaseOrderNumber = 'Dit veld mag alleen letters, nummers en een (laag) streepje bevatten';
export const moneyMessage = 'Vul een bedrag in';
export const percentageMessage = 'Vul een geldig percentage in';
export const validWebAddressMessage = 'Dit is geen geldig web-adres';
export const minRequiredMessage = 'Vul minimaal 2 tekens in';
export const domainTooLongMessage = 'Domeinnaam is te lang';
export const minNumberMessage = (amount) => `Minimale waarde is ${amount}`;
export const maxNumberMessage = (amount) => `Maximale waarde is ${amount}`;
export const intraRegionalCountriesMessage = 'Selecteer minimaal één land binnen of buiten EU';
export const valueTooBigMessage = (amount) => `Waarde te groot. Gebruik maximaal ${amount} cijfers voor de komma.`;
export const valueStringTooBigMessage = (amount) => `Waarde te groot. Gebruik maximaal ${amount} tekens.`;
export const terminalMinRequiredMessage = 'Selecteer ten minste één terminal';
