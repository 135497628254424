import React, { ReactNode } from 'react';
import classNames from 'classnames';

interface InfoBoxProps {
  children: ReactNode;
  theme?: string;
}
const InfoBox: React.FC<InfoBoxProps> = ({ children, theme }) => {
  return (
    <div
      className={classNames('c-info-box', {
        [`info-box--${theme}`]: theme,
      })}
    >
      {children}
    </div>
  );
};

export default InfoBox;
