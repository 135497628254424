/**
 * Generated by orval v6.6.4 🍺
 * Do not edit manually.
 * Brinks Onboarding API v2023.9.6.948
 * Onboarding API (Development)
 * OpenAPI spec version: 1.0
 */
import axios,{
  AxiosRequestConfig,
  AxiosResponse
} from 'axios'
import type {
  BlubeemModelsApiZipCodeSearchAddress
} from './brinks.schemas'



  export const getZipCodeStreetZipcodeHousenumber = <TData = AxiosResponse<BlubeemModelsApiZipCodeSearchAddress[]>>(
    zipcode: string,
    housenumber: string, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.get(
      `/ZipCode/Street/${zipcode}/${housenumber}`,options
    );
  }
