import React, { ReactNode } from 'react';
import classNames from 'classnames';

interface FormLayoutProps {
  children: ReactNode;
  mobileReverse?: boolean;
}
const FormLayout: React.FC<FormLayoutProps> = ({ children, mobileReverse }) => {
  return (
    <section
      className={classNames('c-form-layout u-background--secondary', {
        [`form-layout--mobile-reverse`]: mobileReverse,
      })}
    >
      <div className="o-container">
        <div className="o-grid">{children}</div>
      </div>
    </section>
  );
};

export default FormLayout;
