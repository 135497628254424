// @ts-nocheck
import React from 'react';
import { animated, useTransition } from 'react-spring';
import { useToast } from '@utilities/context/ToastContext';
import classNames from 'classnames';

const Toast: React.FC = () => {
  const { toasts } = useToast();

  const transitions = useTransition(
    toasts,
    (item: any) => {
      return item ? item.id : false;
    },
    {
      from: { opacity: 0 },
      enter: { opacity: 1 },
      leave: { opacity: 0 },
    },
  );

  return (
    <div className="c-toast">
      {toasts &&
        transitions.map(({ item, props }) => {
          if (!item) return <></>;
          const classes = classNames({
            toast__item: true,
            [`toast__item--${item.variant}`]: Boolean(item.variant),
          });

          return (
            <animated.div style={props} key={item.id}>
              <div className={classes}>
                <p className="toast__message">{item.message}</p>
              </div>
            </animated.div>
          );
        })}
    </div>
  );
};

export default Toast;
