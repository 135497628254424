import { countries } from '@utilities/countries';
import { invalidEmail, maxCharacters, requiredMessage, selectAValueMessage } from '@utilities/validation/messages';
import { emailRegExp, phoneRegExp } from '@utilities/validation/regex';
import * as yup from 'yup';

const countryValue = countries.map((country) => country.value);

export const UboFormSchema = (hasCardsOrClicks) =>
  yup.object().shape({
    firstname: yup
      .string()
      .nullable()
      .required(requiredMessage)
      .max(40, ({ max }) => maxCharacters(max)),
    middlename: yup
      .string()
      .nullable()
      .max(40, ({ max }) => maxCharacters(max)),
    lastname: yup
      .string()
      .nullable()
      .required(requiredMessage)
      .max(40, ({ max }) => maxCharacters(max)),
    gender: yup.string().oneOf(['M', 'F']).required(selectAValueMessage),
    homeAddress: yup
      .string()
      .when({
        is: () => !!hasCardsOrClicks,
        then: yup
          .string()
          .nullable()
          .required(requiredMessage)
          .max(50, ({ max }) => maxCharacters(max)),
      })
      .nullable(),
    postalCode: yup
      .string()
      .when({
        is: () => !!hasCardsOrClicks,
        then: yup
          .string()
          .nullable()
          .required(requiredMessage)
          .max(10, ({ max }) => maxCharacters(max)),
      })
      .nullable(),
    city: yup
      .string()
      .when({
        is: () => !!hasCardsOrClicks,
        then: yup
          .string()
          .nullable()
          .required(requiredMessage)
          .max(40, ({ max }) => maxCharacters(max)),
      })
      .nullable(),
    country: yup
      .string()
      .when({
        is: () => !!hasCardsOrClicks,
        then: yup.string().oneOf(countryValue).required(selectAValueMessage),
      })
      .nullable(),
    email: yup
      .string()
      .when({
        is: () => !!hasCardsOrClicks,
        then: yup.string().nullable().matches(emailRegExp, invalidEmail).required(requiredMessage),
      })
      .nullable(),
    title: yup
      .string()
      .nullable()
      .max(40, ({ max }) => maxCharacters(max)),
    mobilePhone: yup
      .string()
      .nullable()
      .matches(phoneRegExp, 'Dit is geen geldig telefoonnummer')
      .required(requiredMessage),
  });
