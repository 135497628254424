import { useUser } from '@state/user';
import { ProductType } from '@utilities/interfaces/user';
import React, { useMemo } from 'react';
import Button, { ButtonProps } from './Button';

interface Props extends ButtonProps {
  isOutline?: boolean;
}

const StyledButton: React.FC<Props> = ({ tag, type, text, isOutline = false, ...rest }) => {
  const { user } = useUser();

  const isBrinks = useMemo(() => user.productType === ProductType.BRINKS, [user.productType]);

  const modifier = useMemo(() => {
    const baseModifier = isBrinks ? 'secondary' : 'primary';
    return isOutline ? `outline-${baseModifier}` : baseModifier;
  }, [isBrinks, isOutline]);

  return <Button {...rest} tag={tag} text={text} type={type} modifier={modifier} />;
};

export default StyledButton;
