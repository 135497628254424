import React, { FC, useCallback, useContext, useMemo, useReducer } from 'react';

interface ToastContextStore {
  toasts: ToastState;
  makeToast: ({}: ToastObject) => void;
}

export type ToastObject = {
  message: string;
  variant?: 'success' | 'warning' | 'error';
  timeOut?: number;
  id?: number;
};

type ToastState = Array<ToastObject>;

type ToastStateAdd = {
  type: 'add';
  data: ToastObject;
};

type ToastStateRemove = {
  type: 'remove';
  id: number;
};

type ToastTimeoutProps = {
  [key: number]: number;
};

let toastCount = 0;
const toastTimeouts: ToastTimeoutProps = {};

export const ToastContext = React.createContext({} as ToastContextStore);
export const useToast = () => {
  const context = useContext(ToastContext);

  if (!context) throw new Error(`useToast must be used within a ToastProvider`);

  return context;
};

export const ToastProvider: FC = ({ children }) => {
  const toastReducer = (prevState: ToastState, action: ToastStateAdd | ToastStateRemove): ToastState => {
    switch (action.type) {
      case 'remove':
        const newState = prevState.filter((item) => item.id !== action.id);
        return [...newState];
      case 'add':
        return [action.data, ...prevState];
      default:
        return prevState;
    }
  };

  const [toasts, setToasts] = useReducer(toastReducer, []);

  const makeToast = useCallback(({ message, variant = 'success', timeOut = 5000 }: ToastObject) => {
    const toastId = toastCount++;

    const toast = {
      message,
      variant,
      id: toastId,
    };

    setToasts({ type: 'add', data: toast });

    if (timeOut) {
      toastTimeouts[toastId] = window.setTimeout(() => removeToast(toastId), timeOut);
    }
  }, []);

  const removeToast = (id: number) => {
    setToasts({ type: 'remove', id });
  };

  const store: ToastContextStore = useMemo(() => {
    return {
      toasts,
      makeToast,
    };
  }, [toasts, makeToast]);

  return <ToastContext.Provider value={store}>{children}</ToastContext.Provider>;
};
