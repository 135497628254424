import { useUser } from '@state/user';
import { useLoader } from '@utilities/context/LoaderContext';
import { useToast } from '@utilities/context/ToastContext';
import { Company, ProductType, UserProps } from '@utilities/interfaces/user';
import { Api } from '@utilities/services/Api';
import classNames from 'classnames';
import { ErrorMessage, useFormikContext } from 'formik';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { animated, useTransition } from 'react-spring';
import StyledButton from '../StyledButton';
import { Cross } from '../svg/icons';
import CompanyTile from './../tiles/CompanyTile';

const emptyCompany = {
  activity: null,
  address: {
    street: '',
    houseNumber: null,
    houseNumberExtension: null,
    zipCode: null,
    city: '',
    country: '',
  },
  invoiceAddress: {
    street: '',
    houseNumber: null,
    houseNumberExtension: null,
    zipCode: null,
    city: '',
    country: '',
  },
  name: '',
  phone: null,
  registrationDate: '',
  registrationNumber: '',
  registrationNumberTitle: '',
  vatNumber: null,
};

export interface AutocompleteProps {
  classes?: string;
  disabled?: boolean;
  readonly?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onFocus?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  formValue?: string;
  sronly?: boolean;
  label: string;
  value?: string;
  type?: string;
  name: string;
  placeholder?: string;
  formType?: string;
  preselect?: Company;
  hasError?: boolean;
  variant?: string;
  clearable?: boolean;
  setVatNumberVisible?: (value: boolean) => void;
  checkout?: boolean;
}

const Autocomplete: React.FC<AutocompleteProps> = ({
  formType = 'primary',
  value: initialValue,
  preselect,
  checkout,
  ...props
}) => {
  const [active, setActive] = useState<boolean>(false);
  const [list, setList] = useState<Company[]>([]);
  const [selected, setSelected] = useState<any>();
  const [hasResults, setResults] = useState(true);

  const { setFieldValue, validateForm, values } = useFormikContext();
  const { toggleLoader } = useLoader();
  const { makeToast } = useToast();

  const token = checkout
    ? (sessionStorage.getItem('USER_ACCOUNT_TOKEN') as string)
    : (sessionStorage.getItem('USER_TOKEN') as string);

  const classes = classNames({
    'c-input form__item': true,
    [`${props.classes}`]: Boolean(props.classes),
    'form__item--active': active,
    'form__item--error': props.hasError,
    [`form__item--${formType}`]: formType,
  });

  const labelClasses = classNames({
    input__label: true,
    'u-sr-only': Boolean(props.sronly),
  });

  const inputRef = useRef<HTMLInputElement>(null);

  const { user } = useUser();
  const isBrinks = useMemo(() => user.productType === ProductType.BRINKS, [user.productType]);

  useEffect(() => {
    setActive(Boolean(initialValue));
  }, [initialValue]);

  useEffect(() => {
    if (!preselect) return;
    setSelected((prevState: any) => ({
      ...prevState,
      ...preselect,
    }));
  }, [preselect, setSelected]);

  useEffect(() => {
    if (!inputRef.current) return;
    if (inputRef.current.value) setActive(true);
  }, []);

  const onFocus = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (props.onFocus) {
      props.onFocus(e);
    }
    if (!inputRef.current) return;
    if (active === false) setActive(true);
  };

  const onBlur = () => {
    // ticket: AC-1561: removed onBlur, now only used on the search company input
    // if (props.onBlur) props.onBlur(e);

    if (!inputRef.current) return;
    const ACTION = inputRef.current.value ? true : false;
    setActive(ACTION);
  };

  const getData = (value: string) => {
    if (!value?.length) return makeToast({ message: 'Vul een bedrijfsnaam in', variant: 'error' });

    toggleLoader(true);
    setResults(true);
    Api.setToken(token);
    setSelected(null);

    Api.get<Company[]>(`/Company/Search/${value}`)
      .then((res) => {
        toggleLoader(false);
        setList(res);
        !res.length && setResults(false);
      })
      .catch(() => {
        toggleLoader(false);
        makeToast({ message: 'Bedrijfsgegevens zijn leeg', variant: 'error' });
      });
  };

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist();

    if (!props.onChange) return;
    if (!inputRef.current) return;
    props.onChange(e);

    if (inputRef.current.value.length === 0) {
      setSelected(null);
      setList([]);
    }
  };

  const getFullCompanyData = (data: any) => {
    toggleLoader(true);
    Api.setToken(token);

    Api.get<Company[]>(`/Company/${data.registrationNumber}/${data.locationNumber}`)
      .then((res) => {
        toggleLoader(false);
        const companyData = {
          ...res,
          name: data.name,
          locationNumber: data.locationNumber,
        };
        setFormValue(companyData);
      })
      .catch((err) => {
        toggleLoader(false);
        makeToast({ message: err.message, variant: 'error' });
      });
  };

  const setFormValue = (data: any) => {
    if (!props.formValue) return;
    if (!data.vatNumber) {
      props.setVatNumberVisible && props.setVatNumberVisible(true);
    }
    const companyData = { ...(values as UserProps).company, ...data };
    setFieldValue(props.formValue, companyData);
    setFieldValue(props.name, data.name);

    setList([]);
    setSelected(data);
    setTimeout(() => {
      validateForm();
    }, 1);
  };

  const clearInput = () => {
    if (!props.formValue) return;
    setFieldValue(props.formValue, emptyCompany);
    setFieldValue(props.name, '');
    setSelected(null);
    setList([]);
  };

  const onKeyDown = (e) => {
    const { key, keyCode, target } = e;
    if (key !== 'Enter' && keyCode !== 13) return;
    e.preventDefault();
    getData(target.value);
  };

  const onSearchClick = (e) => {
    e.preventDefault();
    getData(inputRef.current.value);
  };

  // @ts-ignore
  const transitions = useTransition(list, (item) => item.registrationNumber, {
    from: { transform: 'scale3d(1, 0, 1)' },
    enter: { transform: 'scale3d(1, 1, 1)' },
    leave: { transform: 'scale3d(1, 0, 1)' },
  });

  return (
    <div className={classes}>
      <div className="input__wrapper">
        <label htmlFor={props.name} className={labelClasses}>
          {props.label}
        </label>
        <input
          onChange={onChange}
          type={props.type || 'text'}
          className="input__input"
          name={props.name}
          value={initialValue || ''}
          onFocus={onFocus}
          onBlur={onBlur}
          id={props.name}
          ref={inputRef}
          disabled={props.disabled}
          readOnly={props.readonly}
          placeholder={props.placeholder}
          onKeyDown={onKeyDown}
        />
        {props.clearable && (
          <button type="button" className="input__clear" onClick={clearInput}>
            <Cross />
          </button>
        )}
      </div>
      <p className="input__error u-margin-bottom--xs">
        <ErrorMessage name={props.name} />
      </p>
      <StyledButton onClick={onSearchClick} type="button" tag="button" text="Zoeken" classes="u-margin-bottom--xs" />
      {!hasResults && <p className="input__error u-margin-bottom--xs">Geen bedrijven gevonden</p>}
      {Boolean(list.length) && (
        <ul className="c-autocomplete-list">
          {transitions.map(({ item, props }, index) => (
            <animated.li className="autocomplete-list__item" style={props} key={`chambers--${index}`}>
              <button type="button" className="autocomplete-list__button" onClick={() => getFullCompanyData(item)}>
                <CompanyTile variant={isBrinks ? 'secondary' : 'primary'} {...item} />
              </button>
            </animated.li>
          ))}
        </ul>
      )}
      {!!selected && <CompanyTile variant={isBrinks ? 'secondary' : 'primary'} {...selected} />}
    </div>
  );
};

export default Autocomplete;
