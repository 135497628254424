import { BlubeemRequestsApiUboRequest } from '@generated/brinks.schemas';
import { EMPTY_UBO } from '../models/test-ubos';
import { UboFormData } from '../models/ubo-form-data.model';

export function convertUboToFormData(ubo: BlubeemRequestsApiUboRequest): UboFormData {
  return {
    ...EMPTY_UBO,
    id: ubo.id,
    ...ubo,
    dateOfBirth: ubo.dateOfBirth ? ubo.dateOfBirth : new Date().toISOString(),
  };
}

export function convertFormDataToUbo(
  ubo: BlubeemRequestsApiUboRequest,
  formData: UboFormData,
): BlubeemRequestsApiUboRequest {
  return {
    ...ubo,
    ...formData,
  };
}
