import classNames from 'classnames';
import { ErrorMessage, Field, FieldInputProps, useFormikContext } from 'formik';
import React from 'react';
import Select from 'react-select';
import Tooltip from '../Tooltip';

interface Option {
  value: string;
  label: string;
}

interface Props {
  name: string;
  label?: string;
  labelSubtitle?: string;
  tooltip?: string;
  placeholder?: string;
  options: Option[];
  inputClasses?: string;
}

export const FormikMultiSelect: React.FC<Props> = ({
  name,
  options,
  placeholder,
  inputClasses,
  label,
  labelSubtitle,
  tooltip,
}) => {
  const { setFieldTouched, setFieldValue } = useFormikContext();

  const onChange = (field: FieldInputProps<any>, options: any[]) => {
    setFieldValue(field.name, options ? options.map((item: Option) => item.value) : []);
  };

  const getValue = (field: FieldInputProps<any>) => {
    return field.value && options ? options.filter((option) => field.value.indexOf(option.value) >= 0) : [];
  };

  return (
    <Field name={name}>
      {({ field, meta }) => {
        const hasError = Boolean(meta.touched && meta.error);

        const classes = classNames({
          'c-select form__item': true,
          [`${inputClasses}`]: Boolean(inputClasses),
          'form__item--error': hasError,
          [`form__item--primary`]: true,
        });

        const selectClasses = classNames({
          select__input: true,
          'u-sr-only': false,
        });

        return (
          <div className={classes}>
            {!!label && (
              <div className="select__label-wrapper">
                <label htmlFor={name}>
                  <span dangerouslySetInnerHTML={{ __html: label }} className="select__label-title" />
                  {labelSubtitle && <span className="select__label-subtitle">{labelSubtitle}</span>}
                </label>
                {tooltip && <Tooltip text={tooltip} />}
              </div>
            )}
            <Select
              className={selectClasses}
              name={field.name}
              value={getValue(field)}
              placeholder={placeholder}
              options={options}
              isMulti={true}
              onChange={(e: any) => {
                setFieldTouched(field.name);
                onChange(field, e);
              }}
            />
            {hasError && (
              <p className="select__error">
                <ErrorMessage name={name} />
              </p>
            )}
          </div>
        );
      }}
    </Field>
  );
};
