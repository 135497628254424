import {
  intraRegionalCountriesMessage,
  maxNumberMessage,
  minNumberMessage,
  requiredMessage,
  terminalMinRequiredMessage,
  valueTooBigMessage,
} from '@utilities/validation/messages';
import * as yup from 'yup';
import { ServiceType } from '../models/service-type.enum';
import { validateMoneyInput, validateSelect } from './validation';
import { CheckoutStep4Service } from '../models/checkout-step-4-form.model';

export const CARDS_VALIDATION_SCHEMA = {
  cardsLeadTimeInDays: yup
    .number()
    .when(['active', 'name', 'cardsGoodsDeliveredAfterTransactionMoment'], {
      is: (active, name, cardsGoodsDeliveredAfterTransactionMoment) =>
        name === ServiceType.Cards && active && cardsGoodsDeliveredAfterTransactionMoment === 'true',
      then: yup
        .number()
        .required(requiredMessage)
        .min(1, minNumberMessage(1))
        .max(365, maxNumberMessage(365))
        .nullable(),
    })
    .nullable(),
  averageRevenue: validateMoneyInput('averageRevenue', ServiceType.Cards),
  cardsDomesticTargetCountryName: validateSelect(ServiceType.Cards),
  cardsIntraRegionalTargetMarketInEuCountries: yup
    .array()
    .when(['active', 'name'], {
      is: (active, name) => name === ServiceType.Cards && active,
      then: yup.array().test('exists', intraRegionalCountriesMessage, function (this: any) {
        const countriesInEU = this.parent.cardsIntraRegionalTargetMarketInEuCountries;
        const countriesOutsideEU = this.parent.cardsIntraRegionalTargetMarketOutsideEuCountries;
        if (
          (countriesInEU === undefined || countriesInEU.length === 0) &&
          (countriesOutsideEU === undefined || countriesOutsideEU.length === 0)
        ) {
          return false;
        }
        return true;
      }),
    })
    .nullable(),
  cardsIntraRegionalTargetMarketOutsideEuCountries: yup
    .array()
    .when(['active', 'name'], {
      is: (active, name) => name === ServiceType.Cards && active,
      then: yup.array().test('exists', intraRegionalCountriesMessage, function (this: any) {
        const countriesInEU = this.parent.cardsIntraRegionalTargetMarketInEuCountries;
        const countriesOutsideEU = this.parent.cardsIntraRegionalTargetMarketOutsideEuCountries;
        if (
          (countriesInEU === undefined || countriesInEU.length === 0) &&
          (countriesOutsideEU === undefined || countriesOutsideEU.length === 0)
        ) {
          return false;
        }
        return true;
      }),
    })
    .nullable(),
  cardsExpectedTotalVolumeAnnually: validateMoneyInput('cardsExpectedTotalVolumeAnnually', ServiceType.Cards),
  cardsAverageTransactionValue: validateMoneyInput('cardsAverageTransactionValue', ServiceType.Cards),
  cardsHighestTransactionValue: validateMoneyInput('cardsHighestTransactionValue', ServiceType.Cards),

  // PSP information
  cardsNameFormerPsp: yup
    .string()
    .when(['active', 'name', 'madePaymentsWithAnotherPsp'], {
      is: (active, name, madePaymentsWithAnotherPsp) =>
        name === ServiceType.Cards && active && madePaymentsWithAnotherPsp === 'true',
      then: yup.string().required(requiredMessage).nullable(),
    })
    .nullable(),
  cardsReasonForSwitching: yup
    .string()
    .when(['active', 'name', 'madePaymentsWithAnotherPsp'], {
      is: (active, name, madePaymentsWithAnotherPsp) =>
        name === ServiceType.Cards && active && madePaymentsWithAnotherPsp === 'true',
      then: yup.string().required(requiredMessage).nullable(),
    })
    .nullable(),
  cardsProcessedVolumeLastThreeMonths: yup
    .number()
    .when(['active', 'name', 'madePaymentsWithAnotherPsp'], {
      is: (active, name, madePaymentsWithAnotherPsp) =>
        name === ServiceType.Cards && active && madePaymentsWithAnotherPsp === 'true',
      then: yup
        .number()
        .required(requiredMessage)
        .min(1, minNumberMessage(1))
        .max(999999999999999, valueTooBigMessage(15))
        .nullable(),
    })
    .nullable(),

    accessories: yup
    .array()
    .when(['active', 'name'], {
      is: (active, name) => name === ServiceType.Cards && active,
      then: yup
        .array()
        .test('exists', terminalMinRequiredMessage, function (this: yup.TestContext) {
          const { accessories } = this.parent as CheckoutStep4Service;
  
          if (!Array.isArray(accessories)) {
            return false;
          }
  
          const totalQuantity = accessories.reduce((acc, accessory) => {
            if (accessory && typeof accessory.quantity === 'number') {
              return acc + accessory.quantity;
            }
            return acc;
          }, 0);
  
          return totalQuantity > 0;
        })
        .nullable(),
    })
    .nullable(),
};
