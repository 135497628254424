/**
 * Generated by orval v6.6.4 🍺
 * Do not edit manually.
 * Brinks Onboarding API v2023.9.6.948
 * Onboarding API (Development)
 * OpenAPI spec version: 1.0
 */
import axios,{
  AxiosRequestConfig,
  AxiosResponse
} from 'axios'
import type {
  SalesforceSdkModelsOpportunityStepRequest,
  SalesforceSdkModelsOpportunityLineItemRequest
} from './brinks.schemas'



  export const getOpportunityStep = <TData = AxiosResponse<SalesforceSdkModelsOpportunityStepRequest>>(
     options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.get(
      `/Opportunity/Step`,options
    );
  }
export const putOpportunityStep = <TData = AxiosResponse<void>>(
    salesforceSdkModelsOpportunityStepRequest: SalesforceSdkModelsOpportunityStepRequest, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.put(
      `/Opportunity/Step`,
      salesforceSdkModelsOpportunityStepRequest,options
    );
  }
export const postOpportunityPackage = <TData = AxiosResponse<void>>(
    salesforceSdkModelsOpportunityLineItemRequest: SalesforceSdkModelsOpportunityLineItemRequest, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.post(
      `/Opportunity/Package`,
      salesforceSdkModelsOpportunityLineItemRequest,options
    );
  }
export const postOpportunityComplete = <TData = AxiosResponse<void>>(
     options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.post(
      `/Opportunity/Complete`,undefined,options
    );
  }
