import React, { ReactNode, useRef } from 'react';

const Collapse: React.FC<{ active: boolean | undefined; children: ReactNode }> = ({ active, children }) => {
  const outerRef = useRef<HTMLDivElement>(null);

  return (
    <div className="c-collapse" aria-hidden={active ? undefined : !active}>
      <div ref={outerRef} className="collapse__wrapper" style={active ? {} : { maxHeight: '0px' }}>
        <div>{children}</div>
      </div>
    </div>
  );
};

export default Collapse;
