import { FastField, Field } from 'formik';
import React from 'react';
import Input, { InputProps } from './Input';

interface FormikInputProps extends InputProps {
  optimized?: boolean;
}

export const FormikInput: React.FC<FormikInputProps> = ({ name, optimized = true, ...props }) => {
  const Component = optimized ? FastField : Field;

  return (
    <Component name={name}>
      {({ field, meta }) => <Input {...field} {...props} hasError={Boolean(meta.touched && meta.error)} />}
    </Component>
  );
};
