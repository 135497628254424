import React from 'react';
import Confirmation from '@shared//Confirmation';

interface Callback {
  callback?: () => void;
  buttonClick?: () => void;
}

const RegisterConfirmation: React.FC<Callback> = ({ buttonClick }) => {
  return (
    <Confirmation
      title="Gelukt!"
      text="Je ontvangt nu een <strong>bevestigingsmail</strong>. Volg de link in de mail om je bestelling af te ronden."
      button={{ label: 'Terug naar home', onClick: buttonClick }}
    />
  );
};

export default RegisterConfirmation;
