import { SocialMedia } from '@components/ReactRegister/components/RegisterTwo';
import {
  BlubeemModelsApiLocation,
  BlubeemModelsApiPackage,
  BlubeemRequestsApiContactRequest,
  BlubeemRequestsApiUboRequest,
  BlubeemResponsesApiAccountResponse,
} from '@generated/brinks.schemas';

export interface Company {
  activity?: string | null;
  address: {
    street: string;
    houseNumber?: number | null;
    houseNumberExtension?: string | null;
    zipCode?: string | null;
    city: string;
    country: string;
  };
  invoiceAddress: {
    street: string;
    houseNumber?: number | null;
    houseNumberExtension?: string | null;
    zipCode?: string | null;
    city: string;
    country: string;
  };
  // Legal company name as provided by KVK
  name: string;
  phone?: number | string | null;
  registrationDate: string;
  registrationNumber: string;
  locationNumber: string;
  registrationNumberTitle: string;
  vatNumber?: string | null;
  tradeName?: string | null;
  website?: string | null;
  industry?: string | null;
  descriptionOfBusinessActivities?: string | null;
  socialMediaFields?: [];
}

interface Applicant {
  mobilePhone: string;
  email: string;
  acceptTerms?: boolean;
  id?: string;
  salutation?: string;
  firstname?: string;
  middlename?: string;
  inFix?: string;
  lastname?: string;
  fullLastname?: string;
  phone?: string;
  gender?: string;
  accountId?: string;
  department?: string;
}

export interface SignicatUboProcess {
  uboId: string;
  processId: string;
  dossierId: string;
  status: string;
}

export interface MandateProcess {
  mandateId: string;
  status?: MandateProcessStatus;
}

export enum MandateProcessStatus {
  SIGNED = 'ok',
  CANCEL = 'cancel',
  FAIL = 'fail',
  PENDING = 'pending',
}

export enum ProductType {
  BRINKS = 'brinks',
  BLUEBEEM = 'bluebeem',
}

export interface UserProps {
  applicant: Applicant;
  account: BlubeemResponsesApiAccountResponse;
  companySearchQuery: string;
  socialMediaFields: SocialMedia[];
  step: number;
  productType: ProductType;
  packageInformation: BlubeemModelsApiPackage;
  company: Company;
  contacts: BlubeemRequestsApiContactRequest[];
  packageId: string;
  locations: BlubeemModelsApiLocation[];
  ubos?: BlubeemRequestsApiUboRequest[];
  signicatProcess?: SignicatUboProcess[];
  mandateProcess?: MandateProcess;
  setOnboardingDomain?: string;
}
