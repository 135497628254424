/**
 * Generated by orval v6.6.4 🍺
 * Do not edit manually.
 * Brinks Onboarding API v2023.9.6.948
 * Onboarding API (Development)
 * OpenAPI spec version: 1.0
 */
import axios,{
  AxiosRequestConfig,
  AxiosResponse
} from 'axios'
import type {
  BlubeemResponsesApiCreateMandateResponse,
  BlubeemModelsApiMandateResponse,
  BlubeemResponsesApiTwikeyFeedUpdateResponse
} from './brinks.schemas'



  /**
 * @summary Returns mandate short link upon its creation for a Salesforce Account
 */
export const postMandate = <TData = AxiosResponse<BlubeemResponsesApiCreateMandateResponse>>(
     options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.post(
      `/Mandate`,undefined,options
    );
  }
/**
 * @summary Gets mandate info
 */
export const getMandateMandateId = <TData = AxiosResponse<BlubeemModelsApiMandateResponse>>(
    mandateId: string, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.get(
      `/Mandate/${mandateId}`,options
    );
  }
/**
 * @summary Will update the IBAN number of Salesforce Bank Account
 */
export const putMandateBankAccountMandateId = <TData = AxiosResponse<boolean>>(
    mandateId: string, options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.put(
      `/Mandate/BankAccount/${mandateId}`,undefined,options
    );
  }
export const getMandateFeedUpdate = <TData = AxiosResponse<BlubeemResponsesApiTwikeyFeedUpdateResponse>>(
     options?: AxiosRequestConfig
 ): Promise<TData> => {
    return axios.get(
      `/Mandate/FeedUpdate`,options
    );
  }
