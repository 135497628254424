import { getZipCodeStreetZipcodeHousenumber } from '@generated/zip-code';
import { useFormikContext } from 'formik';
import React, { useRef, useState } from 'react';

type AutoAddressProps = {
  zipCode: JSX.Element;
  houseNumber: JSX.Element;
  street: JSX.Element;
  city: JSX.Element;
  country?: JSX.Element;
};

const AutoAddress: React.FC<AutoAddressProps> = ({ zipCode, houseNumber, street, city, country }) => {
  const { setFieldValue } = useFormikContext();
  const [error, setError] = useState<string>(null);

  const zipCodeRef = useRef<HTMLHeadingElement>(null);
  const houseNumberRef = useRef<HTMLHeadingElement>(null);
  const streetRef = useRef<HTMLHeadingElement>(null);
  const cityRef = useRef<HTMLHeadingElement>(null);
  const countryRef = useRef<HTMLHeadingElement>(null);

  const zipCodeInput = zipCodeRef.current?.querySelector('input');
  const houseNumberInput = houseNumberRef.current?.querySelector('input');
  const streetInput = streetRef.current?.querySelector('input');
  const cityInput = cityRef.current?.querySelector('input');
  const countrySelect = countryRef.current?.querySelector('select');

  const checkZipCodeHouseNumber = () => {
    if (zipCodeInput && zipCodeInput.value.length >= 6 && houseNumberInput && houseNumberInput?.value.length >= 1) {
      getAddress(zipCodeInput.value, houseNumberInput.value);
    }
  };

  const loadFields = (isLoading: boolean) => {
    streetRef.current?.classList[isLoading ? 'add' : 'remove']('is--loading');
    cityRef.current?.classList[isLoading ? 'add' : 'remove']('is--loading');
    country && countryRef.current?.classList[isLoading ? 'add' : 'remove']('is--loading');
  };

  const disableFields = (isDisabled: boolean) => {
    streetInput!.disabled = isDisabled;
    cityInput!.disabled = isDisabled;
    country && (countrySelect!.disabled = isDisabled);
  };

  const getAddress = async (zipCodeValue: string, houseNumberValue: string) => {
    loadFields(true);
    disableFields(true);
    setError(null);

    getZipCodeStreetZipcodeHousenumber(zipCodeValue, houseNumberValue)
      .then(({ data }) => {
        loadFields(false);
        disableFields(true);
        const { street, city, country } = data[0];
        setAddress(street, city, country);
      })
      .catch(({ data }) => {
        const houseNumberNotFound = typeof data === 'string' && data.indexOf('Could not find Postcode address') > -1;
        loadFields(false);
        disableFields(false);
        setAddress('', '', 'NL');
        setError(
          `${
            houseNumberNotFound
              ? 'Niet gevonden, controleer de postcode en het huisnummer'
              : 'Er ging iets mis, probeer het opnieuw'
          } of vul handmatig de straat- en plaatsnaam in`,
        );
      });
  };

  const setAddress = (streetValue: string, cityValue: string, countryValue: string) => {
    streetInput && setFieldValue(streetInput?.name, streetValue);
    cityInput && setFieldValue(cityInput?.name, cityValue);
    countrySelect && setFieldValue(countrySelect?.name, countryValue);
  };

  return (
    <>
      <div className="u-transition-height u-flex u-flex-wrap u-flex-between">
        <div className="form__item--half form__item--no-margin-lg" onBlur={checkZipCodeHouseNumber} ref={zipCodeRef}>
          {zipCode}
        </div>
        <div className="form__item--half form__item--no-margin" onBlur={checkZipCodeHouseNumber} ref={houseNumberRef}>
          {houseNumber}
        </div>
      </div>
      <p className="input__error u-margin-bottom--lg u-margin-top-sm">{error && error}</p>
      <div className="u-transition-height u-flex u-flex-wrap u-flex-between">
        <div className="form__item--half" ref={streetRef}>
          {street}
        </div>
        <div className="form__item--half" ref={cityRef}>
          {city}
        </div>
      </div>
      {!!country && <div ref={countryRef}>{country}</div>}
    </>
  );
};

export default AutoAddress;
