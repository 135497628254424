import InfoBox from '@components/Shared/InfoBox';
import StyledButton from '@components/Shared/StyledButton';
import { BlubeemModelsApiLocation, BlubeemRequestsApiContactRequest } from '@generated/brinks.schemas';
import { getContact, postContact } from '@generated/contact';
import { getLocation, postLocation, deleteLocation } from '@generated/location';
import { putOpportunityStep } from '@generated/opportunity';
import { useUser } from '@state/user';
import { addressModalInitialValues } from '@utilities/constants/user';
import { useLoader } from '@utilities/context/LoaderContext';
import { useModalActions } from '@utilities/context/ModalContext';
import { useToast } from '@utilities/context/ToastContext';
import { Locations } from '@utilities/validation';
import { ErrorMessage, Form, Formik } from 'formik';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import FormHeader from '../../Shared/FormHeader';
import FormLayout from '../../Shared/FormLayout';
import FormLayoutColOne from '../../Shared/FormLayoutColOne';
import FormLayoutColTwo from '../../Shared/FormLayoutColTwo';
import { Cross } from '../../Shared/svg/icons';
import Location from '../../Shared/tiles/Location';
import { serializeLocationDetails } from './shared/edit-location/location-details.converter';
import { LocationDetailsFormValues } from './shared/edit-location/location-details.model';
import { updateLocationDetails } from './shared/edit-location/update-location';
import AddressModal from './shared/modals/AddressModal';
import RemoveLocationModal from './shared/modals/RemoveLocationModal';

const DELETE_LOCATINS_TIMEOUT_IN_SECONDS = 2.5;

const CheckoutThree = () => {
  const { dispatch } = useModalActions();
  const { makeToast } = useToast();
  const { toggleLoader } = useLoader();
  const [currentLocation, setCurrentLocation] = useState(0);
  const [deletedLocationIds, setDeletedLocationIds] = useState<string[]>([]);
  const [isDeleting, setIsDeleting] = useState(false);
  const { user, setUser } = useUser();
  const [modalInitialValues, setModalInitialValues] = useState<LocationDetailsFormValues>(addressModalInitialValues);
  const history = useHistory();

  const getLocations = async () => {
    const { data } = await getLocation();
    setDeletedLocationIds([]);
    setUser({ locations: data });
  };

  const getContacts = async () => {
    const { data } = await getContact();
    setUser({ contacts: data });
  };

  const getLocationsAndContacts = async () => {
    toggleLoader(true);
    await getLocations();
    await getContacts();
    toggleLoader(false);
  };

  const openEditModal = (locationIndex) => {
    setCurrentLocation(locationIndex);

    if (!user?.locations) return;

    const { contactId, ...address } = user?.locations[locationIndex];
    const contact = user.contacts.find((contact) => contact.id === contactId);
    const locationFormValues = serializeLocationDetails(contact, address);

    setModalInitialValues(locationFormValues);
    dispatch({ type: 'show', key: 'address-modal' });
  };

  const deleteLocationModal = (locationIndex) => {
    const id = user.locations[locationIndex].id;

    if (!id) return;
    // Populate the array with deleted ID's and filter just in case the double ones out
    setDeletedLocationIds([...new Set([...deletedLocationIds, id])]);

    setUser({ locations: user.locations.filter((location) => location.id !== id) });
  };

  const deleteLocations = () => {
    if (deletedLocationIds.length === 0) return;
    if (isDeleting) return;

    // toggleLoader(true);
    deleteLocation(deletedLocationIds)
      .then(() => {
        // toggleLoader(false);
        setIsDeleting(false);
      })
      .catch((err) => {
        // toggleLoader(false);
        setIsDeleting(false);
        setDeletedLocationIds([]);
        makeToast({ message: err?.body?.message || err?.message, variant: 'error' });
      });
  };

  // Delete all the locations in the cache when the view get's dismounted
  useEffect(() => {
    return () => {
      setIsDeleting(false);
      deleteLocations();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (deletedLocationIds.length === 0 || isDeleting) return;

    const timer = setTimeout(() => {
      setIsDeleting(true);
      deleteLocations();
    }, DELETE_LOCATINS_TIMEOUT_IN_SECONDS * 1000);

    return () => {
      clearTimeout(timer);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deletedLocationIds]);

  const openRemoveLocationModal = (locationIndex) => {
    setCurrentLocation(locationIndex);
    dispatch({ type: 'show', key: 'remove-location-modal' });
  };

  const addLocation = async (locationAddressValues: LocationDetailsFormValues) => {
    toggleLoader(true);

    const contactPayload: BlubeemRequestsApiContactRequest = {
      ...locationAddressValues.contact,
    };

    const { data } = await postContact(contactPayload);
    const locationPayload: BlubeemModelsApiLocation = {
      ...locationAddressValues.address,
      country: 'NL',
      name: locationAddressValues.name,
      contactId: data,
    };
    await postLocation(locationPayload);

    await getLocationsAndContacts();
  };

  const updateLocation = async (newFormValues: LocationDetailsFormValues) => {
    const location = user?.locations[currentLocation];
    const contact = user.contacts.find((contact) => contact.id === location.contactId);

    toggleLoader(true);
    await updateLocationDetails(contact, location, newFormValues);
    await getLocationsAndContacts();
  };

  useEffect(() => {
    getLocationsAndContacts();
    putOpportunityStep({ step: '3' });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <FormLayout>
      <AddressModal
        initialValues={modalInitialValues}
        id="address-modal"
        title="Adres Bewerken"
        onSubmit={updateLocation}
      />
      <AddressModal
        initialValues={{ ...addressModalInitialValues, name: user.account.name || '' }}
        id="add-location-modal"
        title="Vestiging toevoegen"
        onSubmit={addLocation}
      />
      <RemoveLocationModal
        numberOfLocations={user.locations.length}
        locationIndex={currentLocation}
        removeLocation={deleteLocationModal}
      />

      <FormLayoutColOne>
        <FormHeader
          title="Vestigingen"
          indicator="Stap 3/6"
          text={`We hebben ${user?.locations.length - deletedLocationIds.length} ${
            user?.locations.length - deletedLocationIds.length > 1 ? 'vestigingen' : 'vestiging'
          } gevonden`}
          hasPrev
          prevUrl="step-2"
        />
        <InfoBox theme="light">
          Controleer de gegevens. Voeg contactpersonen toe door te klikken op <b>bewerken</b>. Heb je meer vestigingen?
          Voeg contactgegevens toe per vestiging.
        </InfoBox>
      </FormLayoutColOne>
      <FormLayoutColTwo>
        <Formik
          enableReinitialize
          initialValues={user}
          validate={Locations}
          onSubmit={() => {
            history.push('/checkout/step-4');
          }}
        >
          <Form>
            {user.locations.map((location, index) => {
              const isDeleted = deletedLocationIds.includes(location.id);

              if (isDeleted) return null;

              return (
                <Location
                  key={index}
                  {...location}
                  contacts={user.contacts}
                  index={index}
                  openEditModal={openEditModal}
                  openRemoveLocationModal={openRemoveLocationModal}
                />
              );
            })}

            <button
              type="button"
              className="c-verify u-margin-bottom--sm"
              onClick={() => dispatch({ type: 'show', key: 'add-location-modal' })}
            >
              <div className="verify__icon-wrapper">
                <Cross classes="verify__icon" />
              </div>
              <p className="verify__content">Extra vestiging toevoegen</p>
            </button>

            <div className="location__error">
              <ErrorMessage component="span" name="locations">
                {(msg) => <div>{msg}</div>}
              </ErrorMessage>
            </div>

            <StyledButton tag="button" text="Volgende" type="submit" formType="tertiary" />
          </Form>
        </Formik>
      </FormLayoutColTwo>
    </FormLayout>
  );
};

export default CheckoutThree;
