// @ts-nocheck

import React from 'react';
import NumberInput, { NumberInputProps } from './NumberInput';
import { FastField } from 'formik';

interface FormikNumberInputProps extends NumberInputProps {}

export const FormikNumberInput: React.FC<FormikNumberInputProps> = ({ name, ...props }) => {
  return (
    <FastField name={name}>
      {({ field, meta }) => {
        return <NumberInput {...field} {...props} hasError={Boolean(meta.touched && meta.error)} />;
      }}
    </FastField>
  );
};
