import { BlubeemRequestsApiContactRequest } from '@generated/brinks.schemas';
import { EMPTY_BILLING_ADDRESS } from '@utilities/constants/user';
import { UserProps } from '@utilities/interfaces/user';
import { CheckoutStep2FormData } from '../models/checkout-step-2-form.model';

export function serializeStepTwoFormData(
  user: UserProps,
  dto?: BlubeemRequestsApiContactRequest,
): CheckoutStep2FormData {
  return {
    purchaseOrderNumber: user?.account?.purchaseOrderNumber || '',
    useSameInvoiceAddress:
      JSON.stringify(user?.account?.billingAddress) === JSON.stringify(user?.account?.shippingAddress),
    contact: {
      email: dto?.email || '',
      firstname: dto?.firstname || '',
      middlename: dto?.middlename || '',
      lastname: dto?.lastname || '',
      gender: dto?.gender,
    },
    billingAddress: user?.account?.billingAddress || EMPTY_BILLING_ADDRESS,
  };
}
