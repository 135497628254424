import { BlubeemModelsApiProduct } from '@generated/brinks.schemas';
import { Chevron } from '@shared/svg/icons';
import React, { useRef, useState, useEffect } from 'react';
import Slider from 'react-slick';
import { CheckoutStep4Service } from '../../models/checkout-step-4-form.model';
import Accessory from './Accessory';
import { createCompleteAccessory } from './accessory.converter';
import { useFormikContext } from 'formik';

interface AccessoriesProps {
  service: CheckoutStep4Service;
  products: BlubeemModelsApiProduct[];
  resetValue: boolean;
  resetAccessoires: number;
}

const Accessories: React.FC<AccessoriesProps> = ({ service, products, resetValue, resetAccessoires }) => {
  const sliderComponentRef = useRef<Slider>(null);
  const { errors } = useFormikContext();
  const [accessoriesError, setAccessoriesError] = useState('');

  const settings = {
    dots: true,
    infinite: false,
    slidesToShow: 2.8,
    responsive: [
      {
        breakpoint: 1600,
        settings: {
          slidesToShow: 2.2,
        },
      },
      {
        breakpoint: 1240,
        settings: {
          slidesToShow: 1.7,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2.5,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1.7,
        },
      },
    ],
  };

  const changeSlide = (value: boolean) => {
    sliderComponentRef?.current?.[value ? 'slickNext' : 'slickPrev']();
  };

  useEffect(() => {
    let newAccessoriesError = '';

    Object.keys(errors).forEach((key) => {
      if (Array.isArray(errors[key])) {
        errors[key].forEach((serviceErrors, serviceIndex) => {
          if (serviceErrors && serviceErrors.accessories) {
            newAccessoriesError = serviceErrors.accessories;
          }
        });
      }
    });

    setAccessoriesError(newAccessoriesError);
  }, [errors]);

  return (
    <div className="cash-cards-clicks__item">
      <div className="accessories-title">
        <div>Voeg accessoires toe</div>

        <div className="accessory-slider-buttons">
          <button type="button" className="company-tile-slider-button" onClick={() => changeSlide(false)}>
            <Chevron fill="#0a4a8e" direction="left" />
          </button>

          <button type="button" className="company-tile-slider-button" onClick={() => changeSlide(true)}>
            <Chevron fill="#0a4a8e" direction="right" />
          </button>
        </div>
      </div>
      {accessoriesError && <p className="input__error">{accessoriesError}</p>}
      <div className="company-tile__slider-wrappers">
        <Slider {...settings} ref={sliderComponentRef}>
          {products.map((product, index) => (
            <Accessory
              serviceType={service.name}
              accessory={createCompleteAccessory(product, service.accessories)}
              key={index}
              index={index}
              resetValue={resetValue}
              resetAccessoires={resetAccessoires}
            />
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default Accessories;
