import { BlubeemModelsApiLocationDay } from '@generated/brinks.schemas';
import { timeInputValue } from '@utilities/input-values';
import { maxTwoDecimals, requiredMessage, selectAValueMessage } from '@utilities/validation/messages';
import { maxTwoDecimalRegExp } from '@utilities/validation/regex';
import * as yup from 'yup';
import { CheckoutStep4Service } from '../models/checkout-step-4-form.model';
import { ServiceType } from '../models/service-type.enum';

const selectDayMessage = 'Selecteer minimaal één dag';

export function isTimeAfter(openingTime: string, closingTime?: string): boolean {
  const d1 = new Date();
  const d2 = new Date();

  if (!closingTime) return true;

  const openTime = openingTime.split(':');
  const endTime = closingTime.split(':');
  d1.setHours(+openTime[0]);
  d2.setHours(+endTime[0]);
  d1.setMinutes(Number(openTime[1]));
  d2.setMinutes(+endTime[1]);
  return d2.getTime() > d1.getTime();
}

export const cashCardsClickCustomValidations = {
  hours: function (this: yup.TestContext) {
    const { cash, monday, tuesday, wednesday, thursday, friday, saturday, sunday } = this.parent;
    return !cash || (cash && (monday || tuesday || wednesday || thursday || friday || saturday || sunday));
  },
  time: function (this: yup.TestContext, value: any) {
    const { openingTime } = this.parent as BlubeemModelsApiLocationDay;

    return isTimeAfter(openingTime, value);
  },
};

export const CASH_VALIDATION_SCHEMA = {
  dayInformation: yup
    .array()
    .when(['active', 'name'], {
      is: (active, name) => name === ServiceType.Cash && active,
      then: yup.array().of(
        yup.object().shape({
          open: yup.boolean().test('is-one-true', selectDayMessage, function (this: any) {
            const cashService = this.from.find((service) => service.value.name === ServiceType.Cash)
              .value as CheckoutStep4Service;

            return cashService.dayInformation.filter((day) => day.open).length > 0;
          }),
          openingTime: yup.string().when('open', {
            is: true,
            then: yup.string().oneOf(timeInputValue, selectAValueMessage).required(requiredMessage),
          }),
          closingTime: yup.string().when('open', {
            is: true,
            then: yup
              .string()
              .oneOf(timeInputValue, selectAValueMessage)
              .test(
                'is-greater',
                'Sluitingstijd kan niet voor de openingstijd zijn',
                cashCardsClickCustomValidations.time,
              )
              .required(requiredMessage),
          }),
          turnoverSpeed: yup.number().when('open', {
            is: true,
            then: yup
              .number()
              .test('decimals', maxTwoDecimals, (value) => maxTwoDecimalRegExp.test(String(value)))
              .test('exists', requiredMessage, function (this: yup.TestContext) {
                const { turnoverSpeed } = this.parent;

                return !!turnoverSpeed;
              }),
          }),
        }),
      ),
    })
    .nullable(),
  alarm: yup
    .string()
    .when(['active', 'name'], {
      is: (active, name) => name === ServiceType.Cash && active,
      then: yup.string().test('exists', selectAValueMessage, function (this: yup.TestContext) {
        const { alarm } = this.parent;

        if (!alarm) return true;

        return ['true', 'false'].includes(alarm);
      }),
      otherwise: undefined,
    })
    .nullable(),
};
