import { BlubeemModelsApiLocation, BlubeemRequestsApiContactRequest } from '@generated/brinks.schemas';
import { postContact, putContact } from '@generated/contact';
import { putLocation } from '@generated/location';
import { emptyUser } from '@utilities/constants/user';
import { LocationDetailsFormValues } from './location-details.model';

export async function updateLocationDetails(
  contact: BlubeemRequestsApiContactRequest,
  location: BlubeemModelsApiLocation,
  newValues: LocationDetailsFormValues,
): Promise<any> {
  if (!location.contactId) {
    const payload: BlubeemRequestsApiContactRequest = {
      ...newValues.contact,
    };

    const { data: contactId } = await postContact(payload);

    const locationPayload: BlubeemModelsApiLocation = {
      ...location,
      contactId,
      name: newValues.name,
      ...newValues.address,
    };
    return putLocation({ ...emptyUser.locations[0], ...locationPayload });
  }

  const locationPayload: BlubeemModelsApiLocation = {
    ...location,
    name: newValues.name,
    ...newValues.address,
  };
  await putLocation({ ...emptyUser.locations[0], ...locationPayload });

  const payload: BlubeemRequestsApiContactRequest = {
    ...contact,
    ...newValues.contact,
  };
  return putContact(payload);
}
