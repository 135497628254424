import { UboFormData } from './ubo-form-data.model';

export const EMPTY_UBO: UboFormData = {
  firstname: '',
  middlename: '',
  lastname: '',
  phone: '',
  mobilePhone: '',
  email: '',
  gender: '',
  title: '',
  roles: '',
  dateOfBirth: '',
  homeAddress: '',
  postalCode: '',
  city: '',
  country: '',
  powerOfRepresentation: '',
  limitedName: '',
  isPrimaryUbo: false,
};
