import Select from '@components/Shared/form-elements/Select';
import Collapse from '@shared/collapse/Collapse';
import Checkbox from '@shared/form-elements/Checkbox';
import { FormikNumberInput } from '@shared/form-elements/FormikNumberInput';
import { FormikSelect } from '@shared/form-elements/FormikSelect';
import { FormikToggle } from '@shared/form-elements/FormikToggle';
import { timeInputValue } from '@utilities/input-values';
import classNames from 'classnames';
import { FieldArray, useFormikContext } from 'formik';
import produce from 'immer';
import React, { useEffect, useMemo, useState } from 'react';
import { CheckoutStep4FormData, CheckoutStep4Service } from '../../models/checkout-step-4-form.model';
import { ServiceType } from '../../models/service-type.enum';
import { isTimeAfter } from '../../validation/cash-validation-schema';
import FormTitle from '../shared/FormTitle';

interface HourProps {
  locationIndex: number;
}

export const Hours: React.FC<HourProps> = ({ locationIndex }) => {
  const { values, setValues, touched, setTouched } = useFormikContext<CheckoutStep4FormData>();
  const weekDays = ['Zondag', 'Maandag', 'Dinsdag', 'Woensdag', 'Donderdag', 'Vrijdag', 'Zaterdag'];
  const [applyToAllWorkDays, setApplyToAllWorkDays] = useState(false);
  const [applyToAllDays, setApplyToAllDays] = useState(false);
  const [allOpeningTimes, setAllOpeningTimes] = useState('');
  const [allClosingTimes, setAllClosingTimes] = useState('');

  const timeClasses = classNames({
    'cash-cards-clicks__row cash-cards-clicks__row--padding-top': true,
    'cash-cards-clicks__row--is-hidden': !applyToAllWorkDays && !applyToAllDays,
  });

  const serviceIndex = useMemo(
    () => values.services.findIndex((service) => service.name === ServiceType.Cash),
    [values],
  );

  useEffect(() => {
    setApplyToAllDays(false);
    setApplyToAllWorkDays(false);
    setAllOpeningTimes('');
    setAllClosingTimes('');
  }, [locationIndex]);

  const service = useMemo(() => values.services[serviceIndex], [serviceIndex, values]) as CheckoutStep4Service;

  const clearAllFields = (isTouched = false) => {
    const payloadState = produce(values, (draftState) => {
      draftState.services[serviceIndex].dayInformation?.forEach((item) => {
        item.open = false;
        item.openingTime = '';
        item.closingTime = '';
      });
    });

    setAllOpeningTimes('');
    setAllClosingTimes('');

    if (isTouched) {
      const touchedPayload: any = {};

      touchedPayload.dayInformation = [];

      service.dayInformation?.forEach((_) => {
        touchedPayload.dayInformation.push({
          open: false,
        });
      });

      setTouched({ ...touched, ...touchedPayload });
    }

    return payloadState;
  };

  const getWorkDaysValues = (setDays = false, isTouched = false) => {
    const payloadState = produce(values, (draftState) => {
      const dayInformation = draftState.services[serviceIndex]?.dayInformation;

      if (setDays) {
        dayInformation.forEach((item, index) => {
          if (index <= 5) item.open = true;
          if (index > 5 || !index) item.open = false;
        });
      }

      dayInformation.forEach((item, index) => {
        if (index <= 5) {
          item.openingTime = allOpeningTimes;
          item.closingTime = allClosingTimes;
        }

        if (index > 5 || !index) {
          item.openingTime = '';
          item.closingTime = '';
        }
      });
    });

    if (isTouched) {
      const touchedPayload: any = {};

      touchedPayload.dayInformation = [];

      service?.dayInformation?.forEach((_, index: number) => {
        if (index <= 4) {
          touchedPayload.dayInformation.push({
            open: true,
          });
        }
      });

      setTouched({ ...touched, ...touchedPayload });
    }

    return payloadState;
  };

  const getAllDaysValues = (setDays = false, isTouched = false) => {
    const payloadState = produce(values, (draftState) => {
      if (setDays) {
        draftState.services[serviceIndex]?.dayInformation?.forEach((item) => {
          item.open = true;
        });
      }

      draftState.services[serviceIndex]?.dayInformation?.forEach((item) => {
        item.openingTime = allOpeningTimes;
        item.closingTime = allClosingTimes;
      });
    });

    if (isTouched) {
      const touchedPayload: any = {};

      touchedPayload.dayInformation = [];

      service?.dayInformation?.forEach((_) => {
        touchedPayload.dayInformation.push({
          open: true,
        });
      });

      setTouched({ ...touched, ...touchedPayload });
    }

    return payloadState;
  };

  useEffect(() => {
    if (allOpeningTimes === '' && allClosingTimes === '') {
      return;
    }

    let payload = {} as CheckoutStep4FormData;
    const TOUCHED = !!(allClosingTimes || (allOpeningTimes && allClosingTimes));

    if (applyToAllWorkDays) {
      payload = getWorkDaysValues(false, TOUCHED);
    } else {
      payload = getAllDaysValues(false, TOUCHED);
    }

    setValues(payload);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allOpeningTimes, allClosingTimes]);

  const setWorkDays = (checked: boolean) => {
    setApplyToAllWorkDays(checked);

    let payload = {} as CheckoutStep4FormData;

    if (checked) {
      setApplyToAllDays(false);
      payload = getWorkDaysValues(true);
    } else {
      payload = clearAllFields(true);
    }

    setValues(payload);
  };

  const setAllDays = (checked: boolean) => {
    setApplyToAllDays(checked);
    let payload = {} as CheckoutStep4FormData;

    if (checked) {
      setApplyToAllWorkDays(false);
      payload = getAllDaysValues(true);
    } else {
      payload = clearAllFields(true);
    }

    setValues(payload);
  };

  const getTotalRevenue = () => {
    const activeItems = service.dayInformation?.filter((item) => item.open);
    const filledInItems = activeItems?.map((item) => item.turnoverSpeed);

    const priceFormatter = new Intl.NumberFormat('nl-NL', {
      style: 'currency',
      currency: 'EUR',
    });

    let totalRevenue = 0;
    filledInItems?.forEach((item) => {
      if (item) totalRevenue += Number(item);
    });
    return priceFormatter.format(totalRevenue);
  };

  return (
    <>
      <FormTitle
        variant="secondary"
        title="Openingstijden en cash omzet"
        subtitle="Stel je openingstijden in en geef je gemiddelde cash omzet per dag door."
      />
      <div className="cash-cards-clicks__checkbox-wrapper">
        <Checkbox
          label="Selecteer tijden voor alle werkdagen"
          classes="u-margin-bottom--sm"
          name="workdays"
          formType="secondary"
          onChange={(e) => setWorkDays(e.target.checked)}
          value={applyToAllWorkDays}
          checked={applyToAllWorkDays}
        />
        <Checkbox
          label="Selecteer tijden voor de hele week"
          name="alldays"
          formType="secondary"
          onChange={(e) => setAllDays(e.target.checked)}
          value={applyToAllDays}
          checked={applyToAllDays}
        />

        {(applyToAllWorkDays || applyToAllDays) && (
          <section className={timeClasses}>
            <Select
              label="Opening"
              hideLabel
              name={`services[${serviceIndex}].allOpeningTimes`}
              value={allOpeningTimes}
              onChange={(event) => setAllOpeningTimes(event.target.value)}
              placeholder="Kies openingstijd"
              options={timeInputValue.map((value) => ({
                value,
                displayValue: value,
              }))}
            />
            <Select
              label="Sluiting"
              hideLabel
              name={`services[${serviceIndex}].closingTimes`}
              value={allClosingTimes}
              onChange={(event) => setAllClosingTimes(event.target.value)}
              placeholder="Kies sluitingstijd"
              options={timeInputValue.map((value) => ({
                value,
                displayValue: value,
              }))}
            />
            {!isTimeAfter(allOpeningTimes, allClosingTimes) && (
              <p className="input__error">Sluitingstijd kan niet voor de openingstijd zijn</p>
            )}
          </section>
        )}
      </div>

      <FieldArray
        name="dayInformation"
        render={() => {
          return (
            <>
              {service?.dayInformation &&
                service?.dayInformation.map((day, i) => {
                  const index = i === 6 ? 0 : i + 1;
                  return (
                    <div className="cash-cards-clicks__item" key={index}>
                      <FormikToggle
                        name={`services[${serviceIndex}].dayInformation[${index}].open`}
                        uncheckedLabel="Gesloten"
                        optimized={false}
                        label={weekDays[index]}
                      />
                      <Collapse active={service?.dayInformation?.[index]?.open}>
                        <div className="cash-cards-clicks__row cash-cards-clicks__row--padding-top">
                          <FormikSelect
                            optimized={false}
                            label="Opening"
                            hideLabel
                            name={`services[${serviceIndex}].dayInformation[${index}].openingTime`}
                            placeholder="Kies openingstijd"
                            tabIndex={!!day.open ? 0 : -1}
                            options={timeInputValue.map((value) => ({
                              value,
                              displayValue: value,
                            }))}
                          />
                          <FormikSelect
                            optimized={false}
                            label="Sluiting"
                            hideLabel
                            name={`services[${serviceIndex}].dayInformation[${index}].closingTime`}
                            placeholder="Kies sluitingstijd"
                            tabIndex={!!day.open ? 0 : -1}
                            options={timeInputValue.map((value) => ({
                              value,
                              displayValue: value,
                            }))}
                          />
                        </div>
                        <div className="cash-cards-clicks__revenue">
                          <FormikNumberInput
                            label="Gemiddelde cash omzet op deze dag"
                            name={`services[${serviceIndex}].dayInformation[${index}].turnoverSpeed`}
                            formType="tertiary"
                            prefix="€"
                          />
                        </div>
                      </Collapse>
                    </div>
                  );
                })}

              {getTotalRevenue() !== '0' && (
                <div className="cash-cards-clicks__revenue-total">
                  <span className="cash-cards-clicks__revenue-total-text">Totale cash omzet:</span>
                  <span className="cash-cards-clicks__revenue-total-amount">&nbsp;{getTotalRevenue()}</span>
                </div>
              )}
            </>
          );
        }}
      ></FieldArray>
    </>
  );
};
