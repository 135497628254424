import { BlubeemModelsApiLocationDay } from '@generated/brinks.schemas';
import { emptyUser, EMPTY_CASH_DAY_INFORMATION } from '@utilities/constants/user';
import { BaseStateStore, createImmer } from '@utilities/createImmer';
import { UserProps } from '@utilities/interfaces/user';
import create from 'zustand';

interface UserStore extends BaseStateStore {
  user: UserProps;
  setUser: (data: Partial<UserProps>) => void;
}

export const useUser = create(
  createImmer<UserStore>((set, get) => ({
    loading: false,
    user: JSON.parse(sessionStorage.getItem('USER_DATA')) || emptyUser,
    setUser: (data) => {
      const { user } = get();
      set((state) => {
        const newUser = JSON.parse(JSON.stringify({ ...user, ...data })) as UserProps;

        // Keep daily information if the BE returns none
        newUser.locations = [
          ...(newUser.locations || []).map((locationData) => ({
            ...locationData,
            cash: {
              ...locationData.cash,
              dayInformation: addDefaultCashDayInformation(locationData.cash?.dayInformation),
            },
          })),
        ];

        state.user = Object.freeze(newUser);
      });
      sessionStorage.setItem('USER_DATA', JSON.stringify({ ...user, ...data }));
    },
  })),
);

function addDefaultCashDayInformation(dayInformation?: BlubeemModelsApiLocationDay[]): BlubeemModelsApiLocationDay[] {
  if (!dayInformation || (dayInformation && dayInformation.length === 0)) {
    return EMPTY_CASH_DAY_INFORMATION;
  }

  return EMPTY_CASH_DAY_INFORMATION.map((day) => {
    const fetchedDay = dayInformation.find((fetchedDay) => fetchedDay.dayOfWeek === day.dayOfWeek);
    return fetchedDay ? fetchedDay : day;
  });
}
