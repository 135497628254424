import { BlubeemRequestsApiPennyTestTransactionRequest } from '@generated/brinks.schemas';
import { putMandateBankAccountMandateId } from '@generated/mandate';
import { postTransactionPennyTest } from '@generated/transaction';
import { useUser } from '@state/user';
import { useLoader } from '@utilities/context/LoaderContext';
import { MandateProcess, MandateProcessStatus } from '@utilities/interfaces/user';
import React, { useEffect, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

interface QueryParams {
  mandateId: string;
  state: MandateProcessStatus;
}

const TwikeyRedirect: React.FC = () => {
  const { toggleLoader } = useLoader();
  const location = useLocation();
  const history = useHistory();
  const { user, setUser } = useUser(); // eslint-disable-line @typescript-eslint/no-unused-vars
  const params = useMemo(() => new URLSearchParams(location.search), [location]);
  const { mandateId, state }: QueryParams = {
    mandateId: params.get('mandateId'),
    state: params.get('state') as MandateProcessStatus,
  };

  const updateData = async () => {
    toggleLoader(true);

    const newProcess: MandateProcess = {
      mandateId,
      status: state,
    };

    await putMandateBankAccountMandateId(mandateId);

    setUser({ mandateProcess: newProcess });

    if (state === MandateProcessStatus.SIGNED) {
      await startPennyTest();
    }

    history.replace('step-6');
    toggleLoader(false);
  };

  const startPennyTest = async () => {
    const payload: BlubeemRequestsApiPennyTestTransactionRequest = {
      mandateId,
    };
    await postTransactionPennyTest(payload);
  };

  useEffect(() => {
    updateData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return <></>;
};

export default TwikeyRedirect;
