import { FastField } from 'formik';
import React from 'react';
import Radio, { RadioProps } from './Radio';

interface FormikRadioProps extends RadioProps {}

export const FormikRadio: React.FC<FormikRadioProps> = ({ name, ...props }) => {
  return (
    <FastField name={name}>
      {({ field, meta }) => <Radio {...field} {...props} hasError={Boolean(meta.touched && meta.error)} />}
    </FastField>
  );
};
