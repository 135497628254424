import { useLocation } from 'react-use';
import { useState, useEffect } from 'react';
import { ProductType } from '@utilities/interfaces/user';

interface UseGetCurrentProductReturn {
  currentProduct: ProductType;
}

export const useGetCurrentProduct = (): UseGetCurrentProductReturn => {
  const location = useLocation();

  let productType;
  if (location.host.includes('brinksonboarding.com')) {
    productType = ProductType.BRINKS;
  } else {
    productType = ProductType.BLUEBEEM;
  }

  const [currentProduct, setCurrentProduct] = useState(productType);

  useEffect(() => {
    setCurrentProduct(productType);
  }, []);

  return { currentProduct };
};
