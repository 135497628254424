import { FormikSelect } from '@components/Shared/form-elements/FormikSelect';
import StyledButton from '@components/Shared/StyledButton';
import { putAccount } from '@generated/account';
import { BlubeemRequestsApiContactRequest } from '@generated/brinks.schemas';
import { getContactId, postContact } from '@generated/contact';
import { putOpportunityStep } from '@generated/opportunity';
import { useUser } from '@state/user';
import { useLoader } from '@utilities/context/LoaderContext';
import { Form, Formik } from 'formik';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import AutoAddress from '../../../Shared/AutoAddress';
import { FormikCheckbox } from '../../../Shared/form-elements/FormikCheckbox';
import { FormikInput } from '../../../Shared/form-elements/FormikInput';
import FormHeader from '../../../Shared/FormHeader';
import FormLayout from '../../../Shared/FormLayout';
import FormLayoutColOne from '../../../Shared/FormLayoutColOne';
import FormLayoutColTwo from '../../../Shared/FormLayoutColTwo';
import { serializeStepTwoFormData } from './converters/form-data.converter';
import { CheckoutStep2FormData } from './models/checkout-step-2-form.model';
import { CheckoutStepTwo } from './validation/validation-schema';

const CheckoutTwo = () => {
  const history = useHistory();
  const { toggleLoader } = useLoader();
  const [contact, setContact] = useState<BlubeemRequestsApiContactRequest>();
  const { user, setUser } = useUser();

  const getAssociatedContact = async () => {
    toggleLoader(true);
    const { data } = await getContactId(user.account.contactId);
    setContact(data);
    toggleLoader(false);
  };

  useEffect(() => {
    getAssociatedContact();
    putOpportunityStep({ step: '2' });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const onSubmit = async (formData: CheckoutStep2FormData) => {
    toggleLoader(true);

    const newBillingAddress = formData.useSameInvoiceAddress
      ? user.account.shippingAddress
      : { ...user.account.shippingAddress, ...formData.billingAddress };

    newBillingAddress.country = user.account.shippingAddress.country;

    await putAccount({ billingAddress: newBillingAddress, purchaseOrderNumber: formData.purchaseOrderNumber });
    await postContact({ id: user.account.contactId, ...formData.contact });
    setUser({
      ...user,
      account: {
        ...user.account,
        billingAddress: newBillingAddress,
        purchaseOrderNumber: formData.purchaseOrderNumber,
      },
      step: 3,
    });

    toggleLoader(false);
    history.push('/checkout/step-3');
  };

  return (
    <FormLayout>
      <FormLayoutColOne>
        <FormHeader
          title="Vul je factuurgegevens in"
          indicator="Stap 2/6"
          text="Aan wie mogen we de factuur richten?"
          hasPrev
          prevUrl="step-1"
        />
      </FormLayoutColOne>
      <FormLayoutColTwo>
        <Formik
          enableReinitialize
          initialValues={serializeStepTwoFormData(user, contact)}
          validationSchema={CheckoutStepTwo}
          onSubmit={onSubmit}
        >
          {({ values }) => (
            <Form>
              <FormikInput label="E-mailadres" name="contact.email" type="email" formType="tertiary" />
              <FormikInput label="Voornaam" name="contact.firstname" formType="tertiary" />
              <FormikInput label="Tussenvoegsel" name="contact.middlename" formType="tertiary" />
              <FormikInput label="Achternaam" name="contact.lastname" formType="tertiary" />
              <FormikSelect
                label="Aanhef"
                hideLabel
                name="contact.gender"
                placeholder="Selecteer aanhef"
                options={[
                  { value: 'M', displayValue: 'De Heer' },
                  { value: 'F', displayValue: 'Mevrouw' },
                ]}
              />
              <FormikInput
                label="Purchase order nummer"
                name="purchaseOrderNumber"
                formType="tertiary"
                tooltip="Het Purchase Order nummer is een inkoopnummer dat je kan gebruiken als referentienummer voor je eigen administratie."
              />
              <FormikCheckbox
                label="Factuuradres is hetzelfde als het bedrijfsadres"
                name="useSameInvoiceAddress"
                formType="tertiary"
                align="center"
              />
              <div className={values.useSameInvoiceAddress ? 'u-transition-height--hidden' : ''}>
                <AutoAddress
                  zipCode={<FormikInput label="Postcode" name="billingAddress.zipCode" formType="tertiary" />}
                  houseNumber={<FormikInput label="Huisnummer" name="billingAddress.houseNumber" formType="tertiary" />}
                  street={<FormikInput label="Straat" name="billingAddress.street" formType="tertiary" />}
                  city={<FormikInput label="Plaats" name="billingAddress.city" formType="tertiary" />}
                />
              </div>
              <StyledButton tag="button" text="Volgende" type="submit" formType="tertiary" />
            </Form>
          )}
        </Formik>
      </FormLayoutColTwo>
    </FormLayout>
  );
};

export default CheckoutTwo;
