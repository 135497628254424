import { BlubeemModelsApiLocation, BlubeemRequestsApiContactRequest } from '@generated/brinks.schemas';
import { LocationDetailsFormValues } from './location-details.model';

export function serializeLocationDetails(
  contact: BlubeemRequestsApiContactRequest,
  location: BlubeemModelsApiLocation,
): LocationDetailsFormValues {
  return {
    name: location.name,
    address: {
      street: location.street,
      houseNumber: location.houseNumber,
      zipCode: location.zipCode,
      city: location.city,
      country: location.country,
    },
    contact: {
      firstname: contact?.firstname,
      lastname: contact?.lastname,
      email: contact?.email,
      mobilePhone: contact?.mobilePhone,
      gender: contact?.gender,
    },
  };
}
